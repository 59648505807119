import React, { useEffect, useState } from "react"
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"

const MysqlProfileForm = ({ profileData, saveProfileState }) => {
    const [profile, setProfile] = useState(profileData)
    useEffect(() => {
        saveProfileState(profile)
    }, [profile])
    return (
        <div className={'row'}>
            <div className={'col-12'}>
                <h5>MySQL Rule Profile</h5>
                <p>Send your Rule results to a MySQL database table for later processing or use in your own application.</p>
            </div>
            <div className={'col-12 mb-4'}>
                <div className="form-group">
                    <FormControl className="form-control" variant="outlined">
                        <TextField required className="form-control" value={profile.methods.auth.host} onChange={(e) =>
                            setProfile({ ...profile, methods: { ...profile.methods, auth: { ...profile.methods.auth, host: e.target.value } } }) } label="Host" variant="outlined" />
                        <p className={'form-text text-muted mt-4'}><small>This must be the <strong>full host url WITHOUT PORT</strong>, the primary port supported right now is ONLY 3306. If your port number is different please contact support.</small></p>
                    </FormControl>
                </div>
            </div>
            <div className={'col-12 mb-3 mt-4'}>
                <div className={'row'}>
                    <div className={'col-12 mt-5'}>
                        <div className="form-group">
                            <TextField required className="form-control" value={profile.methods.auth.username} onChange={(e) =>
                                setProfile({ ...profile, methods: { ...profile.methods, auth: { ...profile.methods.auth, username: e.target.value } } }) } label="Username" variant="outlined" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-12 mb-3'}>
                <div className="form-group">
                    <TextField type="password" required className="form-control" value={profile.methods.auth.password} onChange={(e) =>
                        setProfile({ ...profile, methods: { ...profile.methods, auth: { ...profile.methods.auth, password: e.target.value } } }) } label="Password" variant="outlined" />
                </div>
            </div>
            <div className={'col-12'}>
                <div className="form-group">
                    <TextField required className="form-control" value={profile.methods.auth.database} onChange={(e) =>
                        setProfile({ ...profile, methods: { ...profile.methods, auth: { ...profile.methods.auth, database: e.target.value } } }) } label="Database" variant="outlined" />
                    <p className={'form-text text-muted mt-4'}><small>The name of the database we will be inserting data into.</small></p>
                </div>
            </div>
            <div className={'col-12'}>
                <div className="form-group">
                    <TextField required className="form-control" value={profile.methods.auth.tableName} onChange={(e) =>
                        setProfile({ ...profile, methods: { ...profile.methods, auth: { ...profile.methods.auth, tableName: e.target.value } } }) } label="Table Name" variant="outlined" />
                    <p className={'form-text text-muted mt-4'}><small>The name of the table that is within {profile.methods.auth.database} database we will be inserting data into.</small></p>
                </div>
            </div>
        </div>
    )
}

export default MysqlProfileForm
