import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'

import DashboardSelect from './DashboardSelect'

const DashboardSelectModal = ({ dashboardData, selectDashboardhandler, showModel }) => {
    const [dashboards, setDashboards] = useState(dashboardData)
    const [show, setShow] = useState(showModel)

    useEffect(() => {
        setShow(showModel)
        setDashboards(dashboardData)
    }, [showModel, dashboardData])

    const selectDashboard = (dashboard) => {
        selectDashboardhandler(dashboard)
    }
    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Select a Dashboard</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DashboardSelect showHeader={false} dashboards={dashboards} selectHandler={selectDashboard} />
            </Modal.Body>
        </Modal>
    )
}

export default DashboardSelectModal
