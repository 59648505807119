import { API, graphqlOperation } from 'aws-amplify'

import { createAction, createActionUser, deleteRuleAction, deleteAction, deleteActionUser } from '../graphql/mutations'
import { listActions } from './graphql/querys'

import { Tag } from '.'
export const Create = async (company, action) => {
    let tagName = action.tagNames[0]
    let users = action.users

    let input = { actionCompanyId: company.id, ...action }
    delete input.tagNames
    delete input.users

    try {
        if (action.type === 'addtag' || action.type === 'removetag') {
            let tag = await Tag.Find(company, tagName)
            input.tag = tag
        }

        let newAction = (await API.graphql(graphqlOperation(createAction, { input }))).data.createAction

        if (action.type === 'notify') {
            // Add ActionUsers
            users.forEach(user => {
                addActionUser(newAction, user)
            })
        }
        return newAction
    } catch (e) {
        console.warn("Failed to Create a new Action", e)
        return false
    }
}

export const All = async (company) => {
    try {
        return (await API.graphql(graphqlOperation(listActions))).data.listActions.items.filter(a => a.company.id === company.id)
    } catch (e) {
        console.warn("Failed to find ALL actions", e)
        return false
    }
}

export const RemoveActionFromRule = async (ruleActionID) => {
    try {
        return (await API.graphql(graphqlOperation(deleteRuleAction, { input: { id: ruleActionID } }))).data.deleteRuleAction
    } catch (e) {
        console.warn("Failed to remove the RuleAction", e)
        return false
    }
}

export const Delete = async (action) => {
    try {
        return (await API.graphql(graphqlOperation(deleteAction, { input: { id: action.id } }))).data.deleteAction
    } catch (e) {
        console.warn("Failed to delete Action", e)
        return false
    }
}


export const DeleteActionUser = async (actionUser) => {
    try {
        return await API.graphql(graphqlOperation(deleteActionUser, { input: { id: actionUser.id } }))
    } catch (e) {
        console.warn("Failed to DELETE ActionUser", e)
        return false
    }
}

// Private

const addActionUser = async (action, user) =>  {
    const input = {
        actionUserUserId: user.id,
        actionUserActionId: action.id
    }

    try {
        await API.graphql(graphqlOperation(createActionUser, { input }))
    } catch (e) {
        console.warn("Failed to create ActionUser", e)
        return false
    }
}
