import React from 'react'

const FullPageLoading = () => {
    const pageStyle = {
        height: "100vh",
        backgroundColor: "#6333FF",
        textAlign: "center",
        paddingTop: "4em",
        color: "#fff"
    }
    return (
        <div className="FullPageLoading" style={pageStyle}>
            <img src="/images/explorer_logotype_white_transparent.png" alt="Explorer" />
            <p>Loading...</p>
        </div>
    )
}

export default FullPageLoading