import React, { useState, useEffect } from "react"
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"
const WebhookProfileForm = ({ profileData, saveProfileState }) => {
    const [profile, setProfile] = useState(profileData)
    useEffect(() => {
        saveProfileState(profile)
    }, [profile])
    return (
        <div className={'row'}>
            <div className={'col-12'}>
                <h5>Webhook Rule Profile</h5>
                <p>All webhooks require at minimum a URL, and method such as 'POST' or 'PUT'. Please note that 'GET' is not supported. All URL's must start with 'http' or 'https'.</p>
            </div>
            <div className={'col-12 mb-4'}>
                <FormControl className="form-control" variant="outlined">
                    <TextField required className="form-control" value={profile.methods.auth.url} onChange={(e) =>
                        setProfile({ ...profile, methods: { ...profile.methods, auth: { ...profile.methods.auth, url: e.target.value } } }) } label="Webhook URL" variant="outlined" />
                </FormControl>
            </div>

            <div className={'col-12'}>
                <FormControl className="form-control" variant="outlined">
                    <InputLabel id="webhookMethod">Method</InputLabel>
                    <Select
                        fullWidth
                        labelId="webhookMethod"
                        id="webhook-method"
                        value={profile.methods.auth.method}
                        onChange={(e) =>
                            setProfile({ ...profile, methods: { ...profile.methods, auth: { ...profile.methods.auth, method: e.target.value } } })}>
                        <MenuItem value={'POST'}>POST</MenuItem>
                        <MenuItem value={'PUT'}>PUT</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}

export default WebhookProfileForm
