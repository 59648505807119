import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import { SET_CURRENT_USER } from '../../Store/Actions'

import { User } from '../../Controllers'

import UserForm from '../../Components/User/UserForm'
import UserAlertSettingsForm from '../../Components/User/UserAlertSettingsForm'

class UserEdit extends Component {

    userEditHandler = async (user) => {
        let updatedUser = await User.Update(user)
        if (updatedUser) {
            this.props.setCurrentUser(updatedUser)
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success',
                    message: 'Saved!'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: "Sorry, there was an issue saving; please try again."
                }
            })
        }
    }

    render() {
        return (
            <div className="row InnerContainer">
                <div className="col-12">
                    <h2>Edit your profile details</h2>
                </div>
                <div className="col-12">
                    <section>
                        <UserForm handler={this.userEditHandler} currentUser={this.props.currentUser} />
                    </section>

                    <UserAlertSettingsForm />
                </div>
                
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentUser: (user) => dispatch({ type: SET_CURRENT_USER, payload: user })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(UserEdit)