import { SET_CURRENT_USER, 
    SET_SESSION_USERNAME, 
    SET_CURRENT_COMPANY, 
    SET_REGISTERED_USER, 
    SET_SELECTED_DASHBOARD } from '../Actions'
import { loadState } from '../LoadState'

let initState = {
    currentUser: {},
    sessionUsername: '',
    currentCompany: {},
    registerUser: {},
    selectedDashboard: null
}

const LoadedState = loadState()
if (LoadedState) {
    initState = LoadedState.user || initState
}

const UserReducer = (state = initState, action) => {
    switch(action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload
            }
        case SET_SESSION_USERNAME:
            return {
                ...state,
                sessionUsername: action.payload
            }
        case SET_CURRENT_COMPANY:
            return {
                ...state,
                currentCompany: action.payload
            }
        case SET_REGISTERED_USER:
            return {
                ...state,
                registerUser: action.payload
            }
        case SET_SELECTED_DASHBOARD:
            return {
                ...state,
                selectedDashboard: action.payload
            }
        default:
            return state
    }
}

export default UserReducer