import React, { useState, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import SavedReportForm from './SavedReportForm'
import SavedReportUpdateForm from './SavedReportUpdateForm'
import Loading from "../../Helpers/Components/Loading"

const SavedReportModel = ({ showModel, setShowModel, submitHandler, resultData = null, ruleId, inEdit = false, loading = false }) => {
    const [result, setResult] = useState(resultData)
    const [newSavedReport, setNewSavedReport] = useState(null)
    // useEffect(() => {
    //     console.log(newSavedReport)
    // }, [newSavedReport])

    return (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Save Report Results</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 mb-4">
                        {loading ? <Loading isOpen={true} /> :
                            <>
                            {
                                inEdit ?
                                    <SavedReportUpdateForm result={result} outgoingDataHandler={setNewSavedReport} /> :
                                    <SavedReportForm ruleId={ruleId} result={result}
                                                     outgoingDataHandler={setNewSavedReport} />
                            }
                            </>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn" onClick={() => setShowModel(false)}>
                    Close
                </button>
                <button onClick={() => submitHandler(newSavedReport)} className="btn btn-primary">
                    <FontAwesomeIcon icon={['fad', 'save']} /> Save
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default SavedReportModel
