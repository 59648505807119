import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

import { GeoFence } from '../../../Controllers'

const GeoFenceEditForm = ({ geoFenceData, handler }) => {
    const { t } = useTranslation()
    const [geoFence, setGeoFence] = useState(geoFenceData)

    useEffect(() => {
        setGeoFence(geoFenceData)
    }, [geoFenceData])

    const updateGeoFence = async (event) => {
        event.preventDefault()
        let result = await GeoFence.Update(geoFence)
        if (result) {
            handler(result)
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Saved!'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue updating your '+t('geofence.title')+', please try again.'
                }
            })
        }
    }

    return (
        <div className="row mt-4">
            <div className="col-12">
                <form onSubmit={updateGeoFence}>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <TextField className="form-control" value={geoFence.name} onChange={(e) => setGeoFence({ ...geoFence, name: e.target.value })} name="name" required id="geoFenceName" label={`${t('geofence.title')} Name`} variant="outlined" />
                            </div>
                        </div>

                        <div className="col-12 mb-4 text-right">
                            <button className="btn btn-primary"><FontAwesomeIcon icon={['fad', 'save']} /> Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(GeoFenceEditForm)