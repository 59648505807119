import { EcholoApi, Headers } from './config'

export const GetAllReports = async (application, limit = 50) => {
    try {
        const searchData = {
            search: [
                {"fields.messageType": "ruleResults"},
            ],
            limit: limit,
            fromDate: "now-2d"
        }
        return await runSearch(application, searchData)
    } catch (error) {
        console.log(error)
        return false
    }
}

export const GetAllReportsByRule = async (application, ruleId, limit = 50) => {
    try {
        const searchData = {
            search: [
                {"fields.messageType": "ruleResults"},
                {"fields.ruleResults.ruleId": ruleId},
            ],
            limit: limit,
            fromDate: "now-2d"
        }
        return await runSearch(application, searchData)
    } catch (error) {
        console.log(error)
        return false
    }
}

export const GetReportById = async (application, resultId) => {
    try {
        const searchData = {
            search: [
                {"fields.messageType": "ruleResults"},
                {"_id": resultId},
            ],
            limit: 1,
        }
        return await runSearch(application, searchData)
    } catch (error) {
        console.log(error)
        return false
    }
}

const runSearch = async (application, searchData) => {
    const reports = await EcholoApi.post('/search', searchData, Headers(application))
    return reports.data
}