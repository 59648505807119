import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { TextField, Chip } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { Tag } from '../../Controllers'

const TagForm = ({ handler, currentCompany, tagType = 'item', defaultTags = [], limitTags = -1 }) => {
    const [companyTags, setCompanyTags] = useState([])

    useEffect(() => {
        const fetchTags = async () => {
            let requestedTags = (await Tag.All(currentCompany)).filter(t => t.type === tagType).map((t) => t.name)
            setCompanyTags(requestedTags)
        }
        fetchTags()
    }, [currentCompany, tagType])

    const selectTagHandler = (event, values) => {
        handler(values)
    }

    return (
        <div>
            <Autocomplete
                limitTags={limitTags}
                multiple
                id="tags-tagsInput"
                options={companyTags}
                getOptionLabel={(option) => option}
                value={defaultTags}
                onChange={selectTagHandler}
                freeSolo
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Tags" />
                )}
            />
            
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(TagForm)