import React from 'react'
import { Link } from 'react-router-dom'
import { Chip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'
import { Hub } from '@aws-amplify/core'

import { Item } from '../../Controllers'

import TagChip from '../Tag/TagChip'

const ItemTable = ({ items }) => {

    const listTags = (tags) => {
        return tags.map((tag, key) => {
            return (
                <TagChip key={key} tag={tag.tag} />
            )
        })
    }

    const listSensors = (sensors) => {
        return sensors.map((sensor, key) => {
            return (
                <Chip icon={<FontAwesomeIcon icon={['fad', 'microchip']} />} key={key} label={sensor.name} variant="outlined" />
            )
        })
    }

    const deleteItem = async (item) => {
        $("#item_"+item.id).hide('fast')
        let deletedItem = await Item.Delete(item)
        if (deletedItem) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Removed '+ deletedItem.name +'!'
                }
            })
        } else {
            $("#item_"+item.id).show('fast')
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue removing this item.'
                }
            })
        }
    }

    return (
        <section>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Type</th>
                        <th scope="col">Tags</th>
                        <th scope="col">Sensors</th>
                        <th scope="col">Options</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(item => (
                        <tr id={`item_${item.id}`} key={item.id}>
                            <td><Link to={'/item/' + item.id + '/edit'}>{item.name}</Link></td>
                            <td>{item.type}</td>
                            <td>{ listTags(item.tags.items) }</td>
                            <td>{ listSensors(item.sensors.items) }</td>
                            <td><FontAwesomeIcon className="FakeLink" onClick={() => deleteItem(item)} icon={['fad', 'trash']} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    )
}

export default ItemTable