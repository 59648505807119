import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Item } from '../../Controllers'

import TagForm from '../Tag/TagForm'

import ItemTypes from '../../Config/ItemTypes'

import Loading from '../../Helpers/Components/Loading'

const RuleForm = ({ currentCompany, handler, inWizard = false, wizardHandler, ruleData = null }) => {
    const { t } = useTranslation()
    let initRule = {
        name: '',
        interval: ''
    }

    let inEditState = false

    if (ruleData) {
        initRule = {
            name: ruleData.name,
            interval: ruleData.interval,
            active: ruleData.active
        }
        inEditState = true
    }

    const [rule, setRule] = useState(initRule)
    const [inEdit, setInEdit] = useState(inEditState)

    useEffect(() => {
        if (inWizard) {
            wizardHandler('rule', rule)
        }
    }, [rule, inEdit])

    const setInterval = (e) => {
        setRule( { ...rule, interval: e.target.value } )
    }

    const setStatus = (e) => {
        setRule( { ...rule, active: e.target.value } )
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        handler(rule)
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className={'row'}>
                    <div className={'col-12'}>
                        <p>Rules are ways to find and collect data segments;
                            within this rule, you can add special conditions
                            that allow you to narrow down the massive amount of
                            data flowing into your deployment. Once you have
                            segmented your data, you can connect to services
                            like IFTTT, MySQL, Redlist, and more to further
                            store or process your data.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="form-group">
                            <TextField autoFocus={true} className="form-control" value={rule.name} onChange={(e) => setRule({ ...rule, name: e.target.value })} name="name" required id="ruleName" label="Name" variant="outlined" />
                        </div>
                    </div>

                    <div className="col-12 mb-4">
                        <div className="form-group">
                            <p className={'form-text font-weight-lighter" text-muted'}>How often would you like this Rule to run?</p>
                            <FormControl required className="form-control" variant="outlined">
                                <InputLabel id="ruleType">Interval</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="ruleType"
                                    value={rule.interval}
                                    onChange={(e) => setInterval(e)}>
                                    <MenuItem value={'5m'}>5m</MenuItem>
                                    <MenuItem value={'10m'}>10m</MenuItem>
                                    <MenuItem value={'15m'}>15m</MenuItem>
                                    <MenuItem value={'20m'}>20m</MenuItem>
                                    <MenuItem value={'25m'}>25m</MenuItem>
                                    <MenuItem value={'30m'}>30m</MenuItem>
                                    <MenuItem value={'35m'}>35m</MenuItem>
                                    <MenuItem value={'40m'}>40m</MenuItem>
                                    <MenuItem value={'45m'}>45m</MenuItem>
                                    <MenuItem value={'50m'}>50m</MenuItem>
                                    <MenuItem value={'55m'}>55m</MenuItem>
                                    <MenuItem value={'60m'}>60m</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    { inEdit ?
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <p className={'form-text font-weight-lighter" text-muted'}>Once you set to active, your actions will run if data is available.</p>
                                <FormControl required className="form-control" variant="outlined">
                                    <InputLabel id="ruleStatus">Status</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="ruleStatus"
                                        value={rule.active}
                                        onChange={(e) => setStatus(e)}>
                                        <MenuItem value={false}>Inactive</MenuItem>
                                        <MenuItem value={true}>Active</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        : null}

                    {!inWizard ?
                    <div className="col-12 mb-4">
                        <button type="submit" className="btn btn-primary"><FontAwesomeIcon icon={['fad', 'save']} /> Save</button>
                    </div> : null }
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(RuleForm)