import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextField } from '@material-ui/core'

const DashboardForm = ({ handler, dashboardData = { name: '', type: 'standard' } }) => {
    const initDashboard = {
        name: dashboardData.name
    }
    const [dashboard, setDashboard] = useState(initDashboard)

    const createDashboardHandler = (event) => {
        event.preventDefault()
        handler({ ...dashboardData, ...dashboard })
    }

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={createDashboardHandler}>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <TextField className="form-control" variant="outlined" value={dashboard.name} label="Dashboard Name" onChange={(e) => setDashboard({ ...dashboard, name: e.target.value })} name="name" required />
                            </div>
                        </div>

                        <div className="col-12 mt-4 text-right">
                            <button type="submit" className="btn btn-primary"><FontAwesomeIcon icon={['fad', 'save']} /> Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DashboardForm