import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextField, 
    FormHelperText, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem } from '@material-ui/core'

import SensorSelectList from './SensorSelectList'
import TagForm from '../Tag/TagForm'
import SensorTypes from '../../Config/SensorTypes'

const SensorForm = ({ saveHandler, sensorData }) => {
    let initSensor = {
        name: '',
        class: 'ble',
        type: '',
        sid: '',
        sku: '',
        tags: []
    }

    if (sensorData) {
        initSensor = sensorData
    }

    const [sensor, setSensor] = useState(initSensor)

    const submitHandler = async (event) => {
        event.preventDefault()
        saveHandler(sensor)
        setSensor(initSensor)
    }

    const tagChangeHandler = (tags) => {
        setSensor({ ...sensor, tags: tags })
    }

    const setSelectedSensor = (selectedSensor) => {
        console.log({ ...sensor, ...selectedSensor })
        setSensor({ ...sensor, ...selectedSensor })
    }

    return (
        <div className="row">
            <div className="col-8">

                <form onSubmit={submitHandler}>
                    <div className="row">

                        <div className="col-12">
                            <h4>Sensor Details</h4>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <div className="form-group">
                                        <TextField required className="form-control" value={sensor.sid} onChange={(e) => setSensor({ ...sensor, sid: e.target.value }) } label="Sensor ID" variant="outlined" />
                                    </div>
                                </div>

                                <div className="col-12 mb-4">
                                    <div className="form-group">
                                        <TextField className="form-control" value={sensor.name} onChange={(e) => setSensor({ ...sensor, name: e.target.value }) } label="Name" variant="outlined" />
                                    </div>
                                </div>

                                <div className="col-12 mb-4">
                                    <div className="form-group">
                                        <TextField className="form-control" value={sensor.sku} onChange={(e) => setSensor({ ...sensor, sku: e.target.value }) } label="SKU" variant="outlined" />
                                        <FormHelperText className="mt-4">The Sensor SKU is not required but is very helpful in identifying the features of the Sensor.</FormHelperText>
                                    </div>
                                </div>

                                <div className="col-6 mb-4">
                                    <FormControl className="form-control" variant="outlined">
                                        <InputLabel id="sensorClassLabel">Sensor Class</InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="sensorClassLabel"
                                            id="demo-simple-select-helper"
                                            value={sensor.class}
                                            onChange={(e) => setSensor( {...sensor, class: e.target.value} )}>
                                            <MenuItem value={'ble'}>BLE</MenuItem>
                                            <MenuItem value={'lora'}>LoRa</MenuItem>
                                            <MenuItem value={'4g'}>4G</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-6 mb-4">
                                    <FormControl className="form-control" variant="outlined">
                                        <InputLabel id="sensorTypeLabel">Sensor Type</InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="sensorTypeLabel"
                                            value={sensor.type}
                                            onChange={(e) => setSensor( {...sensor, type: e.target.value} )}>
                                                {SensorTypes[sensor.class].map((stype, key) => (
                                                    <MenuItem key={key} value={stype}>{stype}</MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-12 mt-4">
                                    <div className="form-group">
                                        <TagForm handler={tagChangeHandler} defaultTags={sensor.tags} tagType="sensor" />
                                    </div>
                                </div>

                                <div className="col align-self-end mt-4 text-right">
                                    <button className="btn btn-primary"><FontAwesomeIcon icon={['fad', 'save']} /> Save</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className="col-4">
                <h4>Import a Sensor</h4>
                <p>Select a Sensor below that is already active on the network to add it to your account.</p>
                <SensorSelectList selectHandler={setSelectedSensor} />
            </div>
        </div>
    )
}

export default SensorForm