import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Logout from "../../Pages/Cognito/Logout"
import { User } from "../../Controllers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'

const LeftNav = ({ location, currentCompany, currentUser }) => {
    const [userLevel, setUserLevel] = useState('member')

    const fetchUserLevel = async () => {
        const level = await User.UserLevel(currentUser, currentCompany)
        setUserLevel(level)
    }

    useEffect(async () => {
        await fetchUserLevel()
    }, [])

    return (
        <nav className="col-2 sidebarSubMenu">
            <ul className="nav flex-column nav-pills">
                <li className="sidebarSubMenu_header">Personal Settings</li>
                <li><Link className={location === 'profile' ? 'active nav-link' : 'nav-link'} to="/settings/profile"><FontAwesomeIcon icon="fa-duotone fa-user-astronaut" /> Profile</Link></li>
                <li><Link className={location === 'password' ? 'active nav-link' : 'nav-link'} to="/settings/password"><FontAwesomeIcon icon="fa-duotone fa-key" /> Change Password</Link></li>
                <li><span className="nav-link"><Logout><span className="FakeLink text-danger"><FontAwesomeIcon icon="fa-duotone fa-arrow-right-from-bracket" /> Logout</span></Logout></span></li>

                { userLevel === 'owner' ?
                    <>
                        <li className="sidebarSubMenu_header">Company Settings</li>
                        <li><Link className={location === 'company-profile' ? 'active nav-link' : 'nav-link'} to={'/company/' + currentCompany.id + '/edit'}><FontAwesomeIcon icon="fa-duotone fa-address-card" /> Company Profile</Link></li>
                        <li><Link className={location === 'company-users' ? 'active nav-link' : 'nav-link'} to={'/company/' + currentCompany.id + '/users'}><FontAwesomeIcon icon="fa-duotone fa-people-group" /> People</Link></li>
                        <li><Link className={location === 'company-invite' ? 'active nav-link' : 'nav-link'} to={'/company/' + currentCompany.id + '/invite'}><FontAwesomeIcon icon="fa-duotone fa-mailbox" /> Invite</Link></li>
                        <li><Link className={location === 'reporting-tags' ? 'active nav-link' : 'nav-link'} to="/tag"><FontAwesomeIcon icon="fa-duotone fa-tags" /> Reporting Tags</Link></li>
                    </>
                    : null }
                <li className={'sidebarSubMenu_header'}>Infrastructure</li>
                <li><Link className={location === 'hubs' ? 'active nav-link' : 'nav-link'} to="/hubs"><FontAwesomeIcon icon="fa-duotone fa-router" /> Hubs</Link></li>

                <li className="sidebarSubMenu_header">Rules & Profiles</li>
                <li><Link className={location === 'rules' ? 'active nav-link' : 'nav-link'} to="/rule"><FontAwesomeIcon icon="fa-duotone fa-code-branch" /> Rules</Link></li>
                { userLevel === 'owner' ?
                <li><Link className={location === 'profiles-index' ? 'active nav-link' : 'nav-link'} to="/settings/profiles"><FontAwesomeIcon icon="fa-duotone fa-binary" /> Service Profiles</Link></li>
                    : null }


                <li className="sidebarSubMenu_header">Applications</li>
                <li><Link className={'nav-link'} to="/select"><FontAwesomeIcon icon={duotone('shuffle')} /> Switch Applications</Link></li>
                <li><Link className={location === 'app-joinactivate' ? 'active nav-link' : 'nav-link'} to="/settings/company/activate"><FontAwesomeIcon icon="fa-duotone fa-layer-plus" /> Join / Activate Application</Link></li>
                { userLevel === 'owner' ?
                    <li><Link className={location === 'company-new' ? 'active nav-link' : 'nav-link'} to="/company/new"><FontAwesomeIcon icon="fa-duotone fa-folder-plus" /> Create a new Application</Link></li>
                    : null }
            </ul>
        </nav>
    )
}

export default LeftNav
