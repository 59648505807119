import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import { TextField } from '@material-ui/core'

import { GeoFence } from '../../Controllers'

const GeoFenceModal = ({ showModel, setShowModel, geoFenceData, dashboard, currentCompany, handler, editFenceData = null, inEdit = false, manageBtnHandler }) => {
    const { t } = useTranslation()
    const initGeoFence = {
        name: ''
    }
    const [geofence, setGeofence] = useState(initGeoFence)

    useEffect(() => {
        if (geoFenceData) {
            setGeofence({ ...geofence, ...geoFenceData, dashboard: dashboard.id })
        }
        if (editFenceData) {
            setGeofence(editFenceData)
        }
    }, [geoFenceData, editFenceData])

    const saveGeofence = async (event) => {
        event.preventDefault()
        if (inEdit) {
            let result = await GeoFence.Update(geofence)
            if (result) {
                setShowModel(false)
                setGeofence(initGeoFence)
                Hub.dispatch('Alert', {
                    event: 'show',
                    data: {
                        type: 'success', 
                        message: 'Saved!'
                    }
                })
            } else {
                Hub.dispatch('Alert', {
                    event: 'show',
                    data: {
                        type: 'error', 
                        message: 'Sorry, there was an issue updating your '+t('geofence.title')+', please try again.'
                    }
                })
            }
        } else {
            let result = await GeoFence.Create(currentCompany, geofence)
            if (result) {
                handler(result)
                setShowModel(false)
                setGeofence(initGeoFence)
                Hub.dispatch('Alert', {
                    event: 'show',
                    data: {
                        type: 'success', 
                        message: 'Saved!'
                    }
                })
            } else {
                Hub.dispatch('Alert', {
                    event: 'show',
                    data: {
                        type: 'error', 
                        message: 'Sorry, there was an issue creating your '+t('geofence.title')+', please try again.'
                    }
                })
            }
        }
    }

    const manageBtnClickHandler = (geofence) => {
        setShowModel(false)
        manageBtnHandler(geofence)
    }

    return (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            <form onSubmit={saveGeofence}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('geofence.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 mt-4">
                            <div className="form-group">
                                <TextField className="form-control" variant="outlined" value={geofence.name} label={`${t('geofence.title')} Name`} onChange={(e) => setGeofence({ ...geofence, name: e.target.value })} name="name" required />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {inEdit ? <button type="button" onClick={() => manageBtnClickHandler(geofence)} className="btn"><FontAwesomeIcon icon={['fad', 'draw-polygon']} /> Manage</button> : null}
                    <button type="submit" className="btn btn-primary">
                        <FontAwesomeIcon icon={['fad', 'save']} /> Save Changes
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(GeoFenceModal)
