/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      appId
      appSecret
      token
      type
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      savedResults {
        items {
          id
          ruleId
          resultId
          resultCount
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const companyByAppId = /* GraphQL */ `
  query CompanyByAppId(
    $appId: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyByAppId(
      appId: $appId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getDashboard = /* GraphQL */ `
  query GetDashboard($id: ID!) {
    getDashboard(id: $id) {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      geofences {
        items {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        nextToken
      }
      widgets {
        items {
          id
          name
          type
          sensorKey
          range
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
    }
  }
`;
export const listDashboards = /* GraphQL */ `
  query ListDashboards(
    $filter: ModelDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDashboardTag = /* GraphQL */ `
  query GetDashboardTag($id: ID!) {
    getDashboardTag(id: $id) {
      id
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const listDashboardTags = /* GraphQL */ `
  query ListDashboardTags(
    $filter: ModelDashboardTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        dashboard {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSavedResults = /* GraphQL */ `
  query GetSavedResults($id: ID!) {
    getSavedResults(id: $id) {
      id
      ruleId
      resultId
      resultCount
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSavedResultss = /* GraphQL */ `
  query ListSavedResultss(
    $filter: ModelSavedResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSavedResultss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ruleId
        resultId
        resultCount
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getResultsTag = /* GraphQL */ `
  query GetResultsTag($id: ID!) {
    getResultsTag(id: $id) {
      id
      createdAt
      updatedAt
      savedResults {
        id
        ruleId
        resultId
        resultCount
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const listResultsTags = /* GraphQL */ `
  query ListResultsTags(
    $filter: ModelResultsTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResultsTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        savedResults {
          id
          ruleId
          resultId
          resultCount
          name
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGeoFence = /* GraphQL */ `
  query GetGeoFence($id: ID!) {
    getGeoFence(id: $id) {
      id
      name
      paths
      center
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listGeoFences = /* GraphQL */ `
  query ListGeoFences(
    $filter: ModelGeoFenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGeoFences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        paths
        center
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        dashboard {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWidget = /* GraphQL */ `
  query GetWidget($id: ID!) {
    getWidget(id: $id) {
      id
      name
      type
      sensorKey
      range
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const listWidgets = /* GraphQL */ `
  query ListWidgets(
    $filter: ModelWidgetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWidgets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        sensorKey
        range
        createdAt
        updatedAt
        dashboard {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensor {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      phone_number
      notify_email
      notify_sms
      first_name
      last_name
      cognito_id
      status
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      companies {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserCompanies = /* GraphQL */ `
  query GetUserCompanies($id: ID!) {
    getUserCompanies(id: $id) {
      id
      level
      status
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const listUserCompaniess = /* GraphQL */ `
  query ListUserCompaniess(
    $filter: ModelUserCompaniesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserCompaniess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        level
        status
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      name
      sn
      customId
      type
      year
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const itemByType = /* GraphQL */ `
  query ItemByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      items {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const tagByName = /* GraphQL */ `
  query TagByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getItemTags = /* GraphQL */ `
  query GetItemTags($id: ID!) {
    getItemTags(id: $id) {
      id
      createdAt
      updatedAt
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const listItemTagss = /* GraphQL */ `
  query ListItemTagss(
    $filter: ModelItemTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemTagss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSensorTags = /* GraphQL */ `
  query GetSensorTags($id: ID!) {
    getSensorTags(id: $id) {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const listSensorTagss = /* GraphQL */ `
  query ListSensorTagss(
    $filter: ModelSensorTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensorTagss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        sensor {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSensor = /* GraphQL */ `
  query GetSensor($id: ID!) {
    getSensor(id: $id) {
      id
      sid
      sn
      customId
      class
      type
      sku
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSensors = /* GraphQL */ `
  query ListSensors(
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const sensorBySid = /* GraphQL */ `
  query SensorBySid(
    $sid: String
    $sortDirection: ModelSortDirection
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sensorBySid(
      sid: $sid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRule = /* GraphQL */ `
  query GetRule($id: ID!) {
    getRule(id: $id) {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      conditions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listRules = /* GraphQL */ `
  query ListRules(
    $filter: ModelRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRuleTag = /* GraphQL */ `
  query GetRuleTag($id: ID!) {
    getRuleTag(id: $id) {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const listRuleTags = /* GraphQL */ `
  query ListRuleTags(
    $filter: ModelRuleTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRuleTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rule {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRuleCondition = /* GraphQL */ `
  query GetRuleCondition($id: ID!) {
    getRuleCondition(id: $id) {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      condition {
        id
        operator
        metric
        value
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        geofence {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const listRuleConditions = /* GraphQL */ `
  query ListRuleConditions(
    $filter: ModelRuleConditionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRuleConditions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        rule {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        condition {
          id
          operator
          metric
          value
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCondition = /* GraphQL */ `
  query GetCondition($id: ID!) {
    getCondition(id: $id) {
      id
      operator
      metric
      value
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      geofence {
        id
        name
        paths
        center
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        dashboard {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listConditions = /* GraphQL */ `
  query ListConditions(
    $filter: ModelConditionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConditions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        operator
        metric
        value
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        geofence {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRuleAction = /* GraphQL */ `
  query GetRuleAction($id: ID!) {
    getRuleAction(id: $id) {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const listRuleActions = /* GraphQL */ `
  query ListRuleActions(
    $filter: ModelRuleActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRuleActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        rule {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        action {
          id
          name
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getActionUser = /* GraphQL */ `
  query GetActionUser($id: ID!) {
    getActionUser(id: $id) {
      id
      createdAt
      updatedAt
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const listActionUsers = /* GraphQL */ `
  query ListActionUsers(
    $filter: ModelActionUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActionUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        action {
          id
          name
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
