import React from "react"
import Modal from "react-bootstrap/Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SavedResultsDeleteModel = ({ showModel, setShowModel, submitHandler, currentCompany, savedResult }) => {
    return (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Saved Results</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 mb-4">
                        <section className={'callout'}>
                            <h4>You may lose access to the Results</h4>
                            <p>If this saved result set is older (> 90 days), you may lose access to the results once deleted. However, if you keep the 'Result ID,' you may later use it in the Reports Builder.</p>
                            <div className={'row DeleteBoxInCallout'}>
                                <div className={'col-12 text-center'}>
                                    <pre>Result ID: {savedResult.resultId}</pre>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn" onClick={() => setShowModel(false)}>
                    Cancel
                </button>
                <button onClick={() => submitHandler(savedResult)} className="btn btn-danger">
                    <FontAwesomeIcon icon="fa-duotone fa-trash-can" /> DELETE
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default SavedResultsDeleteModel
