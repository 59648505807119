import React, { useState } from "react"
import { connect } from "react-redux"
import Modal from "react-bootstrap/Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ProfileForm from './ProfileForm'

const NewProfileModel = ({ showModel, setShowModel, currentCompany, submitHander }) => {
    const [profile, setProfile] = useState(null)
    const saveProfileData = (profileData) => {
        setProfile(profileData)
    }
    return (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create a new Rule Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 mb-4">
                        <ProfileForm saveHandler={saveProfileData}/>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn" onClick={() => setShowModel(false)}>
                    Close
                </button>
                <button onClick={() => submitHander(profile)} className="btn btn-primary">
                    <FontAwesomeIcon icon={['fad', 'save']} /> Save
                </button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(NewProfileModel)
