import React from "react"

const IftttProfileForm = () => {
    return (
        <div className={'row'}>
            <div className={'col-12'}>
                <h5>IFTTT Rule Profile</h5>
                <p>Great news! To get started with IFTTT you can simply add this profile to any Rule and you will gain access to it in IFTTT. Once this Profile is added to a Rule it will show the information you will need to input on your IFTTT Applets.</p>
                <img src="/images/IFTTT-White-EcholoLogo-badge.png" alt="Works with IFTTT" />
            </div>
        </div>
    )
}

export default IftttProfileForm
