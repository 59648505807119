import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

// Settings
import UserEdit from '../User/UserEdit'

import LeftNav from '../../Components/Settings/LeftNav'

class SettingsIndex extends Component {

    render() {
        return (
            <>
                <div className="row">
                    <LeftNav location={'profile'} currentUser={this.props.currentUser} currentCompany={this.props.currentCompany} />

                    <div className="col-10 ml-auto pl-5">
                        <div className="row">
                            <div className="col-12 pl-4">
                                <UserEdit />
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(withTranslation()(SettingsIndex))