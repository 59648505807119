import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Sensor, Item } from '../../Controllers'

import Loading from '../../Helpers/Components/Loading'

const DashboardEmpty = ({ currentCompany, newDashboardBtn }) => {
    const { t } = useTranslation()

    const [SensorCount, setSensorCount] = useState(0)
    const [ItemCount, setItemCount] = useState(0)
    const [loading, setLoading] = useState(true)

    const fetchCounts = async () => {
        let sensors = await Sensor.All(currentCompany)
        let items = await Item.All(currentCompany)
        setSensorCount(sensors.length)
        setItemCount(items.length)
        setLoading(false)
    }

    useEffect(() => {
        fetchCounts()
    }, [])

    return (
        <div className="row justify-content-md-center">

            <div className="col-7">
                <section>
                    <h2><FontAwesomeIcon icon={['fad', 'chart-bar']} /> Explorer Dashboards</h2>
                    <p>Welcome to your Explorer Dashboard here; you can create custom displays for everything from single {t('sensor.titles')} or {t('item.titles')} to groups of {t('item.titles')}. If you are using GPS-enabled {t('sensor.titles')}, you can also create custom Map displays with {t('geofence.title_plural')} and more.</p>
                    
                    {SensorCount > 0 && ItemCount > 0 ?
                    <div className="row mb-4">
                        <div className="col-12">
                            <section className="callout">
                                <h4>Time to create a new Dashboard!</h4>
                                <p>Now that you have added at least one {t('sensor.title')} and one {t('item.title')}, you can start to customize how you want to display its data! Click the button below to create a brand new Dashboard.</p>
                                {newDashboardBtn}
                            </section>
                        </div>
                    </div> : null }

                    <div className="row mt-2">
                        <div className="col-2 text-center mt-4">
                            {loading ? <Loading isOpen={true} /> : 
                            <>
                                {SensorCount > 0 ? <h2 className="text-success"><FontAwesomeIcon icon={['fad', 'check']} /></h2> : <h2 className="text-danger"><FontAwesomeIcon icon={['fad', 'times']} /></h2>}
                            </>
                            }
                        </div>

                        <div className="col-10">
                            <h4><FontAwesomeIcon icon={['fad', 'microchip']} /> Added {t('sensor.titles')}</h4>
                            <p>Before you get started with a new custom Dashboard, please import or add new {t('sensor.titles')} to your account; this will allow you to get the most out of your incoming data.</p>
                            <Link to="/sensor" className="btn btn-sm btn-outline-primary btn-block"><FontAwesomeIcon icon={['fad', 'microchip']} /> Add {t('sensor.titles')}</Link>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-2 text-center mt-4">
                            {loading ? <Loading isOpen={true} /> : 
                            <>
                                {ItemCount > 0 ? <h2 className="text-success"><FontAwesomeIcon icon={['fad', 'check']} /></h2> : <h2 className="text-danger"><FontAwesomeIcon icon={['fad', 'times']} /></h2>}
                            </>
                            }
                        </div>

                        <div className="col-10">
                            <h4><FontAwesomeIcon icon={['fad', 'barcode-read']} /> Added {t('item.titles')}</h4>
                            <p>After you add {t('sensor.titles')}, connect them to {t('item.titles')} to customize your data to your specific needs.</p>
                            <Link to="/item" className="btn btn-sm btn-outline-primary btn-block"><FontAwesomeIcon icon={['fad', 'barcode-read']} /> Add {t('item.titles')}</Link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(DashboardEmpty)