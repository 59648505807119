import React, { useState, useEffect } from 'react'
import { Hub } from '@aws-amplify/core'
import { Link } from 'react-router-dom'
import $ from 'jquery'

import { Condition } from '../../../Controllers'

import { ConditionOperatorConverter } from '../../../Helpers/TextHelpers'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { RemoveCondition } from "../../../Services/Echolo/Rules/Condition"

const ConditionTable = ({ currentCompany, conditionData = null }) => {
    const [conditions, setConditions] = useState(conditionData)

    useEffect(() => {
        setConditions(conditionData)
    }, [conditionData])

    const removeConditionFromRule = async (ruleCondition) => {
        let removed = await RemoveCondition(currentCompany, ruleCondition)
        if (!removed) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, we could not remove this Condition.'
                }
            })
        } else {
            $('#rulecondition_'+ruleCondition.conditionId).hide('fast')
        }
    }

    return (
        <>
        {conditions ?
            <table className="table table-sm table-hover">
                <thead>
                    <tr>
                        <th scope="col">Data Key</th>
                        <th scope="col">Comparator</th>
                        <th scope="col">Value</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {conditions.map(data => (
                        <tr id={'rulecondition_' + data.conditionId} key={data.conditionId}>
                            <td><span className="badge badge-secondary">{data.conditionKey}</span></td>
                            <td>{ConditionOperatorConverter(data.comparator)}</td>
                            <td>{data.conditionValue}</td>
                            <td><button onClick={() => removeConditionFromRule(data)} className="btn btn-sm btn-outline-danger"><FontAwesomeIcon icon="fa-duotone fa-trash-can" /> Remove</button></td>
                        </tr>
                    ))}
                </tbody>
            </table> : null }
        </>
    )
}

export default ConditionTable