const types = {
    "base": [
        "Vehicle",
        "Asset"
    ],
    "construction": [
        "Vehicle",
        "Trailer",
        "Container",
        "Attachment",
        "Small Tool",
        "Small Equipment",
        "Large Equipment"
    ]
}

export default types