import React, { Component } from 'react'
import { Hub } from '@aws-amplify/core'
import { withTranslation } from 'react-i18next'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'

import { GeoFence } from '../../Controllers'

import GeoFenceModal from './GeoFenceModal'
class BasicMap extends Component {
    state = {
        userLocation: { latitude: 0.00, longitude: 0.00 },
        centerMap: { latitude: 0.00, longitude: 0.00 },
        items: this.props.items,
        geoPolyPaths: [],
        newGeoFence: {},
        showModal: false,
        showEditModal: false,
        dashboard: this.props.dashboard,
        map: null,
        editFenceData: null
    }

    _drawingManager = null

    addFenceToMap = (google, map, fence) => {
        let paths = JSON.parse(fence.paths)
        let GooglePaths = []

        paths.forEach(path => {
            GooglePaths.push(new google.maps.LatLng(path.lat, path.lng))
        })

        let mapFence = new google.maps.Polygon({
            paths: GooglePaths,
            strokeColor: "#2b1869",
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillColor: "#6333FF",
            fillOpacity: 0.35
        })

        mapFence.setMap(map)

        mapFence.fence = fence
        let center = JSON.parse(fence.center)

        const infowindow = new google.maps.InfoWindow({
            content: fence.name,
            position: new google.maps.LatLng(center.latitude, center.longitude)
        })

        google.maps.event.addListener(mapFence, 'click', () => {
            this.setState({ editFenceData: fence, centerMap: { latitude: center.latitude, longitude: center.longitude } })
            this.setEditShowModal(true)
        })
        
        google.maps.event.addListener(mapFence, 'mouseover', () => {
            infowindow.open(map)
        })

        google.maps.event.addListener(mapFence, 'mouseout', () => {
            infowindow.close(map)
        })

    }

    fetchGeoFences = async (google, map) => {
        const { dashboard } = this.state
        let fences = await GeoFence.AllByDashboard(dashboard.id)
        console.log(fences)
        if (fences) {
            fences.forEach(fence => {
                this.addFenceToMap(google, map, fence)
            })
            this.setState({
                geoPolyPaths: fences
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, we failed to load your GeoFences'
                }
            })
        }
    }

    componentDidMount = () => {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    userLocation: { longitude: position.coords.longitude, latitude: position.coords.latitude }
                })
                this.props.doneLoading()
            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        let { selectedItem } = this.props
        let oldSelectedItem = prevProps.selectedItem
        
        if (prevProps.items !== this.props.items) {
            this.setState({
                items: this.props.items
            })
        }

        if (prevState.userLocation.longitude !== this.state.userLocation.longitude) {
            this.setState({
                userLocation: { longitude: this.state.userLocation.longitude, latitude: this.state.userLocation.latitude },
                centerMap: { longitude: this.state.userLocation.longitude, latitude: this.state.userLocation.latitude }
            })
        }

        if (!prevState.selectedItem) {
            oldSelectedItem = { location: { lat: "0.00", long: "0.00" } }
        }

        if (!selectedItem) {
            selectedItem = { location: { lat: "0.00", long: "0.00" } }
        }

        if (selectedItem.location.lat !== oldSelectedItem.location.lat && selectedItem.location.long !== prevState.centerMap.longitude) {
            this.setState({
                centerMap: { longitude: selectedItem.location.long, latitude: selectedItem.location.lat }
            })
        }
    }

    displayData = (mapProps, map, clickEvent, item) => {
        this.props.selectedItemHandler(item)
        this.setState({
            userLocation: { latitude: item.location.lat, longitude: item.location.long }
        })
    }

    loadMap = (mapProps, map) => {
        if (this.props && this.props.google) {
            this.setState({ map: map })
            this.fetchGeoFences(this.props.google, map)

            $('#CreateGeofence').on('click', () => {
                $('#CreateGeofence').hide('fast')
                $('#DoneWithGeofence').show('fast')
                const {google} = mapProps
                this._drawingManager = new google.maps.drawing.DrawingManager({
                    drawingMode: null,
                    drawingControl: true,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [
                            google.maps.drawing.OverlayType.POLYGON
                        ]
                    },
                    polygonOptions: {
                        clickable: true,
                        strokeColor: "#2b1869",
                        strokeOpacity: 0.8,
                        strokeWeight: 3,
                        fillColor: "#6333FF",
                        fillOpacity: 0.35
                    },
                    map: map
                })
                this._drawingManager.setMap(map)
                google.maps.event.addListener(this._drawingManager, 'overlaycomplete', (event) => {
                    if (event.type === 'polygon' || event.type === 'rectangle' || event.type === 'circle') {
                        this.buildPlyArray(event.overlay, google, map)
                        event.overlay.setMap(null)
                    }
                })
            })

            $('#DoneWithGeofence').on('click', () => {
                $('#CreateGeofence').show('fast')
                $('#DoneWithGeofence').hide('fast')
                // this._drawingManager.setOptions({
                //     drawingControl: false
                // })
                this._drawingManager.setMap(null)
            })

        }
    }

    buildPlyArray = (polygon, google, map) => {
        var coordArr = []
        for (var i = 0; i < polygon.getPath().getLength(); i++) {
            let latLongStr = polygon.getPath().getAt(i).toUrlValue(6)
            latLongStr = latLongStr.split(',')
            let pointArr = { lat: latLongStr[0], lng: latLongStr[1] }
            coordArr.push(pointArr)
        }

        var bounds = new google.maps.LatLngBounds()

        let polygonCoords = []
        coordArr.forEach(cords => {
            polygonCoords.push(new google.maps.LatLng(cords.lat, cords.lng))
        })

        for (var x = 0; x < polygonCoords.length; x++) {
            bounds.extend(polygonCoords[x])
        }

        // Center of new poly
        bounds = bounds.getCenter().toJSON()

        this.setState({
            newGeoFence: { paths: JSON.stringify(coordArr), center: JSON.stringify({ latitude: bounds.lat, longitude: bounds.lng  })},
            centerMap: { latitude: bounds.lat, longitude: bounds.lng },
            showModal: true
        })
    }

    newGeoFenceHandler = (fenceData) => {
        this.addFenceToMap(this.props.google, this.state.map, fenceData)
    }

    setShowModal = (status) => {
        this.setState({
            showModal: status
        })
    }

    setEditShowModal = (status) => {
        this.setState({
            showEditModal: status
        })
    }

    GeoFenceModalEditManageBtn = (manageGeoFenceData) => {
        // sending this back to MapIndex to open the GeoFenceShow page.
        this.props.geoFenceManageHandler(manageGeoFenceData)
    }

    render() {
        const { userLocation, centerMap, showModal, newGeoFence, dashboard, items, showEditModal, editFenceData } = this.state
        const { t } = this.props
        return (
            <div className="BasicMapContainer">
                <Map google={this.props.google}
                    onReady={this.loadMap}
                    center={{ lat: centerMap.latitude, lng: centerMap.longitude }} 
                    initialCenter={{ lat: userLocation.latitude, lng: userLocation.longitude }} 
                    streetViewControl={false}
                    yesIWantToUseGoogleMapApiInternals
                    zoom={14}>
                    
                    
                    
                    { items.map(item => (
                        <Marker key={item.id} onClick={(mapProps, map, clickEvent) => this.displayData(mapProps, map, clickEvent, item)} name={item.name} position={{ lat: item.location.lat, lng: item.location.long }} />
                    ))}

                    <button id="CreateGeofence" className="btn btn-primary btn-sm BasicMapGeofenceBtn"><FontAwesomeIcon icon={['fad', 'draw-polygon']} /> Create a {t('geofence.title')}</button>
                    <button id="DoneWithGeofence" className="btn btn-secondary btn-sm BasicMapGeofenceDoneBtn"><FontAwesomeIcon icon={['fad', 'times-circle']} /> Done</button>
                </Map>
                <GeoFenceModal handler={this.newGeoFenceHandler} dashboard={dashboard} geoFenceData={newGeoFence} showModel={showModal} setShowModel={this.setShowModal} />
                <GeoFenceModal showModel={showEditModal} setShowModel={this.setEditShowModal} editFenceData={editFenceData} inEdit={true} manageBtnHandler={this.GeoFenceModalEditManageBtn} />
            </div>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyBx6xJVPPzXuleTLXm7ot7kn48FgThbOvs'),
    libraries: ['drawing']
})(withTranslation()(BasicMap))

