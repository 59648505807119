import { API, graphqlOperation } from 'aws-amplify'
import _ from 'lodash'

import { UserAuth } from '../Functions/Auth'

import { createUser, createUserCompanies, updateUserCompanies, deleteUserCompanies } from '../graphql/mutations'
import { updateUser } from './graphql/mutations'
import { listUsers, listActionUsers, listUserCompaniess } from '../graphql/queries'
import { userByEmail, getUser } from './graphql/querys'

import { Dashboards, Action } from '.'
export const Create = async (user) => {
    const input = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        cognito_id: user.cognito_id,
        status: 'active'
    }
    try {
        return await API.graphql(graphqlOperation(createUser, { input }))
    } catch (e) {
        console.warn("Failed to create the User:", user, e)
    }
}

// --- UpdateUser
// UpdateUser(user, { first_name: "Name", ... })
export const Update = async (updatedData) => {
    const input = updatedData
    delete input.companies
    delete input.createdAt
    delete input.updatedAt
    delete input.dashboards

    try {
        await UserAuth.Update(updatedData)
        return (await API.graphql(graphqlOperation(updateUser, { input }))).data.updateUser
    } catch (e) {
        console.warn("There was an issue updating the user:", e)
        return false
    }
}

export const UpdateWOAuth = async (updatedData) => {
    const input = updatedData
    delete input.companies
    delete input.createdAt
    delete input.updatedAt
    delete input.dashboards

    try {
        return (await API.graphql(graphqlOperation(updateUser, { input }))).data.updateUser
    } catch (e) {
        console.warn("There was an issue updating the user:", e)
        return false
    }
}

export const UpdateStatus = async (user, company, status) => {
    try {
        let userCompany = (await API.graphql(graphqlOperation(listUserCompaniess))).data.listUserCompaniess.items.filter(uc => uc.company.id === company.id && uc.user.id === user.id)
        userCompany = userCompany[0]
        const input = {
            id: userCompany.id,
            status: status
        }
        return (await API.graphql(graphqlOperation(updateUserCompanies, { input }))).data.updateUserCompanies
    } catch (e) {
        console.warn("Failed to update the user Status", e)
        return false
    }
}

export const All = async () => {
    return await API.graphql(graphqlOperation(listUsers))
}

// --- FindUser
// FindUser({ email: "email@email.io" })
// FindUser({ id: '123abc' })
export const Find = async (params = {email: null, id: null}) => {
    if (params.email) {
        try {
            const input = {
                email: params.email
            }
            return (await API.graphql(graphqlOperation(userByEmail, input ))).data.userByEmail.items[0]
        } catch (e) {
            console.warn("Failed to find the User", params, e)
        }
    }

    if (params.id) {
        try {
            const input = {
                id: params.id
            }
            return (await API.graphql(graphqlOperation(getUser, input ))).data.getUser
        } catch (e) {
            console.warn("Failed to find the User", params, e)
        }
    }
}

export const AssignCompany = async (user, company) => {
    const input = {
        userCompaniesCompanyId: company.id,
        userCompaniesUserId: user.id,
        level: company.userLevel,
        status: 'active'
    }

    try {
        return (await API.graphql(graphqlOperation(createUserCompanies, { input }))).data.createUserCompanies
    } catch (e) {
        console.warn("Failed to assign user to company", e)
        return false
    }
}

export const UpdateCompanyPermission = async (user, company) => {
    try {
        const selectedUser = await Find({ id: user.id })
        const UserCompany = selectedUser.companies.items.filter(uc => uc.company.id === company.id)[0]
        const input = {
            level: user.level,
            id: UserCompany.id
        }
        return await API.graphql(graphqlOperation(updateUserCompanies, { input }))
    } catch (e) {
        console.warn("Failed to UpdateCompanyPermission on this User", e)
        return false
    }
}

export const Companies = async (user) => {
    //return (await DataStore.query(UserCompanies)).filter(c => c.user.id === user.id)
}

export const UserLevel = async (user, company) => {
    if (!_.isEmpty(company)) {
        let userLevel = user.companies.items.filter(uc => uc.company.id === company.id)[0]
        return userLevel.level
    } else {
        return false
    }
}

export const Delete = async (user, company) => {

    try {
        // Delete: ActionUser, UsersCompanies, (Dashboards, GeoFence, Widgets)
        const actionUsers = (await API.graphql(graphqlOperation(listActionUsers))).data.listActionUsers.items.filter(au => au.user.id === user.id && au.action.company.id === company.id)
        // Loop actionUsers
        actionUsers.forEach(actionUser => {
            Action.DeleteActionUser(actionUser)
        })

        const dashboards = await Dashboards.All(company, user)
        // Loop dashboards
        dashboards.forEach(dashboard => {
            Dashboards.Delete(dashboard)
        })

        const userCompanies = (await API.graphql(graphqlOperation(listUserCompaniess))).data.listUserCompaniess.items.filter(uc => uc.company.id === company.id)
        // Loop UserCompanies - yes it's miss spelled as 'listUserCompaniess'
        userCompanies.forEach(userCompany => {
            API.graphql(graphqlOperation(deleteUserCompanies, { input: { id: userCompany.id } }))
        })

        return true
    } catch (e) {
        console.warn("Failed to delete User", e)
        return false
    }

}


// PRIVATE


