import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Rule } from '../../Controllers'
import { UpdateRule } from '../../Services/Echolo/Rules/Rule'

import RuleForm from './RuleForm'
const RuleEditFormModal = ({ showModel, setShowModel, ruleData, currentCompany, editHandler }) => {
    const [rule, setRule] = useState(ruleData)
    const [updatedRule, setUpdatedRule] = useState(null)

    useEffect(() => {
        setRule(ruleData)
    }, [ruleData])

    const ruleHandler = (incomingType, data) => {
        setUpdatedRule(data)
    }

    const editRuleHandler = async () => {
        let updatedRuleData = await UpdateRule(currentCompany, rule.ruleId, updatedRule)

        if (updatedRuleData) {
            // Close the Model
            setShowModel(false)
            editHandler({ ...rule, ...updatedRuleData })
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Updated'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue updating this Rule'
                }
            })
        }
    }

    return (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Update Rule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RuleForm inWizard={true} wizardHandler={ruleHandler} ruleData={rule} />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn" onClick={() => setShowModel(false)}>
                    Close
                </button>
                <button onClick={() => editRuleHandler(updatedRule)} className="btn btn-primary">
                    <FontAwesomeIcon icon={['fad', 'save']} /> Save Changes
                </button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(RuleEditFormModal)