import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TagChip from "../../Tag/TagChip"
import { Link } from "react-router-dom"

const ReportResultsTable = ({ savedResultsData, editModel }) => {
    const listTags = (tags) => {
        return tags.map((tag, key) => {
            return (
                <TagChip key={key} tag={tag.tag} />
            )
        })
    }
    return (
        <table className="table table-hover">
            <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Tags</th>
                <th scope="col">Rule</th>
                <th scope="col">Results Count</th>
                <th scope="col">Options</th>
            </tr>
            </thead>
            <tbody>
                { savedResultsData.map((result) => (
                    <tr key={result.id}>
                        <td><Link to={'/reports/view/saved/' + result.id}><FontAwesomeIcon icon="fa-duotone fa-bookmark" /> {result.name}</Link></td>
                        <td>{listTags(result.tags.items)}</td>
                        <td><Link to={'/rule/'+ result.rule.ruleId +'/view'}><FontAwesomeIcon icon="fa-duotone fa-code-branch" /> {result.rule.name}</Link></td>
                        <td>{result.resultCount}</td>
                        <td><button onClick={() => editModel(true, result)} className={'btn btn-outline-primary btn-sm'}><FontAwesomeIcon icon="fa-duotone fa-pen-to-square" /> Edit</button></td>
                    </tr>
                )) }
            </tbody>
        </table>
    )
}

export default ReportResultsTable
