import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RuleForm from "./RuleForm"

const NewRuleModel = ({ showModel, setShowModel, currentCompany, saveRule }) => {
    const [rule, setRule] =useState({
        name: '',
        interval: '60m',
        active: false
    })
    const saveRuleData = (type, data) => {
        setRule({ ...data, active: false })
    }
    return (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon="fa-duotone fa-code-branch" /> New Rule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="form-group">
                            <RuleForm inWizard={true} wizardHandler={saveRuleData} />
                        </div>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn" onClick={() => setShowModel(false)}>
                    Close
                </button>
                <button onClick={() => saveRule(rule)} className="btn btn-primary">
                    <FontAwesomeIcon icon={['fad', 'save']} /> Create Rule
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default NewRuleModel
