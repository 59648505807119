import { API, graphqlOperation } from 'aws-amplify'
import _ from 'lodash'

import { createItem, createItemTags, deleteItemTags, updateItem, updateSensor, deleteItem } from '../graphql/mutations'
import { listItemTagss, listRules, listSensors } from '../graphql/queries'
import { listItems, getItem, searchItems, itemByType } from './graphql/querys'

import { Tag, Widget, Dashboards, Rule } from '.'

import { asyncForEach } from '../Helpers/ArrayHelpers'

export const Create = async (company, data) => {
    const sensors = data.sensors
    const tags = data.tags

    delete data.sensors
    delete data.tags
    const input = { ...data, itemCompanyId: company.id }

    const item = (await API.graphql(graphqlOperation(createItem, { input }))).data.createItem

    sensors.forEach(sensor => {
        saveItemToSensor(sensor, item)
    })

    tags.forEach(tag => {
        saveTagForItem(company, tag).then(newTag => {
            saveItemTag(item, newTag)
        })
    })

    return item
}

export const Update = async (company, itemId, updatedData) => {
    const sensors = updatedData.sensors
    delete updatedData.sensors

    try {
        // Remove all Tags & Sensors for this Item.
        await RemoveAllTags(itemId)
        await RemoveAllSensors(itemId)

        updatedData.itemCompanyId = company.id

        let tags = updatedData.tags
        const input = updatedData
        delete input.company
        delete input.sensors
        delete input.tags
        delete input.createdAt
        delete input.updatedAt

        let updatedItem = (await API.graphql(graphqlOperation(updateItem, { input }))).data.updateItem

        // Add the Sensors back to the Item
        sensors.forEach(sensor => {
            saveItemToSensor(sensor, updatedItem)
        })

        // Now Add all the tags back
        tags.forEach(tag => {
            findOrCreate(company, tag).then(newOrFoundTag => {
                saveItemTag(updatedItem, newOrFoundTag)
            })
        })

        return updatedItem
    } catch (e) {
        console.log("Item.Update Failed: ", e)
        return false
    }
}

export const Find = async (company, itemId) => {
    return (await API.graphql(graphqlOperation(getItem, { id: itemId } ))).data.getItem
}

export const FindByType = async (company, type) => {
    return (await API.graphql(graphqlOperation(itemByType, { type: type }))).data.itemByType.items.filter(i => i.company.id === company.id)
}

export const Search = async (company, searchText, options = false) => {
    const searchInput = {
        filter: {
            or: [
                { name: { matchPhrasePrefix: searchText } },
                { sn: { matchPhrasePrefix: searchText } },
                { customId: { matchPhrasePrefix: searchText } },
                { type: { matchPhrasePrefix: searchText } },
                { year: { matchPhrasePrefix: searchText } }
            ]
        },
        sort: {
            field: "name",
            direction: "asc"
        }
    }
    if (options) {
        // options filterByID = ["1234"]
        try {
            return ((await API.graphql(graphqlOperation(searchItems, searchInput))).data.searchItems.items.filter(i => i.company.id === company.id)).filter(i => options.filterByID.includes(i.id))
        } catch(e) {
            console.warn("Failed to search with options", e)
        }
    } else {
        return (await API.graphql(graphqlOperation(searchItems, searchInput))).data.searchItems.items.filter(i => i.company.id === company.id)
    }
}

export const All = async (company) => {
    let items = (await API.graphql(graphqlOperation(listItems))).data.listItems.items.filter(i => i.company.id === company.id)
    // Attach any ItemDashboards to the item
    let updatedItems = []
    await asyncForEach(items, async (item) => {
        item.dashboards = await Dashboards.ItemFindByItemId(item.id)
        updatedItems.push(item)
    })

    return updatedItems
}

export const RemoveAllTags = async (itemId) => {
    try {
        let tagItems = (await API.graphql(graphqlOperation(listItemTagss))).data.listItemTagss.items.filter(it => it.item.id === itemId)
        // @TODO: HAVE TO LOOP OR find a new way to bulk delete.
        tagItems.forEach((tagModel) => {
            deleteItemTag(tagModel)
        })
    } catch (e) {
        console.warn("RemoveAllTags Not needed or has failed: ", e)
    }
}

export const RemoveAllSensors = async (itemId) => {
    try {
        let sensors = (await API.graphql(graphqlOperation(listSensors))).data.listSensors.items.filter(s => s.item.id === itemId)
        if (sensors.length > 0) {
            sensors.forEach(sensor => {
                saveItemToSensor(sensor, { id: null })
            })
        }

    } catch (e) {
        console.warn("Failed to Remove Sensors from Item.", e)
    }
}

export const Delete = async (item) => {
    try {
        await RemoveAllTags(item.id)
        await RemoveAllSensors(item.id)
        await RemoveAllItemWidgets(item.id)
        await RemoveAllItemDashboards(item.id)
        await RemoveAllRules(item.id)

        return (await API.graphql(graphqlOperation(deleteItem, { input: { id: item.id } }))).data.deleteItem
    } catch (e) {
        console.warn("Failed to DELETE Item", e)
        return false
    }
}

// Private

const RemoveAllRules = async (itemId) => {
    try {
        let rules = (await API.graphql(graphqlOperation(listRules))).data.listRules.items.filter(r => r.item.id === itemId)
        if (rules.length > 0) {
            rules.forEach(rule => {
                Rule.Delete(rule)
            })
        }
    } catch (e) {
        console.warn("Failed to remove the Item Rules.", e)
    }
}

const RemoveAllItemWidgets = async (itemId) => {
    try {
        let widgets = await Widget.FindByItemId(itemId)
        console.log("RemoveAllItemWidgets", widgets)
        if (widgets.length > 0) {
            widgets.forEach(widget => {
                Widget.Delete(widget)
            })
        }
    } catch(e) {
        console.warn("Failed to DELETE Widget", e)
    }
}

const RemoveAllItemDashboards = async (itemId) => {
    try {
        let dashboards = await Dashboards.FindByItemId(itemId)
        console.log("RemoveAllItemDashboards", dashboards)
        if (dashboards.length > 0){
            dashboards.forEach(dashboard => {
                Dashboards.Delete(dashboard)
            })
        }
    } catch(e) {
        console.warn("Failed to DELETE Dashboard", e)
    }
}

const deleteItemTag = async (itemModel) => {
    return await API.graphql(graphqlOperation(deleteItemTags, { input: { id: itemModel.id } }))
}

const saveTagForItem = async (company, tag) => {
    let tagData = { name: tag, type: 'item' }
    return await Tag.Create(company, tagData)
}

const saveItemTag = async (newItem, newTag) => {
    const input = {
        itemTagsItemId: newItem.id,
        itemTagsTagId: newTag.id
    }
    await API.graphql(graphqlOperation(createItemTags, { input }))
}

const saveItemToSensor = async (sensor, item) => {
    const input = {
        id: sensor.id,
        sensorItemId: item.id
    }
    try {
        await API.graphql(graphqlOperation(updateSensor, { input }))
    } catch (e) {
        console.warn("Failed to save Item to Sensor.", sensor, item)
        return false
    }
}

const findOrCreate = async (company, tagName) => {
    let tag = Tag.Find(company, tagName)
    if (_.isEmpty(tag)) return await saveTagForItem(company, tagName)
    return tag
}