import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'

import { Sensor } from '../../Controllers'

import SensorForm from '../../Components/Sensor/SensorForm'

class SensorNew extends Component {

    createHandler = async (sensor) => {
        let results = await Sensor.Create(this.props.currentCompany, sensor)
        if (results) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Saved!'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue saving this Sensor.'
                }
            })
        }
    }

    render() {
        return (
            <div className="row InnerContainer">
                <div className="col-12">
                    <h2>New Sensor</h2>
                </div>
                <div className="col-12">
                    <section>
                        <SensorForm saveHandler={this.createHandler} />
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(SensorNew)