import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import Modal from 'react-bootstrap/Modal'
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

import { Sensor, Widget, Item } from '../../Controllers'
import { Device } from '../../Services/Echolo'

const WidgetCreateModel = ({ dashboard, currentCompany, currentUser }) => {
    const { t } = useTranslation()
    const initWidget = {
        name: '',
        sensorKey: '',
        sensorId: '',
        sensor: null,
        itemId: '',
        tags: [],
        type: ''
    }
    const [showModel, setShowModel] = useState(false)
    const [widget, setWidget] = useState(initWidget)
    const [sensorIds, setSensorIds] = useState([])
    const [items, setItems] = useState([])
    const [remoteDeviceData, setRemoteDeviceData] = useState(null)

    const createWidgetHandler = async (event) => {
        event.preventDefault()
        let newWidget = await Widget.Create(dashboard, widget)
        if (newWidget) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Your new Widget has been created and is ready to use!'
                }
            })
            setShowModel(false)
            setSensorIds([])
            setWidget(initWidget)
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue creating your Widget, please try again.'
                }
            })
        }
    }

    const fetchSensors = async () => {
        let sensors = await Sensor.All(currentCompany)
        let sensorIdsArray = sensors.map(s => { return { id: s.id, sid: s.sid, name: s.name, type: s.type } })
        setSensorIds([ ...sensorIdsArray ])
    }

    const fetchItemSensors = async () => {
        let item = await Item.Find(currentCompany, dashboard.item.id)
        let sensorIdsArray = item.sensors.items.map(s => { return { id: s.id, sid: s.sid, name: s.name, type: s.type } })
        setSensorIds([ ...sensorIdsArray ])
    }

    const fetchItems = async () => {
        // The the dashboard is already and item dash we can just return the current item
        if (dashboard.type === 'item') {
            let item = await Item.Find(currentCompany, dashboard.item.id)
            setItems([ item ])
        } else {
            let items = await Item.All(currentCompany)
            setItems(items)
        }
    }

    const openModel = () => {
        // Go Fetch sensors
        switch(dashboard.type) {
            case 'standard':
                fetchSensors()
                break
            case 'item':
                fetchItemSensors()
                break
            default:
                fetchSensors()
                return
        }
        setShowModel(true)
    }

    const selectSensor = async (e) => {
        // Pull the ID from the sensorIdsArray
        let sensor = sensorIds.filter(s => s.sid === e.target.value)
        setWidget({ ...widget, sensorId: e.target.value, sensor: sensor[0].id })

        // API call to grab profilePacket
        let device = await Device.Find(currentCompany, e.target.value)
        setRemoteDeviceData(device)
    }

    const selectWidgetType = (e) => {
        if (e.target.value === 'list') {
            fetchItems()
        }
        setWidget({ ...widget, type: e.target.value })
    }

    const selectWidgetRange = (e) => {
        setWidget({ ...widget, range: e.target.value })
    }

    const selectItem = async (e) => {
        setWidget({ ...widget, itemId: e.target.value })
    }

    const createModel = (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            <form onSubmit={createWidgetHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a Widget</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <p>Add a widget to {dashboard.name}</p>
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <div className="form-group">
                                        <TextField className="form-control" variant="outlined" value={widget.name} label="Widget Name" onChange={(e) => setWidget({ ...widget, name: e.target.value })} name="name" required />
                                    </div>
                                </div>

                                <div className="col-12 mb-4">
                                    <div className="form-group">
                                        <FormControl className="form-control" variant="outlined" required>
                                            <InputLabel id="widget-type">Widget Type</InputLabel>
                                                <Select
                                                    labelId="widget-type"
                                                    value={widget.type}
                                                    onChange={(e) => selectWidgetType(e)}
                                                >
                                                <MenuItem value={'text'}>Text</MenuItem>
                                                <MenuItem value={'graph'}>Graph</MenuItem>
                                                <MenuItem value={'list'}>List</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {sensorIds.length > 0 && ["text", "graph"].includes(widget.type) ? 
                                <div className="col-12 mb-4">
                                    <div className="form-group">
                                        <FormControl className="form-control" variant="outlined" required>
                                            <InputLabel id="widget-sensor">Sensor</InputLabel>
                                                <Select
                                                    labelId="widget-sensor"
                                                    value={widget.sensorId}
                                                    onChange={(e) => selectSensor(e)}
                                                >
                                                    { sensorIds.map(sensor => (
                                                        <MenuItem key={sensor.sid} value={sensor.sid}>{sensor.name} [{sensor.type} {sensor.sid}]</MenuItem>
                                                    )) }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div> : null }

                                {sensorIds.length > 0 && ["graph"].includes(widget.type) ?
                                    <div className="col-12 mb-4">
                                        <div className="form-group">
                                            <FormControl className="form-control" variant="outlined" required>
                                                <InputLabel id="widget-sensor">Time range to show</InputLabel>
                                                <Select
                                                    labelId="widget-range"
                                                    value={widget.range}
                                                    onChange={(e) => selectWidgetRange(e)}
                                                >
                                                    <MenuItem value={'now-15m'}>Last 15 minutes</MenuItem>
                                                    <MenuItem value={'now-30m'}>Last 30 minutes</MenuItem>
                                                    <MenuItem value={'now-1h'}>Last 1 hour</MenuItem>
                                                    <MenuItem value={'now-2h'}>Last 2 hours</MenuItem>
                                                    <MenuItem value={'now-3h'}>Last 3 hours</MenuItem>
                                                    <MenuItem value={'now-4h'}>Last 4 hours</MenuItem>
                                                    <MenuItem value={'now-5h'}>Last 5 hours</MenuItem>
                                                    <MenuItem value={'now-6h'}>Last 6 hours</MenuItem>
                                                    <MenuItem value={'now-7h'}>Last 7 hours</MenuItem>
                                                    <MenuItem value={'now-8h'}>Last 8 hours</MenuItem>
                                                    <MenuItem value={'now-9h'}>Last 9 hours</MenuItem>
                                                    <MenuItem value={'now-10h'}>Last 10 hours</MenuItem>
                                                    <MenuItem value={'now-11h'}>Last 11 hours</MenuItem>
                                                    <MenuItem value={'now-12h'}>Last 12 hours</MenuItem>
                                                    <MenuItem value={'now-1d'}>Last 1 day</MenuItem>
                                                    <MenuItem value={'now-2d'}>Last 2 days</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    : null }

                                    { ["list"].includes(widget.type) ?
                                <div className="col-12 mb-4">
                                    <div className="form-group">
                                        <FormControl className="form-control" variant="outlined">
                                                <InputLabel id="widget-item">{t('item.title')}</InputLabel>
                                                <Select
                                                    labelId="widget-item"
                                                    value={widget.itemId}
                                                    onChange={(e) => selectItem(e)}
                                                >
                                                    { items.map(item => (
                                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                    )) }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div> : null }
                                
                                { remoteDeviceData && widget.type !== '' ? 
                                    <div className="col-12 mb-4">
                                        <p>Select the value from the sensor to display in your {widget.type} widget.</p>
                                        <div className="form-group">
                                            <FormControl className="form-control" variant="outlined">
                                                <InputLabel id="widget-sensorkey">Sensor Value</InputLabel>
                                                    <Select
                                                        labelId="widget-sensorkey"
                                                        value={widget.sensorKey}
                                                        onChange={(e) => setWidget({ ...widget, sensorKey: e.target.value })}
                                                    >
                                                        { Object.keys(remoteDeviceData.profilePacket).map((profileKey, key) => (
                                                            <MenuItem key={key} value={profileKey}>{profileKey} [{remoteDeviceData.profilePacket[profileKey]}]</MenuItem>
                                                        )) }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                : null}
                                
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a className="btn" onClick={() => setShowModel(false)}>
                        Close
                    </a>
                    <button type="submit" className="btn btn-primary">
                        <FontAwesomeIcon icon={['fad', 'save']} /> Save Widget
                    </button>
                </Modal.Footer>
            </form>
        </Modal>

    )
    return (
        <div>
            <span onClick={() => openModel()}><FontAwesomeIcon icon={['fad', 'plus']} /> Add a new Widget</span>
            {createModel}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(WidgetCreateModel)