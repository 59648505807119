import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Hub } from "@aws-amplify/core"
import $ from 'jquery'

import { GetAllReports } from '../../Services/Echolo/Reports'
import { SavedResults } from '../../Controllers'

import ReportsTable from '../../Components/Reports/ReportsTable'
import LoadingTable from "../../Helpers/Components/LoadingTable"
import SavedReportModel from '../../Components/Reports/SavedReportModel'

import ReportsMenu from '../../Components/Reports/ReportsMenu'

class ReportsIndex extends Component {
    state = {
        reports: [],
        loading: true,
        saveReportModelStatus: false,
        report: null,
        selectedRow: null
    }

    fetchReports = async () => {
        const { currentCompany } = this.props
        try {
            let savedResultsIds = await SavedResults.SavedResultsAll(currentCompany)
            savedResultsIds = savedResultsIds.map(r => r.resultId)
            const reportsData = await GetAllReports(currentCompany)
            let cleanReports = []
            for (const report of reportsData) {
                if (!savedResultsIds.includes(report._id)) {
                    cleanReports = [...cleanReports, report]
                }
            }
            this.setState({
                reports: cleanReports,
                loading: false
            })
        } catch (error) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue loading your Reports, please try again shortly.'
                }
            })
        }
    }

    componentDidMount() {
       this.fetchReports()
    }

    saveReportHandler = async (report) => {
        console.log('saving', report)
        const { currentCompany } = this.props
        const savedResults = await SavedResults.Create(currentCompany, report)
        if (savedResults) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success',
                    message: 'Saved!'
                }
            })
            this.saveReportModelState(false)
            $('#'+this.state.selectedRow).slideUp(1000)
            this.setState({
                selectedRow: null
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue saving your report results, try again.'
                }
            })
        }
    }

    openReportModelHandler = (report) => {
        this.setState({
            selectedRow: 'result_row_' + report._id
        })
        this.setReportForSave(report)
        this.saveReportModelState(true)
    }

    setReportForSave = (report) => {
        this.setState({
            report: report
        })
    }

    saveReportModelState = (status) => {
        this.setState({
            saveReportModelStatus: status
        })
        if (!status) {
            this.setState({
                report: null
            })
        }
    }

    render() {
        const { reports, loading, saveReportModelStatus, report } = this.state
        return (
            <div className={'row'}>
                <div className={'col-2 sidebarSubMenu'}>
                    <ReportsMenu location={'index'} />
                </div>
                <div className={'col-10 ml-auto pl-5'}>
                    <div className="row InnerContainer">
                        <div className={'col-12'}>
                            <h2><FontAwesomeIcon icon="fa-duotone fa-cloud-arrow-up" /> Incoming Reports</h2>
                        </div>

                        <div className={'col-12'}>
                            <section>
                                {loading ? <LoadingTable /> :
                                    <ReportsTable reportData={reports} saveReport={this.openReportModelHandler} />
                                }
                            </section>
                        </div>

                    </div>
                </div>
                { report ? <SavedReportModel showModel={saveReportModelStatus} setShowModel={this.saveReportModelState} ruleId={report.fields.ruleResults.ruleId} resultData={report} submitHandler={this.saveReportHandler} /> : null }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany,
        currentUser: state.user.currentUser
    }
}
export default connect(mapStateToProps)(withTranslation()(ReportsIndex))