import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Skeleton from '@material-ui/lab/Skeleton'
import $ from 'jquery'

import DashboardSelectModal from '../Dashboard/DashboardSelectModal'

import TagChip from '../Tag/TagChip'
import SensorInfoModel from '../Sensor/SensorInfoModel'

import { SensorIsOnline } from '../../Helpers/SensorHelpers'


const ItemDetailsList = ({ filteredItems, selectedItem, selectItemHandler, showDashboardHandler }) => {
    const [showModel, setShowModel] = useState(false)
    const [selectedSensor, setSelectedSensor] = useState(null)
    const [showDashboardSelectModel, setShowDashboardSelectModel] = useState(false)
    const [selectedItemDashboards, setSelectedItemDashboards] = useState([])

    useEffect(() => {
        if (selectedItem) {
            $('div.ItemDetailsList .ItemDetailsListSensorList').slideUp('fast')
            $('div.ItemDetailsList').removeClass('ItemDetailsListSelectedItem')

            $('#itemFor_' + selectedItem.id).addClass('ItemDetailsListSelectedItem')
            $('#sensorListFor_' + selectedItem.id).slideToggle('fast')
        }
    }, [selectedItem])

    const selectSensor = (sensor) => {
        setSelectedSensor(sensor)
        setShowModel(true)
    }

    const showSensorList = (item) => {
        // Center map over Item
        selectItemHandler(item)
    }

    const selectDashboard = (dashboards) => {
        setSelectedItemDashboards(dashboards)
        setShowDashboardSelectModel(true)
    }

    const selectDashboardhandler = (dashboard) => {
        setShowDashboardSelectModel(false)
        setSelectedItemDashboards([])
        showDashboardHandler(dashboard)
    }

    return (
        <>
            {filteredItems.map(item => (
                <div id={`itemFor_${item.id}`} key={item.id} onClick={() => showSensorList(item)} className="row ItemDetailsList">
                    <div className="ItemDetailsListIcon">
                        <FontAwesomeIcon icon={['fad', 'forklift']} />
                    </div>
                    <div className="col ml-auto ItemDetailsListDetails">
                        <p className="p-0 m-0"><strong>{item.name}</strong></p>
                        <p className="p-0 m-0"><small>{item.type} {item.customId ? `(${item.customId})` : null}</small></p>
                        <p className="p-0 m-0"><small>{item.sn ? `SN: ${item.sn}` : null} {item.year ? `(${item.year})` : null}</small></p>
                        
                        { item.dashboards.length > 0 ? <FontAwesomeIcon onClick={() => selectDashboard(item.dashboards)} className="FakeLink ItemDetailsListItemDashIcon" icon={['fad', 'chart-line']} /> : null }

                        <div className="ItemDetailsListTags">
                            {item.tags.items.map(tag => (
                                <TagChip tag={tag.tag} key={tag.tag.id} />
                            ))}
                        </div>
                    </div>


                    <div id={`sensorListFor_${item.id}`} className="col-12 ItemDetailsListSensorList">
                        
                        {item.sensors.items.map(sensor => (
                            <div key={sensor.id} className="ItemDetailsListSensor row">
                                <div className="">
                                    <strong>{sensor.name}</strong> ({sensor.type} {sensor.sid})
                                </div>
                                {sensor._source ? 
                                    <div className="col-3 ml-auto text-right">
                                        <FontAwesomeIcon className="mr-3" onClick={() => selectSensor(sensor)} icon={['fad', 'info-square']} />
                                        { SensorIsOnline(sensor._source['lastSeenDate']) ? 
                                            <FontAwesomeIcon className="text-success" icon={['fad', 'signal']} /> : <FontAwesomeIcon className="text-muted" icon={['fad', 'signal-slash']} /> }
                                    </div>
                                : null }
                            </div>
                        ))}
                    </div>
                </div>
            ))}

            {filteredItems.length === 0 ? 
            <>
            <div className="row ItemDetailsList">
                <div className="ItemDetailsListIcon">
                    <Skeleton />
                </div>
                <div className="col ml-auto ItemDetailsListDetails">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-3"></div>
                        <div className="col-3"><Skeleton /></div>
                        <div className="col-3"><Skeleton /></div>
                        <div className="col-3"><Skeleton /></div>
                    </div>
                </div>
            </div>
            <div className="row ItemDetailsList">
                <div className="ItemDetailsListIcon">
                    <Skeleton />
                </div>
                <div className="col ml-auto ItemDetailsListDetails">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-3"></div>
                        <div className="col-3"><Skeleton /></div>
                        <div className="col-3"><Skeleton /></div>
                        <div className="col-3"><Skeleton /></div>
                    </div>
                </div>
            </div>
            </>
            : null}

            <SensorInfoModel showModel={showModel} setShowModel={setShowModel} sensor={selectedSensor} />
            <DashboardSelectModal showModel={showDashboardSelectModel} dashboardData={selectedItemDashboards} selectDashboardhandler={selectDashboardhandler} />
        </>
    )
}

export default ItemDetailsList