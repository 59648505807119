import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Hub } from '@aws-amplify/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'

import { GetRule, GetRuleWithProfiles } from '../../Services/Echolo/Rules/Rule'

import RuleEditFormModal from '../../Components/Rule/RuleEditFormModal'
import ActionAddModal from '../../Components/Rule/Action/ActionAddModal'
import ConditionAddModal from '../../Components/Rule/Condition/ConditionAddModal'
import ConditionTable from '../../Components/Rule/Condition/ConditionTable'
import ActionTable from '../../Components/Rule/Action/ActionTable'
import DeviceReportTable from "../../Components/Reports/DeviceReportTable"
import { GetAllReportsByRule } from "../../Services/Echolo/Reports"
import ReportsTable from "../../Components/Reports/ReportsTable"
import { GetProfileById } from "../../Services/Echolo/Profile"
import { ConvertProfileMethodsToJSON } from "../../Helpers/ProfileHelpers"
import LoadingTable from "../../Helpers/Components/LoadingTable"
import IftttModel from '../../Components/Rule/ifttt/iftttModel'


class RuleShow extends Component {
    state = {
        rule: null,
        showEditModel: false,
        showActionModal: false,
        showConditionModal: false,
        showIFTTTModel: false,
        reports: null,
        iftttActive: false
    }

    fetchReports = async () => {
        const { currentCompany } = this.props
        const reportsData = await GetAllReportsByRule(currentCompany, this.props.match.params.id, 3)
        if (reportsData) {
            this.setState({
                reports: reportsData
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue loading Recent Reports.'
                }
            })
        }
    }

    fetchRule = async () => {
        const { currentCompany } = this.props
        let rule = await GetRuleWithProfiles(currentCompany, this.props.match.params.id)
        if (rule) {
            if (rule.actions) {
                let actions = []
                for (const action of rule.actions) {
                    const profileMethods = ConvertProfileMethodsToJSON(action.profile)
                    actions.push({ ...action, profile: profileMethods })
                }
                rule.actions = [ ...actions ]
                this.checkForIFTTTMethod(rule)
            }
            this.setState({
                rule: rule
            })
            return rule
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue loading this Alert.'
                }
            })
        }
    }

    checkForIFTTTMethod = (rule) => {
        const profileMethodsArray = _.flatten(_.map(rule.actions, 'profile.methods'))
        if (_.find(profileMethodsArray, { sendMethod: 'ifttt' })) {
            this.setState({
                iftttActive: true
            })
        }
    }

    componentDidMount = () => {
        this.fetchRule()
        this.fetchReports()
    }

    setShowEditModelHandler = (status) => {
        this.setState({
            showEditModel: status
        })
    }

    setIFTTTModelHandler = (status) => {
        this.setState({
            showIFTTTModel: status
        })
    }

    setShowActionModelHandler = (status) => {
        this.setState({
            showActionModal: status
        })
    }

    UpdatedRuleOptionsHandler = async () => {
        await this.fetchRule()
    }

    setShowConditionModelHandler = (status) => {
        this.setState({
            showConditionModal: status
        })
    }

    editHandler = (data) => {
        console.log(data)
        this.setState({
            rule: data
        })
    }


    render() {
        const { rule, showEditModel, showActionModal, showConditionModal, reports, iftttActive, showIFTTTModel } = this.state
        const { t } = this.props
        return (
            <>
                {rule ?
                <div className="row InnerContainer">
                    <RuleEditFormModal editHandler={this.editHandler} showModel={showEditModel} setShowModel={this.setShowEditModelHandler} ruleData={rule} />
                    <div className={iftttActive ? 'col-3' : 'col-4'}>
                        <section>
                            <h2>{rule.name}</h2>
                            <p><span className="badge badge-dark">{rule.name}</span> will run every <span className="badge badge-primary">{rule.interval}</span> to
                                try and match the conditions. Right now the Rule is currently { rule.active ? <><span className="badge badge-success">ACTIVE</span> and running.</>  : <><span className="badge badge-warning">INACTIVE</span> and not running yet.</> } </p>

                            <table className="table table-sm table-hover">
                                <tbody>
                                    <tr>
                                        <td>Interval</td>
                                        <td>{rule.interval}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>{rule.active ? 'ACTIVE' : 'INACTIVE'}</td>
                                    </tr>
                                    <tr>
                                        <td>Rule ID</td>
                                        <td>{rule.ruleId}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </div>

                    <div className={iftttActive ? 'col-4' : 'col-6'}>
                        <section>
                            <h5>Recent Results</h5>
                            {reports ?
                                <>
                                {reports.length > 0 ? <ReportsTable reportData={reports} /> :
                                    <p>No reports yet, check back later!</p>
                                }
                                </>
                                : null}
                        </section>
                    </div>

                    {iftttActive ?
                        <div className={'col-3'}>
                            <section>
                                <h5>IFTTT Setup</h5>
                                <p className={'mb-0'}>When creating your Applets on <a target={'_blank'} href={'https://ifttt.com/echolo_iot'}>IFTTT</a>, the <a target={'_blank'} href={'https://ifttt.com/echolo_iot'}>Echolo IoT service</a> will ask for the following details.</p>
                                <p className={'text-center'}><a target={'_blank'} href={'https://ifttt.com/echolo_iot'}><img src={'/images/IFTTT-White-EcholoLogo-badge.png'}  alt={'IFTTT'}/></a></p>

                                <button onClick={() => this.setIFTTTModelHandler(true)} className={'btn btn-outline-primary btn-block'}>Show IFTTT Setup</button>
                            </section>
                        </div> : null
                    }

                    <div className="col-2 text-right">
                        <button onClick={() => this.setShowEditModelHandler(true)} className="btn btn-outline-primary btn-sm"><FontAwesomeIcon icon={['fad', 'edit']} /> Update</button>
                    </div>

                    <div className="col-12 mt-1">
                        <section>
                            <div className="row">
                                <div className="col-9">
                                    <h5><FontAwesomeIcon icon="fa-duotone fa-input-numeric" /> Conditions</h5>
                                    <p>Conditions are used to filter out the noise and get to what you are precisely looking for, that needle in a haystack!</p>
                                </div>

                                <div className="col-3 text-right">
                                    <button onClick={() => this.setShowConditionModelHandler(true)} className="mt-4 btn btn-outline-primary btn-sm"><FontAwesomeIcon icon={['fad', 'plus']} /> Add a Condition</button>
                                </div>

                                <div className="col-12">
                                    { rule.conditions ? <ConditionTable currentCompany={this.props.currentCompany} conditionData={rule.conditions} /> : null }
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="col-12 mt-4">
                        <section>
                            <div className="row">
                                <div className="col-9">
                                    <h5><FontAwesomeIcon icon="fa-duotone fa-cloud-arrow-up" /> Actions</h5>
                                    <p>Actions are used to move your newly filtered data around! For example, you might choose to build a report in Google Sheets or maybe send it all to your MySQL database!</p>
                                </div>

                                <div className="col-3 text-right">
                                    <button onClick={() => this.setShowActionModelHandler(true)} className="mt-4 btn btn-outline-primary btn-sm"><FontAwesomeIcon icon={['fad', 'plus']} /> Add an Action</button>
                                </div>

                                <div className="col-12">
                                    { rule.actions ? <ActionTable isUpdatingHandler={this.UpdatedRuleOptionsHandler} currentCompany={this.props.currentCompany} ruleData={rule} actionData={rule.actions} /> : <LoadingTable /> }
                                </div>
                            </div>
                        </section>
                    </div>

                    <ActionAddModal ruleData={rule} isUpdatingHandler={this.UpdatedRuleOptionsHandler} showModel={showActionModal} setShowModel={this.setShowActionModelHandler} />
                    <ConditionAddModal ruleData={rule} isUpdatingHandler={this.UpdatedRuleOptionsHandler} showModel={showConditionModal} setShowModel={this.setShowConditionModelHandler} />
                    <IftttModel showModel={showIFTTTModel} setShowModel={this.setIFTTTModelHandler} rule={rule} currentCompany={this.props.currentCompany} />
                </div> : null }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(withTranslation()(RuleShow))