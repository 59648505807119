import { API, graphqlOperation } from 'aws-amplify'
import _ from 'lodash'

import { createSensor, createSensorTags, deleteSensorTags, updateSensor } from '../graphql/mutations'
import { listSensorTagss } from '../graphql/queries'
import { listSensors, sensorBySid } from './graphql/querys'

import { Tag } from '.'

export const Create = async (company, sensorData) => {

    try {
        let tags = sensorData.tags
        delete sensorData.tags
        
        sensorData.sensorCompanyId = company.id
        const input = sensorData

        let sensor = (await API.graphql(graphqlOperation(createSensor, { input }))).data.createSensor

        tags.forEach(tag => {
            saveTagForSensor(company, tag).then(newTag => {
                saveSensorTag(sensor, newTag)
            })
        })
        
        return sensor
    } catch (e) {
        console.warn("Failed to create a new Sensor", e)
        return false
    }
}

export const Update = async (company, sensorId, updatedData) => {

    try {
        // Remove all Tags for this Sensor.
        await RemoveAllTags(sensorId)

        let tags = updatedData.tags

        updatedData.sensorCompanyId = company.id
        const input = updatedData
        delete input.company
        delete input.item
        delete input.tags
        delete input.createdAt
        delete input.updatedAt

        let updatedSensor = (await API.graphql(graphqlOperation(updateSensor, { input }))).data.updateSensor

        // Now Add all the tags back
        tags.forEach(tag => {
            findOrCreate(company, tag).then(newOrFoundTag => {
                saveSensorTag(updatedSensor, newOrFoundTag)
            })
        })
        updatedSensor.tags = tags
        return updatedSensor
    } catch (e) {
        console.log("Sensor.Update Failed: ", e)
        return false
    }
}

export const Find = async (company, sensorId) => {
    return (await API.graphql(graphqlOperation(sensorBySid, { sid: sensorId } ))).data.sensorBySid.items[0]
}

export const All = async (company) => {
    try {
        return (await API.graphql(graphqlOperation(listSensors))).data.listSensors.items.filter(i => i.company.id === company.id)
    } catch (e) {
        console.warn("Failed to find all Sensors", e)
        return false
    }
}

export const RemoveAllTags = async (sensorId) => {
    try {
        let tagSensors = (await API.graphql(graphqlOperation(listSensorTagss))).data.listSensorTagss.items.filter(st => st.sensor.sid === sensorId)
        // @TODO: HAVE TO LOOP OR find a new way to bulk delete.
        tagSensors.forEach((tagModel) => {
            deleteSensorTag(tagModel)
        })
    } catch (e) {
        console.warn("RemoveAllTags [sensors] Not needed or has failed: ", e)
    }
}

// Private
const deleteSensorTag = async (sensorTagModel) => {
    return await API.graphql(graphqlOperation(deleteSensorTags, { input: { id: sensorTagModel.id } }))
}

const saveTagForSensor = async (company, tag) => {
    let tagData = { name: tag, type: 'sensor' }
    return await Tag.Create(company, tagData)
}

const saveSensorTag = async (newSensor, newTag) => {
    const input = {
        sensorTagsSensorId: newSensor.id,
        sensorTagsTagId: newTag.id
    }
    await API.graphql(graphqlOperation(createSensorTags, { input }))
}

const findOrCreate = async (company, tagName) => {
    let tag = Tag.Find(company, tagName, 'sensor')
    if (_.isEmpty(tag)) return await saveTagForSensor(company, tagName)
    return tag
}