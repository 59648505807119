import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '@material-ui/core/Tooltip'
import { Auth } from 'aws-amplify'

const AppTopMenu = ({ locationPath, currentUser }) => {

    const LogoutHandler = async () => {
        await Auth.signOut()
    }

    return (
        <nav className="navbar flex-nowrap TopNav p-0">
            <Link className="navbar-brand col-1 mr-0 px-3" to="/"><img src="/images/explorer_logo_40x100.png" alt="Explorer" /></Link>
            <div className="ml-auto">
                <div className="IconNav">
                    <ul>
                        <li><Tooltip title="Settings" arrow><Link className="mainMenuItem" to="/settings/profile"><FontAwesomeIcon icon={['fad', 'users-cog']} /></Link></Tooltip></li>
                        <li><Tooltip title="Support" arrow><a className="mainMenuItem" target="_blank" rel="noreferrer" href="https://support.echolo.io"><FontAwesomeIcon icon="fa-duotone fa-life-ring" /></a></Tooltip></li>
                        <li className="dropdown">
                                <Tooltip title="Your Settings" arrow>
                                    <a href="#" className="dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><FontAwesomeIcon icon={['fad', 'user-astronaut']} /> {currentUser.first_name} {currentUser.last_name}</a>
                                </Tooltip>

                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <Link className="dropdown-item" to="/settings/profile"><FontAwesomeIcon icon={['fad', 'user-edit']} /> Profile</Link>
                                    <Link className="dropdown-item" to="/settings/password"><FontAwesomeIcon icon={['fad', 'lock-alt']} /> Change Password</Link>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item text-danger" href="#logout" onClick={() => LogoutHandler()}><FontAwesomeIcon icon={['fad', 'sign-out']} /> Logout</a>
                                </div>
                            
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(AppTopMenu)

