import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SET_CURRENT_USER, SET_CURRENT_COMPANY, SET_REGISTERED_USER, SET_SELECTED_DASHBOARD } from './Store/Actions'
import { Hub } from 'aws-amplify'
import { Switch, Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import AppRoute from './Core/AppRoute'

// Layouts
import MinimalLayout from './Pages/Layouts/MinimalLayout'
import AppLayout from './Pages/Layouts/AppLayout'

// ---- Pages
// Login / Register
import Login from './Pages/Cognito/Login'
import Register from './Pages/Cognito/Register'
import ConfirmRegister from './Pages/Cognito/ConfirmRegister'
import ForgotPassword from './Pages/Cognito/ForgotPassword'
import ResetPassword from './Pages/Cognito/ResetPassword'
// Company
import CompanyWelcome from './Pages/Company/CompanyWelcome'
import CompanySelect from './Pages/Company/CompanySelect'
import CompanyIndex from './Pages/Company/CompanyIndex'
import CompanyNew from './Pages/Company/CompanyNew'
import CompanyEdit from './Pages/Company/CompanyEdit'
// Item
import ItemNew from './Pages/Item/ItemNew'
import ItemEdit from './Pages/Item/ItemEdit'
import ItemIndex from './Pages/Item/ItemIndex'
// Sensor
import SensorNew from './Pages/Sensor/SensorNew'
import SensorIndex from './Pages/Sensor/SensorIndex'
import SensorEdit from './Pages/Sensor/SensorEdit'
// Tags
import TagIndex from './Pages/Tag/TagIndex'
// Settings
import SettingsIndex from './Pages/Settings/SettingsIndex'
// Dashboards
import DashboardIndex from './Pages/Dashboard/DashboardIndex'
import DashboardNew from './Pages/Dashboard/DashboardNew'
// Map
import MapIndex from './Pages/Map/MapIndex'
import GeoFenceIndex from './Pages/Map/GeoFence/GeoFenceIndex'
import GeoFenceShow from './Pages/Map/GeoFence/GeoFenceShow'
// Rule
import RuleIndex from './Pages/Rule/RuleIndex'
import RuleWizard from './Pages/Rule/RuleWizard'
import RuleNew from './Pages/Rule/RuleNew'
import ConditionNew from './Pages/Rule/ConditionNew'
import ActionNew from './Pages/Rule/ActionNew'
import RuleShow from './Pages/Rule/RuleShow'
import { default as RuleShowComp } from './Components/Rule/RuleShow'
// Profile
import ProfileIndex from './Pages/Profile/ProfileIndex'
import ProfileShow from './Pages/Profile/ProfileShow'
// Reports
import ReportsIndex from './Pages/Reports/ReportsIndex'
import ReportsShow from "./Pages/Reports/ReportsShow"
import SavedReports from "./Pages/Reports/SavedReports"
import SavedReportsShow from './Pages/Reports/SavedReportsShow'
// Hubs
import HubIndex from './Pages/Hub/HubIndex'

// Controllers
import { Company, User } from './Controllers'

// Functions
import { UserAuth } from './Functions/Auth'

import Alert from './Helpers/Components/Alert'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fad } from '@fortawesome/pro-duotone-svg-icons'

import Amplify from 'aws-amplify'
import awsExports from './aws-exports'

import './Styles/App.scss'
import UserPassword from "./Pages/User/UserPassword"
import CompanyUsers from "./Pages/Company/CompanyUsers"
import CompanyInvite from "./Pages/Company/CompanyInvite"
import CompanyActivate from "./Components/Company/CompanyActivate"
import ActionShow from "./Components/Rule/Action/ActionShow"
import ConditionShow from "./Components/Rule/Condition/ConditionShow"
import ProfileNew from "./Pages/Profile/ProfileNew"




Amplify.configure(awsExports)

library.add(fad)

class App extends Component {
    state = {
        loggedIn: false,
        notify: {
            isOpen: false,
            type: '',
            message: ''
        },
        apiTokenUpdateRequest: false
    }

    _isMounted = true

    isLoggedIn = () => {
        if (!_.isEmpty(this.props.currentUser)) {
            this.setState({
                loggedIn: true
            })
        } else {
            this.setState({
                loggedIn: false
            })
        }
    }

    signInHandler = async () => {
        const { registerUser } = this.props
        let dbUser = null
        if (!_.isEmpty(registerUser)) {
            // Users first login.
            try {
                dbUser = await User.Create(registerUser)
                dbUser = dbUser.data.createUser
                this.props.setRegisterUser({})
            } catch (e) {
                console.warn("Failed to REGISTER a new user on login:", e)
            }
        } else {
            // Normal Login
            dbUser = await UserAuth.SignIn()
            if (dbUser.status === 'suspended') {
                dbUser = false
            }
        }

        if (dbUser) {
            this.props.setCurrentUser(dbUser)
            this.setState({
                loggedIn: true
            })
        }
    }

    signOutHandler = () => {
        this.props.setCurrentUser(null)
        this.props.setCurrentCompany(null)
        this.props.setSelectedDashboard(null)
        this.setState({
            loggedIn: false
        })
    }

    

    subscribeToData = () => {
        if (!_.isEmpty(this.props.currentCompany) && !_.isEmpty(this.props.currentUser)) {
            Company.Subscribe(this.props.currentCompany).subscribe({
                next: companyData => {
                    const updatedCompany = companyData.value.data.onUpdateCompany
                    if (this.props.currentCompany.id === updatedCompany.id) {
                        console.log("Setting company:", updatedCompany)
                        this.props.setCurrentCompany(updatedCompany)
                        // Set changeLanguage
                        this.props.i18n.changeLanguage(updatedCompany.type)
                        //let userCompanies = this.props.currentUser.companies.items
                        //let updatedCompanies = userCompanies.filter(c => c.company.id !== updatedCompany.id)
                        
                    }
                }
            })
        }

        Hub.listen('User', (data) => {
            switch(data.payload.event) {
                case "update":
                    this.props.setCurrentUser(data.payload.data)
                    break
                default:
                    return
            }
        })

        Hub.listen('Alert', (data) => {
            switch(data.payload.event) {
                case "show":
                    this.setState({
                        notify: {
                            isOpen: true,
                            type: data.payload.data.type,
                            message: data.payload.data.message
                        }
                    })
                    break
                default:
                    return
            }
        })
    }

    componentDidMount = () => {
        const { currentCompany, i18n } = this.props
        this._isMounted = true
        
        this.isLoggedIn()
        this.subscribeToData()

        Hub.listen('auth', this.authHandler)

        if (currentCompany) {
            i18n.changeLanguage(currentCompany.type)
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false
    }

    authHandler = (auth) => {
        switch(auth.payload.event) {
            case "signIn":
                console.log("SIGN IN")
                this.signInHandler()
                break
            case "signOut":
                console.log("SIGN OUT")
                this.signOutHandler()
                break
            case "signUp":
                console.log("REG USER 2: ", auth)
                break
            default:
                return
        }
    }

    setNotify = (notifyData) => {
        this.setState({
            notify: notifyData
        })
    }

    render() {
        const { notify } = this.state
        return (
            <>
                <Switch>
                    <AppRoute component={Login} path="/" exact layout={MinimalLayout}>
                        { !_.isEmpty(this.props.currentUser) ? <Redirect to="/dashboard" /> : null }
                    </AppRoute>

                    <AppRoute component={Login} path="/signin" exact layout={MinimalLayout}></AppRoute>
                    <AppRoute component={ForgotPassword} path="/forgot-password" exact layout={MinimalLayout}></AppRoute>
                    <AppRoute component={Register} path="/register" exact layout={MinimalLayout}></AppRoute>
                    <AppRoute component={ConfirmRegister} path="/register/confirm" exact layout={MinimalLayout}></AppRoute>
                    <AppRoute component={ResetPassword} path="/register/resetpassword" exact layout={MinimalLayout}></AppRoute>

                    {/* Routes for Loggedin Users */}
                    { !_.isEmpty(this.props.currentUser) ?
                        <>
                            { _.isEmpty(this.props.currentUser.companies.items) ? <Redirect to="/welcome" /> : null }
                            { (!_.isEmpty(this.props.currentUser.companies.items) && _.isEmpty(this.props.currentCompany)) ? <Redirect to="/select" /> : null }

                            <AppRoute component={CompanyWelcome} path="/welcome" exact layout={MinimalLayout}></AppRoute>
                            <AppRoute component={CompanySelect} path="/select" exact layout={MinimalLayout}></AppRoute>
            
                            <AppRoute component={DashboardIndex} path="/dashboard" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={DashboardNew} path="/dashboard/new" exact layout={AppLayout}></AppRoute>
                                            
                            <AppRoute component={CompanyIndex} path="/company" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={CompanyNew} path="/company/new" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={CompanyEdit} path="/company/:id/edit" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={CompanyUsers} path="/company/:id/users" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={CompanyInvite} path="/company/:id/invite" exact layout={AppLayout}></AppRoute>

                            <AppRoute component={SensorNew} path="/sensor/new" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={SensorIndex} path="/sensor" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={SensorEdit} path="/sensor/:id/edit" exact layout={AppLayout}></AppRoute>

                            <AppRoute component={ItemIndex} path="/item" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={ItemNew} path="/item/new" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={ItemEdit} path="/item/:id/edit" exact layout={AppLayout}></AppRoute>

                            <AppRoute component={TagIndex} path="/tag" exact layout={AppLayout}></AppRoute>

                            <AppRoute component={MapIndex} path="/map" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={GeoFenceIndex} path="/map/geofence" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={GeoFenceShow} path="/map/geofence/:id" exact layout={AppLayout}></AppRoute>

                            <AppRoute component={HubIndex} path="/hubs" exact layout={AppLayout}></AppRoute>

                            <AppRoute component={ReportsIndex} path="/reports" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={SavedReports} path="/reports/view/saved" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={SavedReportsShow} path="/reports/view/saved/:id" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={ReportsShow} path="/reports/:id" exact layout={AppLayout}></AppRoute>

                            <AppRoute component={RuleIndex} path="/rule" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={RuleWizard} path="/rule/new/wizard" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={RuleShow} path="/rule/:id/view" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={RuleShowComp} path="/rule/all" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={RuleNew} path="/rule/new" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={ConditionNew} path="/rule/condition/new" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={ConditionNew} path="/rule/condition/new/:ruleId" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={ConditionShow} path="/rule/condition" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={ActionNew} path="/rule/action/new/:ruleId" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={ActionNew} path="/rule/action/new" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={ActionShow} path="/rule/action" exact layout={AppLayout}></AppRoute>

                            <AppRoute component={ProfileIndex} path="/settings/profiles" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={ProfileShow} path="/settings/profiles/:id/view" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={ProfileNew} path="/settings/profiles/new" exact layout={AppLayout}></AppRoute>

                            <AppRoute component={UserPassword} path="/settings/password" exact layout={AppLayout}></AppRoute>
                            <AppRoute component={CompanyActivate} path="/settings/company/activate" exact layout={AppLayout}></AppRoute>

                            <AppRoute component={SettingsIndex} path="/settings/profile" exact layout={AppLayout}></AppRoute>
                        </>
                        : <Redirect to="/" />
                    }
                </Switch>
                <Alert notify={notify} setNotify={this.setNotify} />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany,
        registerUser: state.user.registerUser
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentUser: (user) => dispatch({ type: SET_CURRENT_USER, payload: user }),
        setCurrentCompany: (company) => dispatch({ type: SET_CURRENT_COMPANY, payload: company }),
        setRegisterUser: (user) => dispatch({ type: SET_REGISTERED_USER, payload: user }),
        setSelectedDashboard: (dashboard) => dispatch({ type: SET_SELECTED_DASHBOARD, payload: dashboard })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(withTranslation()(App))
