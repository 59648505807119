import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { flatten } from 'flat'

const SensorInfoModel = ({ sensor, showModel, setShowModel }) => {
    let tableRow = []
    if (sensor) {
        let flattenData = flatten(sensor)
        let d = Object.keys(flattenData)
        tableRow = d.map((key, i) => {
            return (
                <tr key={i}>
                    {flattenData[key] ?
                        <>
                            <th scope="row">{key}</th>
                            <td>{flattenData[key]}</td>
                        </>
                        : null}
                </tr>
            )
        })
    }

    return (
        <>
            {sensor ?
            <Modal size={'lg'} show={showModel} onHide={() => setShowModel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{sensor._id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <h5>Sensor: {sensor.nodeId}</h5>
                            <table className="table table-hover SensorSelectListInfoText">
                                <tbody>
                                    {tableRow}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn" onClick={() => setShowModel(false)}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal> : null }
        </>
    )
}

export default SensorInfoModel