import React, { Component } from 'react'
import { connect } from 'react-redux'

import CompanyInviteForm from '../../Components/Company/CompanyInviteForm'
import LeftNav from "../../Components/Settings/LeftNav"
class CompanyInvite extends Component {

    render() {
        const { currentCompany } = this.props
        return (
            <div className="row">
                <LeftNav location={'company-invite'} currentUser={this.props.currentUser} currentCompany={this.props.currentCompany} />

                <div className="col-10 ml-auto pl-5">
                    <div className="row">
                        <div className="col-12 pl-4">
                            <div className="InnerContainer">
                                <div className="row">
                                    <div className="col-12">
                                        <h2>Invite new People</h2>
                                        <p>You can invite new people to join your application by sending them an invite by email or by giving them the key below.</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <section>
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4>Give Access (Option 1):</h4>
                                                    <p>You can provide access to your application by providing a user with the following Key:</p>
                                                </div>

                                                <div className="col-12 pt-2">
                                                    <div className="alert alert-info" role="alert">
                                                        <div className="row">
                                                            <div className="col-1">
                                                                <p className="mb-0 pt-1 text-right"><strong>Key:</strong></p>
                                                            </div>
                                                            <div className="col-11">
                                                                <input type="text" value={currentCompany.appId} onChange={() => null} className="form-control" id="exampleInputEmail1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <h6>Steps:</h6>
                                                    <ol>
                                                        <li>Copy the above key.</li>
                                                        <li>Provide this key to the new person.</li>
                                                        <li>After signing in, have the person paste this key into the 'Join/Activate' AppId box.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <section>
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4>Invite a Person (Option 2):</h4>
                                                </div>

                                                <div className="col-12">
                                                    <CompanyInviteForm />
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(CompanyInvite)