import React from 'react'
import { Link } from 'react-router-dom'


import TagChip from '../Tag/TagChip'

const SensorTable = ({ sensors }) => {

    const listTags = (tags) => {
        return tags.map((tag, key) => {
            return (
                <TagChip key={key} tag={tag.tag} />
            )
        })
    }

    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">SID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Class</th>
                    <th scope="col">Type</th>
                    <th scope="col">Tags</th>
                </tr>
            </thead>
            <tbody>
                {sensors.map((row) => (
                    <tr key={row.sid}>
                        <td><Link to={'/sensor/' + row.sid + '/edit'}>{row.sid}</Link></td>
                        <td>{row.name}</td>
                        <td>{row.class}</td>
                        <td>{row.type}</td>
                        <td>{ listTags(row.tags.items) }</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default SensorTable