import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import * as HubHelper from '../../Helpers/HubHelpers'
import { StDateToTimeStamp } from '../../Helpers/DateHelper'

const HubTable = ({ hubsData, listType, counts }) => {
    const [hubElm, setHubElm] = useState(null)
    useEffect(() => {
        let hubs = null,
            hubElement = null,
            onlineHubs = [],
            offlineHubs = []

        if (hubsData.length > 0) {
            hubsData.forEach(hub => {
                if (HubHelper.status(hub.lastSeenDate)) {
                    onlineHubs.push(hub)
                } else {
                    offlineHubs.push(hub)
                }
            })

            counts(onlineHubs, offlineHubs)

            let loopHubs = onlineHubs
            if (listType === 'offline') {
                loopHubs = offlineHubs
            }

            hubs = loopHubs.map((item, key) => {
                return (
                    <tr key={item.hubId + key}>
                        <th scope="row">{HubHelper.statusIcon(item.lastSeenDate)}</th>
                        <td>{item.hubId}</td>
                        <td>{item.sku}</td>
                        <td>{item.mfgSn}</td>
                        <td>{HubHelper.statusIcon(item.heartBeat || false, 'comms')}</td>
                        <td>{StDateToTimeStamp(item.lastSeenDate)}</td>
                    </tr>
                )
            })
        }

        hubElement = (

            <table className="table table-hover">
                <thead>
                <tr>
                    <th scope="col">Status</th>
                    <th scope="col">Hub ID</th>
                    <th scope="col">SKU</th>
                    <th scope="col">Manufacturer Serial Number</th>
                    <th scope="col">Heartbeat</th>
                    <th scope="col">Last Updated</th>
                </tr>
                </thead>
                <tbody>
                {hubs}
                </tbody>
            </table>
        )


        setHubElm(hubElement)
    }, [counts, hubsData, listType])
    return (
        <>{hubElm}</>
    )
}

export default HubTable
