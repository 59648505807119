import React from 'react'
import { default as BSProgressBar } from 'react-bootstrap/ProgressBar'

const ProgressBar = ({ isOpen, progress }) => {
    return (
        <>
            {isOpen ?
            <div className="progressPopOverBG">
                <BSProgressBar animated now={progress} />
            </div> : null }
        </>
    )
}

export default ProgressBar