import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Input, FormControl, InputLabel, InputAdornment, Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { search } from "ss-search"

import { Item, Dashboards } from '../../Controllers'
import { Device } from '../../Services/Echolo'

import BasicMap from '../../Components/Map/BasicMap'
import DashboardShow from '../../Components/Dashboard/DashboardShow'
import GeoFenceShow from './GeoFence/GeoFenceShow'
import ItemDetailsList from '../../Components/Item/ItemDetailsList'

import ProgressBar from '../../Helpers/Components/ProgressBar'

import { flatDeep, asyncForEach } from '../../Helpers/ArrayHelpers'

// Required props
// dashboard = DashboardId
class MapIndex extends Component {

    state = {
        items: [],
        allItems: [],
        loadingItems: true,
        loadingItemsProgress: 0,
        dashboard: this.props.dashboard,
        searchText: '',
        filteringItems: false,
        filteredItems: [],
        selectedItem: null,
        selectedDashboard: null,
        selectedGeoFenceManage: null
    }

    fetchDeviceData = async (sensor, item) => {
        const { currentCompany } = this.props

        // API request to Echolo
        let device = await Device.Find(currentCompany, sensor)
        
        if (device) {
            // Check to see if we have lat long
            if (device._source.latitude && device._source.longitude && (device._source.latitude !== "0.00") && (device._source.longitude !== "0.00")) {
                item.location = { lat: parseFloat(device._source.latitude), long: parseFloat(device._source.longitude) }


                let currentSensor = item.sensors.items.filter(s => s.sid === sensor)
                let otherSensors = item.sensors.items.filter(s => s.sid !== sensor)

                let updatedCurrentSensor = { ...currentSensor[0], ...device }
                otherSensors.push(updatedCurrentSensor)

                item.sensors.items = otherSensors
                return item
            } else {
                console.log("Item does not have a sensor with a Lat/Long")
            }
        }
    }

    fetchData = async () => {
        const { currentCompany, dashboard } = this.props
        let items = []
        if (dashboard) {

            // Is this a Tag Filter OR Type Filter?
            if (dashboard.itemType) {
                items = await Item.FindByType(currentCompany, dashboard.itemType)
            } else {
                // Find DashboardTags items
                let tagItems = dashboard.tags.items
                let deepSensorsArray = tagItems.map(i => i.tag).map(tag => tag.items).map(i => i.items).map(a => a.map(item => item.item)).map(a => a.map(item => item))
                items = flatDeep(deepSensorsArray, Infinity).filter(i => i.sensors.items.length > 0)
            }
            
        } else {
            // Find Items with sensors only
            items = (await Item.All(currentCompany)).filter(i => i.sensors.items.length > 0)
        }

        let itemsCount = 0
        let totalItemsCount = items.length

        await asyncForEach(items, async (item) => {
            let sensorIds = item.sensors.items.map(s => s.sid)
            let sensorCount = 0
            let totalSensorCount = sensorIds.length

            await asyncForEach(sensorIds, async (sensor) => {
                // Request to API
                let updatedItem = await this.fetchDeviceData(sensor, item)
                sensorCount++
                if (updatedItem) {
                    updatedItem.dashboards = await Dashboards.ItemFindByItemId(item.id)

                    if (sensorCount === totalSensorCount) {
                        itemsCount++
                        let updatedItems = [ ...this.state.items, updatedItem ]
                        this.setState({
                            items: updatedItems,
                            filteredItems: updatedItems,
                            allItems: updatedItems,
                            loadingItemsProgress: itemsCount / totalItemsCount * 100
                        })
                    }
                }
            })
        })
    }

    componentDidMount = () => {
        this.fetchData()
    }

    doneLoading = () => {
        this.setState({
            loadingItems: false
        })
    }

    searchItems = async (e) => {
        const { allItems } = this.state

        const searchText = e.target.value
        this.setState({ searchText: searchText })

        if (searchText.length >= 2) {
            let results = search(allItems, ["name", "sn", "customId", "tags.items", "sensors.items"], searchText)
            this.setState({
                filteredItems: results,
                filteringItems: true
            })
        }

        if (searchText.length === 0) {
            this.clearSearchHandler()
        }
    }

    clearSearchHandler = () => {
        const { allItems } = this.state
        this.setState({
            filteredItems: allItems,
            filteringItems: false,
            searchText: ''
        })
    }

    selectedItemHandler = (item) => {
        this.setState({
            selectedItem: item
        })
    }

    showDashboardHandler = (dashboard) => {
        this.setState({
            selectedDashboard: dashboard
        })
    }

    geoFenceManageHandler = (geoFence) => {
        // This is just to show the GeoFenceShow Page
        this.setState({
            selectedGeoFenceManage: geoFence
        })
    }

    geoFenceManageMapHandler = () => {
        this.setState({
            selectedGeoFenceManage: null
        })
    }


    render() {
        const { selectedItem, filteredItems, loadingItems, loadingItemsProgress, searchText, filteringItems, selectedDashboard, selectedGeoFenceManage } = this.state
        const { t } = this.props
        return (
            <div className="row MapIndex BasicMapContainer">
                <div className="col-3 MapIndexLeftMenu">
                    <div className="row">

                        <div className="col-12 MapIndexItemsSearchBox">
                            <FormControl className="form-control">
                                <InputLabel htmlFor="filter-items-in-list">Search for {t('item.titles')}</InputLabel>
                                <Input
                                    id="filter-items-in-list"
                                    value={searchText}
                                    onChange={(e) => this.searchItems(e)}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <FontAwesomeIcon icon={['fad', 'search']} />
                                        </InputAdornment>
                                    }
                                    />
                            </FormControl>
                            { filteringItems ?
                            <Tooltip title="Clear search results." arrow>
                                <span className="MapIndexSearchClear"><FontAwesomeIcon onClick={() => this.clearSearchHandler()} icon={['fad', 'times-circle']} /></span>
                            </Tooltip> : null }
                        </div>

                        <div className="col-12 MapIndexItemsHeader">
                            <h5 className="pt-1 pb-1 mt-1 mb-1">{t('item.titles')}</h5>
                        </div>
                    </div>

                    <ItemDetailsList showDashboardHandler={this.showDashboardHandler} filteredItems={filteredItems} selectedItem={selectedItem} selectItemHandler={this.selectedItemHandler} />
                </div>

                <ProgressBar isOpen={loadingItems} progress={loadingItemsProgress} />

                <div className="col-9 ml-auto p-0">
                    { selectedDashboard ? 
                        <div className="OnMapDashboardShow">
                            <DashboardShow onMapClose={this.showDashboardHandler} onMap={true} dashboardData={selectedDashboard} />
                        </div>
                    : null }

                    {selectedGeoFenceManage ? 
                        <div className="OnMapGeoFenceShow">
                            <GeoFenceShow geoFenceId={selectedGeoFenceManage.id} onMap={true} onMapHandler={this.geoFenceManageMapHandler} />
                        </div> 
                    : <BasicMap geoFenceManageHandler={this.geoFenceManageHandler} dashboard={this.state.dashboard} selectedItemHandler={this.selectedItemHandler} selectedItem={selectedItem} items={filteredItems} doneLoading={this.doneLoading} />}
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(withTranslation()(MapIndex))