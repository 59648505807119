import React, { useState, useEffect } from "react"
import { TextField } from "@material-ui/core"
import TagForm from "../Tag/TagForm"

const SavedReportForm = ({ result, ruleId, outgoingDataHandler }) => {
    const initResults = {
        name: '',
        tags: [],
        ruleId: ruleId,
        resultId: result._id,
        resultCount: result.fields.ruleResults.results.length
    }

    const [results, setResults] = useState(initResults)

    const tagChangeHandler = (tags) => {
        setResults({ ...results, tags: tags })
    }

    useEffect(() => {
        outgoingDataHandler(results)
    }, [results])


    return (
        <div className={'row'}>
            <div className={'col-12'}>
                <form>
                    <div className={'col-12'}>
                        <p>Saving Report <span className={'badge badge-info'}>{result._id}</span></p>
                        <p>Reported @ <span className={'badge badge-info'}>{result['@timestamp']}</span></p>
                    </div>

                    <div className={'col-12'}>
                        <div className={'row'}>
                            <div className="col-12 mb-4">
                                <div className="form-group">
                                    <TextField required className="form-control" value={results.name} onChange={(e) => setResults({ ...results, name: e.target.value }) } label="Name the Results" variant="outlined" />
                                </div>
                            </div>

                            <div className="col-12 mt-4">
                                <div className="form-group">
                                    <TagForm handler={tagChangeHandler} defaultTags={results.tags} tagType="report" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SavedReportForm
