import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Hub } from '@aws-amplify/core'

import { Dashboards } from '../../Controllers'

import DashboardForm from '../../Components/Dashboard/DashboardForm'

class DashboardNew extends Component {

    createDashboardhandler = async (dashboard) => {
        const { currentUser, currentCompany } = this.props
        let newdash = await Dashboards.Create(currentCompany, currentUser, dashboard)
        if (newdash) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Your new Dashboard has been created!'
                }
            })
            this.props.history.push('/dashboard')
            
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue creating your Dashboard, please try again.'
                }
            })
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <DashboardForm handler={this.createDashboardhandler} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(withTranslation()(DashboardNew))