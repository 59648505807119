import React, { useEffect, useState } from "react"
import ReportsMenu from "../../Components/Reports/ReportsMenu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { SavedResults } from '../../Controllers'
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import LoadingTable from "../../Helpers/Components/LoadingTable"

import ReportResultsTable from '../../Components/Reports/Results/ReportResultsTable'
import SavedReportModel from '../../Components/Reports/SavedReportModel'

import { GetRule } from '../../Services/Echolo/Rules/Rule'
import { Hub } from "@aws-amplify/core"

const SavedReports = ({ currentCompany }) => {
    const [editModelIsOpen, setEditModelIsOpen] = useState(false)
    const [resultUpdate, setResultUpdate] = useState(null)
    const [resultUpdateLoading, setResultUpdateLoading] = useState(false)
    const [savedResults, setSavedResults] = useState(null)

    const fetchSavedResults = async () => {
        const resultsData = await SavedResults.SavedResultsAll(currentCompany)
        if (resultsData) {
            let correctedResults = []
            for (const result of resultsData) {
                const rule = await GetRule(currentCompany, result.ruleId)
                correctedResults.push({ ...result, rule: rule })
            }
            setSavedResults(correctedResults)
        } else {

        }
    }

    useEffect(() => {
        fetchSavedResults()
    }, [])

    const editModelShow = (status, resultData) => {
        setResultUpdate(resultData)
        setEditModelIsOpen(status)
    }

    const saveUpdatedResults = async (resultsUpdates) => {
        setResultUpdateLoading(true)
        const result = await SavedResults.UpdateSavedReport(currentCompany, resultsUpdates)
        if (result) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success',
                    message: 'Saved!'
                }
            })
            const rule = await GetRule(currentCompany, result.ruleId)
            const currentResults = savedResults.filter(r => r.id !== result.id)
            setSavedResults([...currentResults, { ...result, rule: rule }])
            setEditModelIsOpen(false)
            setResultUpdate(null)
            await fetchSavedResults()
            setResultUpdateLoading(false)
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue saving your report results, try again.'
                }
            })
            setResultUpdateLoading(false)
        }
    }

    return (
        <div className={'row'}>
            <div className={'col-2 sidebarSubMenu'}>
                <ReportsMenu location={'savedReports'} />
            </div>
            <div className={'col-10 ml-auto pl-5'}>
                <div className="row InnerContainer">
                    <div className={'col-12'}>
                        <h2><FontAwesomeIcon icon="fa-duotone fa-folder-bookmark" /> Saved Reports</h2>
                    </div>

                    <div className={'col-12'}>
                        <section>
                            { savedResults ?
                            <>
                                {savedResults.length > 0 ?
                                <ReportResultsTable editModel={editModelShow} savedResultsData={savedResults} />
                                :
                                <p>No Results</p>
                                }
                            </>
                            : <LoadingTable />}
                        </section>
                    </div>

                </div>
            </div>

            { savedResults && resultUpdate ? <SavedReportModel inEdit={true} ruleId={resultUpdate.ruleId} setShowModel={editModelShow} showModel={editModelIsOpen} submitHandler={saveUpdatedResults} resultData={resultUpdate} loading={resultUpdateLoading} /> : null }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany,
        currentUser: state.user.currentUser
    }
}
export default connect(mapStateToProps)(withTranslation()(SavedReports))
