import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Hub } from "@aws-amplify/core"

import { GetReportById } from "../../Services/Echolo/Reports"
import DeviceReportTable from "../../Components/Reports/DeviceReportTable"
import LoadingTable from "../../Helpers/Components/LoadingTable"

class ReportsShow extends Component {
    state = {
        report: null,
        loading: true
    }
    fetchReport = async () => {
        try {
            const { currentCompany } = this.props
            const reportResults = await GetReportById(currentCompany, this.props.match.params.id)
            console.log('reportResults', reportResults[0])
            this.setState({
                report: reportResults[0],
                loading: false
            })
        } catch (error) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue loading your Report, please try again shortly.'
                }
            })
        }
    }

    componentDidMount() {
        this.fetchReport()
    }

    render() {
        const { report } = this.state
        return (
            <div className={'row InnerContainer'}>
                <div className={'col-12'}>
                    <h2>Report: <small></small></h2>
                </div>

                <div className={'col-12'}>
                    {report ? <DeviceReportTable devicesData={report.fields.ruleResults.results} /> : <LoadingTable />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany,
        currentUser: state.user.currentUser
    }
}
export default connect(mapStateToProps)(withTranslation()(ReportsShow))