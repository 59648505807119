import React, { Component } from "react"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import HubTable from "../../Components/Hub/HubTable"
import LeftNav from "../../Components/Settings/LeftNav"
import LoadingTable from "../../Helpers/Components/LoadingTable"

import { GetAllHubs } from '../../Services/Echolo/Hub'
import { Hub } from "@aws-amplify/core"
import Loading from "../../Helpers/Components/Loading"

class HubIndex extends Component {
    state = {
        hubs: [],
        loading: true,
        onlineHubCount: 0,
        offlineHubCount: 0
    }
    fetchHubs = async () => {
        const { currentCompany } = this.props
        const hubsData = await GetAllHubs(currentCompany)
        if (hubsData) {
            console.log('hubs', hubsData)
            this.setState({
                hubs: hubsData,
                loading: false
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue while trying to find Hubs.'
                }
            })
        }
    }

    componentDidMount() {
        this.fetchHubs()
    }

    setCounts = (online, offline) => {
        this.setState({
            onlineHubCount: online.length,
            offlineHubCount: offline.length
        })
    }

    render() {
        const { loading, hubs, onlineHubCount, offlineHubCount } = this.state
        return (
            <div className="row">
                <LeftNav location={'hubs'} currentUser={this.props.currentUser} currentCompany={this.props.currentCompany} />

                <div className="col-10 ml-auto pl-5">
                    <div className="row InnerContainer">
                        <div className="col-6 mb-3">
                            <h2><FontAwesomeIcon icon="fa-duotone fa-router" /> Hubs</h2>
                        </div>
                        <div className="col-6 mb-3 text-right"></div>

                        <div className="col-12">
                            <section>
                                { loading ? <Loading isOpen={true} /> : <h4>Online Hubs <small className={'text-muted'}>({onlineHubCount})</small></h4> }
                                { loading ? <LoadingTable /> : <HubTable hubsData={hubs} counts={this.setCounts} listType={'online'} /> }
                            </section>
                        </div>

                        <div className="col-12">
                            <section>
                                { loading ? <Loading isOpen={true} /> : <h4>Offline Hubs <small className={'text-muted'}>({offlineHubCount})</small></h4> }
                                { loading ? <LoadingTable /> : <HubTable hubsData={hubs} counts={this.setCounts} listType={'offline'} /> }
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(HubIndex)