
export const capitalize = (word) => {
    return word[0].toUpperCase() + word.substring(1).toLowerCase()
}

// Private
export const ItemTextSearch = (tree, value, key = 'id', reverse = false) => {
    const stack = [ tree[0] ]
    while (stack.length) {
        const node = stack[reverse ? 'pop' : 'shift']()
        if (node[key] === value) return node
        node.children && stack.push(...node.children)
    }
    return null
}

export const ConditionOperatorConverter = (operator) => {
    switch(operator) {
        case 'gt':
            return 'Greater Than'
        case 'lt':
            return 'Less Than'
        case 'gte':
            return 'Greater Than or Equal To'
        case 'lte':
            return 'Less Than or Equal To'
        case 'on':
            return 'ON'
        case 'off':
            return 'OFF'
        case 'has':
            return 'Has'
        case 'nothave':
            return 'Dose not have'
        case 'eq':
            return 'Equals'
        case 'geofence_enter':
            return 'Entered'
        case 'geofence_exit':
            return 'Exit'
        case 'geofence_in':
            return 'In'
        case 'geofence_out':
            return 'Out'
        default:
            return '??'
    }
}

export const RuleTypeConverter = (type, textTranslation) => {
    switch(type) {
        case 'tag':
            return 'Tag Rule'
        case 'itemType':
            return (<>{textTranslation} Type Rule</>)
        case 'item':
            return (<>{textTranslation} Rule</>)
        default:
            return '??'
    }
}

export const ActionTypeConverter = (type) => {
    switch(type) {
        case 'notify':
            return 'Notify'
        case 'addtag':
            return 'Add Tag'
        case 'removetag':
            return 'Remove Tag'
        default:
            return '??'
    }
}