import { API, graphqlOperation } from 'aws-amplify'

import { createRule, createRuleTag, createRuleCondition, createRuleAction, deleteRuleTag, deleteRule } from '../graphql/mutations'
import { listRules, getRule } from './graphql/querys'
import { updateRule } from './graphql/mutations'
import { listRuleTags } from '../graphql/queries'

import { Tag } from '.'

export const Create = async (company, rule) => {
    let input = {
        ruleCompanyId: company.id
    }

    let tagNames = rule.tags
    delete rule.tags

    input = { ...input, ...rule }

    try {
        let newRule = (await API.graphql(graphqlOperation(createRule, { input }))).data.createRule
        if (rule.type === 'tag') {
            tagNames.forEach(tagName => {
                addTagToRule(company, newRule, tagName)
            })
        }
        return newRule
    } catch (e) {
        console.warn("Failed to create Rule.", e)
        return false
    }
}

export const CreateRuleCondition = async (rule, condition) => {
    const input = {
        ruleConditionRuleId: rule.id,
        ruleConditionConditionId: condition.id
    }

    try {
        return (await API.graphql(graphqlOperation(createRuleCondition, { input }))).data.createRuleCondition
    } catch (e) {
        console.warn("Failed to create RuleCondition", e)
        return false
    }
}

export const CreateRuleAction = async (rule, action) => {
    const input = {
        ruleActionRuleId: rule.id,
        ruleActionActionId: action.id
    }

    try {
        return (await API.graphql(graphqlOperation(createRuleAction, { input }))).data.createRuleAction
    } catch (e) {
        console.warn("Failed to create RuleAction", e)
        return false
    }
}

export const All = async (company) => {
    try {
        return (await API.graphql(graphqlOperation(listRules))).data.listRules.items.filter(r => r.company.id === company.id)
    } catch (e) {
        console.warn("Failed to get ALL Rules", e)
        return false
    }
}

export const Find = async (company, ruleId) => {
    try {
        let rule = (await API.graphql(graphqlOperation(getRule, { id: ruleId }))).data.getRule
        if (rule.company.id !== company.id) throw new Error("You do not have access to this Rule")
        return rule
    } catch (e) {
        console.warn("Failed to Find Rule", e)
        return false
    }
}

export const Update = async (company, rule, updatedRule) => {
    let tagNames = updatedRule.tags
    delete updatedRule.tags
    const input = { ...updatedRule, id: rule.id }

    try {
        let savedRule = (await API.graphql(graphqlOperation(updateRule, { input }))).data.updateRule
        
        await removeAllTagsFromRule(rule)

        if (updatedRule.type === 'tag') {
            tagNames.forEach(tagName => {
                addTagToRule(company, savedRule, tagName)
            })
        }
        return savedRule

    } catch (e) {
        console.warn("Failed to update the Rule", e)
        return false
    }
}

export const Delete = async (rule) => {
    try {
        return (await API.graphql(graphqlOperation(deleteRule, { input: { id: rule.id } }))).data.deleteRule
    } catch (e) {
        console.warn("Faild to delete the Rule", e)
        return false
    }
}

// Private
const addTagToRule = async (company, rule, tagName) => {
    // Find Tag
    let tag = await Tag.Find(company, tagName, 'item')
    // Save to RuleTag
    const input = {
        ruleTagTagId: tag.id,
        ruleTagRuleId: rule.id
    }

    await API.graphql(graphqlOperation(createRuleTag, { input }))
}

const removeAllTagsFromRule = async (rule) => {
    let RuleTags = (await API.graphql(graphqlOperation(listRuleTags))).data.listRuleTags.items.filter(rt => rt.rule.id === rule.id)
    RuleTags.forEach(rt => {
        removeTagFromRule(rt)
    })
}

const removeTagFromRule = async (RuleTag) => {
    try {
        return await API.graphql(graphqlOperation(deleteRuleTag, { input: { id: RuleTag.id } }))
    } catch (e) {
        console.warn("Failed to delete RuleTag", e)
    }
}