import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DashboardCreateModal from './DashboardCreateModal'

import DashboardEmpty from './DashboardEmpty'
import LoadingTable from '../../Helpers/Components/LoadingTable'

const DashboardSelect = ({ dashboards, selectHandler, showHeader = true, loading }) => {
    const [showModel, setShowModel] = useState(false)

    const select = (dashboard) => {
        selectHandler(dashboard)
    }

    const newDashboardBtn = (<button onClick={() => setShowModel(true)} className="btn btn-block btn-primary"><FontAwesomeIcon icon={['fad', 'plus']} /> Add a new Dashboard</button>)

    return (
        <div className="row InnerContainer">
            { loading ? <LoadingTable /> :
            <>
                {dashboards.length > 0 && !loading ?
                <>
                    {showHeader ?
                        <>
                            <div className="col-6">
                                <h2>Dashboards</h2>
                            </div>
                            <div className="col-6 text-right">
                                <button onClick={() => setShowModel(true)} className="btn btn-primary"><FontAwesomeIcon icon={['fad', 'plus']} /> Add a new Dashboard</button>
                            </div>
                        </>
                    : null }
                    <div className="col-12">
                        <section>

                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Dashboard</th>
                                        <th>Type</th>
                                        <th>Widgets</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { dashboards.map(dashboard => (
                                        <tr key={dashboard.id}>
                                            <td className="FakeLink" onClick={() => select(dashboard)}>{dashboard.name}</td>
                                            <td>{dashboard.type}</td>
                                            <td>{dashboard.widgets.items.length}</td>
                                        </tr>
                                    )) }
                                </tbody>
                            </table>
                                
                        </section>
                    </div>
                </>
                : <DashboardEmpty newDashboardBtn={newDashboardBtn} /> }
            </>
            }
            <DashboardCreateModal showModel={showModel} setShowModel={setShowModel} />
        </div>
    )
}

export default DashboardSelect