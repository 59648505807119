import TagChip from "../Components/Tag/TagChip"

export const BuildDeliveryTags = (methods) => {
    let methodTags = []
    for (const method of methods) {
        methodTags.push(method.sendMethod)
    }
    return methodTags.map(m => (<TagChip key={m} tag={{ name: m }} />))
}

export const ConvertProfileMethodsToJSON = (profile) => {
    const methods = JSON.parse(profile.methods)
    return { ...profile, methods: methods }
}
