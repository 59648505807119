import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Hub } from '@aws-amplify/core'

import { Device } from '../../../Services/Echolo'

import DeleteWidgetModel from './DeleteWidgetModel'
import { StDateToTimeStamp } from '../../../Helpers/DateHelper'
import Loading from '../../../Helpers/Components/Loading'

const Graph = ({ currentCompany, widgetData }) => {
    const initChart = {
        chart: {
            type: 'spline',
            height: "190"
        },
        colors: [
            "#6333FF"
        ],
        title: {
            text: null
        },
        yAxis: {
            title: {
                text: widgetData.sensorKey
            }
        },
        xAxis: {
            categories: []
        },
        series: [
            {
                name: widgetData.sensorKey,
                data: [0]
            }
        ]
    }
    const [chart, setChart] = useState(initChart)
    const [widget] = useState(widgetData)
    const [showModel, setShowModel] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)

    const fetchData = async () => {
        let search = await Device.SearchProfiles(currentCompany, widgetData.sensor.sid, widgetData)
        console.log(search)
        if (search) {
            let chartValues = search.map(reading => [StDateToTimeStamp(reading['@timestamp']), reading.fields.profileData[widget.sensorKey]])
            setChart({
                chart: {
                    type: 'area',
                    zoomType: 'x',
                    panning: true,
                    panKey: 'shift',
                    height: "190"
                },
                colors: [
                    "#6333FF"
                ],
                title: {
                    text: null
                },
                yAxis: {
                    title: {
                        text: widgetData.sensorKey
                    }
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        formatter: function() {
                            return Highcharts.dateFormat('%b/%e/%Y', '{value}')
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                series: [
                    {
                        type: 'area',
                        name: widgetData.sensorKey,
                        data: chartValues.reverse()
                    }
                ]
            })

            setIsLoading(false)
        } else {
            // search has failed and we can not return data.
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry! There seems to be an issue communicating to our servers, Please try reloading the page.'
                }
            })

            let errMsg = (
                <p><FontAwesomeIcon icon={['fad', 'info']} /> Sorry! There seems to be an issue communicating to your servers, or data is not available for this timeframe. If you continue to have this issue, please contact Echolo Support.</p>
            )

            setError(errMsg)
        }
    }

    const openDeleteModel = () => {
        setShowModel(true)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="row m-1 WidgetGraph">
            <div className="col-12">
                <div className="row WidgetBox rounded">
                    <div className="col-12 WidgetTextHeader">
                        <h4>{widget.name}</h4>
                        <p className="WidgetTextSubHeader">{widget.sensorKey} from {widget.sensor.sid}</p>
                    </div>
                    <div className="col-12 text-center">
                        {error ? 
                        <>
                            {error}
                        </> : 
                        <>
                            { isLoading && !error ? <Loading isOpen={isLoading} /> : <HighchartsReact highcharts={Highcharts} options={chart} /> }
                        </>
                        }
                    </div>
                    <p><FontAwesomeIcon className="FakeLink text-danger WidgetDeleteIcon" onClick={() => openDeleteModel()} icon={['fad', 'trash']} /></p>
                    <DeleteWidgetModel widgetData={widget} showModel={showModel} setShowModel={setShowModel} />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(Graph)