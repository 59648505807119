// CUSTOM QUERYS
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        companies {
          items {
            id
            level
            createdAt
            updatedAt
            company {
                id
                name
                appId
                appSecret
                token
                type
            }
          }
          nextToken
          
        }
        createdAt
        updatedAt
      }
      nextToken
      
    }
  }
`

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      phone_number
      notify_email
      notify_sms
      first_name
      last_name
      cognito_id
      status
      createdAt
      updatedAt
      companies {
        items {
          id
          level
          company {
              id
              name
              appId
            }
          createdAt
          updatedAt
        }
        nextToken
        
      }
      createdAt
      updatedAt
    }
  }
`
export const getCompanyTags = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt

          items {
            items {
              id
            }
          }

          sensors {
            items {
              id
            }
          }
          
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        sn
        customId
        year
        sensors {
          items {
            id
            name
            sid
          }
        }
        company {
          id
        }
        tags {
          items {
            id
            tag {
              id
              name
              type
            }
          }
          nextToken
          
        }
        createdAt
        updatedAt
      }
      nextToken
      
    }
  }
`
export const listSensors = /* GraphQL */ `
  query ListSensors(
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sid
        class
        type
        sku
        name
        company {
          id
        }
        item {
          id
          name
          type
          sn
          customId
          year
          createdAt
          updatedAt
        }
        tags {
          items {
            id
            tag {
              id
              name
              type
            }
          }
          nextToken
          
        }
        createdAt
        updatedAt
      }
      nextToken
      
    }
  }
`
export const sensorBySid = /* GraphQL */ `
  query SensorBySid(
    $sid: String
    $sortDirection: ModelSortDirection
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sensorBySid(
      sid: $sid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sid
        class
        type
        sku
        name
        company {
          id
          name
        }
        item {
          id
          name
          type
          sn
          customId
          year
          createdAt
          updatedAt
        }
        tags {
          items {
            id
            tag {
              id
              name
              type
            }
          }
          nextToken
          
        }
        createdAt
        updatedAt
      }
      nextToken
      
    }
  }
`
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      name
      type
      sn
      customId
      year
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      sensors {
        items {
          id
          sid
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          tag {
            id
            name
            type
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`

export const listUserCompaniess = /* GraphQL */ `
  query ListUserCompaniess(
    $filter: ModelUserCompaniesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserCompaniess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        company {
          id
          name
          appId
          type
          createdAt
          updatedAt
        }
        level
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listDashboards = /* GraphQL */ `
  query ListDashboards(
    $filter: ModelDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        itemType
        geofences {
          items {
            id
            name
            paths
            center
          }
        }
        item {
          id
          name
        }

        company {
          id
          name
          appId
          appSecret
          token
          users {
            nextToken
          }
          items {
            nextToken
          }
          sensors {
            nextToken
          }
          tags {
            nextToken
          }
          dashboards {
            nextToken
          }
          type
          createdAt
          updatedAt
        }

        user {
          id
          email
          first_name
          last_name
          cognito_id
          companies {
            nextToken
          }
          dashboards {
            nextToken
          }
          createdAt
          updatedAt
        }

        tags {
          items {
            id
            tag {
              id
              name
              items {
                items {
                  id
                  item {
                    id
                    name
                    type
                    sn
                    customId
                    year
                    tags {
                      items {
                        tag {
                          id
                          name
                          type
                        }
                      }
                    }
                    sensors {
                      items {
                        id
                        sid
                        name
                        type
                        class
                      }
                    }
                  }
                }
              }
            }
          }
        }

        widgets {
          items {
            id
            name
            type
            sensor {
              id
              sid
              name
            }
            item {
              id
              name
              type
              sn
              customId
              year
              sensors {

                items {
                  id
                  sid
                  name
                }
              }
            }
            sensorKey
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getDashboard = /* GraphQL */ `
  query GetDashboard($id: ID!) {
    getDashboard(id: $id) {
      id
      name
      type
      itemType
      geofences {
        items {
          id
          name
          paths
          center
        }
      }
      item {
        id
        name
      }

      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }

      user {
        id
        email
        first_name
        last_name
        cognito_id
        companies {
          nextToken
        }
        dashboards {
          nextToken
        }
        createdAt
        updatedAt
      }

      tags {
        items {
          id
          tag {
            id
            name
            items {
              items {
                id
                item {
                  id
                  name
                  type
                  sn
                  customId
                  year
                  tags {
                    items {
                      tag {
                        id
                        name
                        type
                      }
                    }
                  }
                  sensors {
                    items {
                      id
                      sid
                      name
                      type
                      class
                    }
                  }
                }
              }
            }
          }
        }
      }

      widgets {
        items {
          id
          name
          type
          sensor {
            id
            sid
            name
          }
          item {
            id
            name
            type
            sn
            customId
            year
            sensors {

              items {
                id
                sid
                name
              }
            }
          }
          sensorKey
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const searchItems = /* GraphQL */ `
  query SearchItems(
    $filter: SearchableItemFilterInput
    $sort: SearchableItemSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchItems(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        sn
        customId
        type
        year
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        sensors {
          items {
            id
            sid
            class
            type
            sku
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            tag {
              id
              name
              type
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const itemByType = /* GraphQL */ `
  query ItemByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        sn
        customId
        type
        year
        company {
          id
        }
        sensors {
          items {
            id
            sid
            class
            type
            sku
            name
            createdAt
            updatedAt
          }
        }
        tags {
          items {
            id
            tag {
              id
              name
            }
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listRules = /* GraphQL */ `
  query ListRules(
    $filter: ModelRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        name
        type
        tags {
          nextToken
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        itemType
        conditions {
          items {
            id
            condition {
              id
              operator
              metric
              value
              geofence {
                id
                name
                paths
                center
              }
            }
          }
          nextToken
        }
        actions {
          items {
            id
            action {
              id
              name
              type
              tag {
                id
                name
                type
              }
              users {
                items {
                  user {
                    id
                    first_name
                    last_name
                  }
                }
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getRule = /* GraphQL */ `
  query GetRule($id: ID!) {
    getRule(id: $id) {
      id
      company {
        id
        name
        createdAt
        updatedAt
      }
      name
      type
      tags {
        items {
          id
          tag {
            id
            name
            type
            items {
              items {
                id
                item {
                  id
                  name
                  customId
                  sn
                  type
                  year
                }
              }
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      item {
        id
        name
        sn
        customId
        type
        year
        sensors {
          items {
            id
            sid
            name
            sn
            customId
            class
            type
            sku
            tags {
              items {
                tag {
                  id
                  name
                }
              }
            }
          }
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      itemType
      conditions {
        items {
          id
          condition {
            id
            operator
            metric
            value
            geofence {
              id
              name
              paths
              center
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          action {
            id
            name
            type
            tag {
              id
              name
              type
            }
            users {
              items {
                user {
                  id
                  first_name
                  last_name
                }
              }
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listConditions = /* GraphQL */ `
  query ListConditions(
    $filter: ModelConditionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConditions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        operator
        metric
        value
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        geofence {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        rules {
          items {
            id
            rule {
              id
              name
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          items {
            id
            rule {
              id
              name
            }
          }
          nextToken
        }
        users {
          items {
            id
            user {
              id
              first_name
              last_name
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listSavedResultss = /* GraphQL */ `
  query ListSavedResultss(
    $filter: ModelSavedResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSavedResultss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ruleId
        resultId
        resultCount
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          items {
            id
            tag {
              id
              name
              type
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getSavedResults = /* GraphQL */ `
  query GetSavedResults($id: ID!) {
    getSavedResults(id: $id) {
      id
      ruleId
      resultId
      resultCount
      name
      createdAt
      updatedAt
      company {
        id
      }
      tags {
        items {
          id
          tag {
            id
            name
            type
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;