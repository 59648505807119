import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import { Link } from 'react-router-dom'
import $ from 'jquery'

import { Action } from '../../../Controllers'

import { ActionTypeConverter } from '../../../Helpers/TextHelpers'
import LoadingTable from '../../../Helpers/Components/LoadingTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LeftNav from "../../Settings/LeftNav"
const ActionShow = ({ currentCompany, currentUser }) => {
    const [actions, setActions] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchActions = async () => {
        let results = await Action.All(currentCompany)
        if (results) {
            setActions(results)
            setLoading(false)
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue loading your Actions'
                }
            })
        }
    }

    const deleteAction = async (action) => {
        let deleated = await Action.Delete(action)
        if (deleated) {
            $('#action_' + action.id).hide('fast')
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Deleated.'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue with deleting this Action.'
                }
            })
        }
    }

    useEffect(() => {
        fetchActions()
    }, [])

    return (
        <div className="row">
            <LeftNav location={'actions'} currentUser={currentUser} currentCompany={currentCompany} />

            <div className="col-10 ml-auto pl-5">
                <div className="row">
                    <div className="col-12 pl-4">
                        <div className="row InnerContainer">
                            <div className="col-6">
                                <h2><FontAwesomeIcon icon={['fad', 'paper-plane']} /> Actions</h2>
                            </div>

                            <div className="col-6 text-right">
                                <Link to="/rule/action/new" className="btn btn-secondary"><FontAwesomeIcon icon={['fad', 'paper-plane']} /> New Action</Link>
                            </div>

                            <div className="col-12">
                                <section>
                                    {loading ? <LoadingTable /> :
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Action Type</th>
                                                <th scope="col">Result</th>
                                                <th>Delete</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {actions.map(action => (
                                                <tr id={`action_${action.id}`} key={action.id}>
                                                    <td>{action.name}</td>
                                                    <td>{ActionTypeConverter(action.type)}</td>
                                                    {action.type === 'notify' ?
                                                        <td>
                                                            {action.users.items.map(u => (
                                                                <span key={u.user.id} className="badge badge-secondary ChipTag">{`${u.user.first_name} ${u.user.last_name}`}</span>
                                                            ))}
                                                        </td>
                                                        : null }

                                                    {action.type === 'addtag' || action.type === 'removetag' ?
                                                        <td>
                                                            <span key={action.tag.id} className="badge badge-secondary ChipTag">{`${action.tag.name}`}</span>
                                                        </td>
                                                        : null }
                                                    <td>{action.rules.items.length > 0 ? <span className="badge badge-warning">In Use</span> : <button onClick={() => deleteAction(action)} className="btn btn-sm btn-danger"><FontAwesomeIcon icon={['fad', 'trash']} /> DELETE</button>}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>}

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-warning" role="alert">
                                                <strong>In Use:</strong> Actions that are <span className="badge badge-warning">In Use</span> can not be deleted until they are removed from` all Alerts that are using this Action.
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany,
        currentUser: state.user.currentUser
    }
}
export default connect(mapStateToProps)(ActionShow)