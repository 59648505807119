import React, { Component } from "react"
import { connect } from 'react-redux'
import { withTranslation } from "react-i18next"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import LoadingTable from "../../Helpers/Components/LoadingTable"
import ProfileTable from '../../Components/Profile/ProfileTable'

import { CreateProfile } from '../../Services/Echolo/Profile'

import { GetAllProfiles, SplitServiceTypes } from "../../Services/Echolo/Profile"
import LeftNav from "../../Components/Settings/LeftNav"
import NewProfileModel from '../../Components/Profile/NewProfileModel'
import { Hub } from "@aws-amplify/core"


class ProfileIndex extends Component {
    state = {
        profiles: [],
        loading: true,
        showNewProfileModel: false
    }

    fetchProfiles = async () => {
        const { currentCompany } = this.props
        const profilesData = await GetAllProfiles(currentCompany)
        this.setState({
            profiles: SplitServiceTypes(profilesData),
            loading: false
        })
    }
    componentDidMount() {
        this.fetchProfiles()
    }

    setShowEditModelHandler = (status) => {
        this.setState({
            showNewProfileModel: status
        })
    }

    saveProfile = async (profile) => {
        const profileData = await CreateProfile(this.props.currentCompany, profile)
        if (profileData) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success',
                    message: 'Profile has been saved!'
                }
            })
            await this.fetchProfiles()
            this.setShowEditModelHandler(false)
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue creating your Profile.'
                }
            })
        }
    }

    render() {
        const { profiles, loading, showNewProfileModel } = this.state
        return (
            <div className="row">
                <LeftNav location={'profiles-index'} currentUser={this.props.currentUser} currentCompany={this.props.currentCompany} />

                <div className="col-10 ml-auto pl-5">
                    <div className="row">
                        <div className="col-12 pl-4">
                            <div className="row InnerContainer">
                                <div className="col-6">
                                    <h2><FontAwesomeIcon icon={['fad', 'binary']} /> Profiles</h2>
                                </div>

                                <div className="col-6 mb-3 text-right">

                                </div>

                                <div className="col-12 mt-1">
                                    { loading ? <section><LoadingTable /></section> :
                                        <section>
                                            <div className={'row'}>
                                                <div className={'col-8'}>
                                                    <h4>Device Profiles</h4>
                                                    <p>Device Profiles, also known as Device Integration, allow you to use a specific type of device. All Device profiles are sent to Echolo first. You may add a Rule Profile below to deliver Device data to your systems.</p>
                                                </div>
                                                <div className={'col-4'}>button</div>
                                                <div className={'col-12'}>
                                                    <ProfileTable profiles={profiles.auto_delivery} />
                                                </div>
                                            </div>
                                        </section>
                                    }
                                </div>

                                <div className="col-12 mt-1">
                                    { loading ? <section><LoadingTable /></section> :
                                        <section>
                                            <div className={'row'}>
                                                <div className={'col-8'}>
                                                    <h4>Rule Delivery Profiles</h4>
                                                    <p>Rule Profiles allow you to specify where you want to send your results after a rule has run and met your conditions. Connect to services like IFTTT, MySQL, Redlist, and more.</p>
                                                </div>
                                                <div className={'col-4 text-right'}><button className={'btn btn-outline-primary btn-sm'} onClick={(e) => this.setShowEditModelHandler(true)}><FontAwesomeIcon icon="fa-duotone fa-plus" /> New Profile</button></div>
                                                <div className={'col-12'}>
                                                    <ProfileTable profiles={profiles.rule_delivery} />
                                                </div>
                                            </div>
                                        </section>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NewProfileModel submitHander={this.saveProfile} setShowModel={this.setShowEditModelHandler} showModel={showNewProfileModel} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany,
        currentUser: state.user.currentUser
    }
}
export default connect(mapStateToProps)(withTranslation()(ProfileIndex))
