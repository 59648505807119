import React, { useState } from "react"
import Loading from "../../Helpers/Components/Loading"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import $ from 'jquery'
import DeviceExtraDataTable from "./DeviceExtraDataTable"

const DeviceReportTable = ({ devicesData }) => {
    const [devices, setDevices] = useState(devicesData)

    const showExtraData = (e, id) => {
        $("#" + id + "_data").slideToggle(1000)
        //$("#"+ this.state.tableStyleIdBase + id).toggleClass('TelemetryListTitleSelected')

        //$("#icon_right_" + this.state.tableStyleIdBase + id).toggle()
        //$("#icon_down_" + this.state.tableStyleIdBase + id).toggle()
    }

    return (
        <table className="table table-hover">
            <thead>
            <tr>
                <th scope="col">Sensor ID</th>
                <th scope="col">Seen at Hub ID</th>
                <th scope="col">Profile Data</th>
                <th scope="col">Distance from Hub</th>
                <th scope="col">Last Seen</th>
            </tr>
            </thead>
            <tbody>
            {!devices ? <Loading isOpen={true} /> :
                <>
                    {
                        devices.map((row) => (
                            <>
                                <tr onClick={(e) => showExtraData(e, row._id)} key={Math.random() + row._id}>
                                    <td><FontAwesomeIcon icon="fa-duotone fa-microchip" /> {row._id}</td>
                                    <td>{ row._source.seenAt }</td>
                                    <td>{row._source.profileData ? 'Profiled' : 'Device has no Profile'}</td>
                                    <td>{ row._source.measuredDistance }</td>
                                    <td>{ row._source.lastSeenDate }</td>
                                </tr>
                                <tr key={ Math.random() + row._id}>
                                    <td colSpan={5}>
                                        <div id={row._id + '_data'} className={'hiddenTR row'}>
                                            <div className={'col-12'}>
                                                <table className="table table-hover table-sm">
                                                    <tbody>
                                                        <DeviceExtraDataTable rowData={row._source} />
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        ))
                    }
                </>
            }
            </tbody>
        </table>
    )
}

export default DeviceReportTable
