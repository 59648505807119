const SensorTypes = {
    "ble": [
        "OEM BT20v",
        "OEM BT21i",
        "OEM BT22m",
        "OEM BT25wi",
        "OEM BT27i",
        "OEM BT50av",
        "OEM BT51t",
        "OEM BT52rs",
        "OEM BT53ts",
        "OEM BT54af",
        "OEM BT2tt",
        "OEM BT5v",
        "OEM BT5vc",
        "OEM BT7v",
        "OEM BT8v",
        "OEM BT9ts",
        "OEM BT60ni",
        "OEM BT61in",
        "Echolo S1",
        "Echolo K21",
        "Echolo C7",
        "DATLOG-1"
    ],
    "lora": [
        "Echolo AN101D",
        "Echolo AN301",
        "Echolo SN100",
        "Echolo AN122",
        "Echolo EM500",
        "Echolo AM100",
        "Echolo 206P",
        "Echolo AN103",
        "Echolo UC11-T1"
    ],
    "4g": [
        "Echolo GPS 100",
        "Echolo GPS 200",
        "Echolo GPS 300",
        "Echolo Pulsar 101",
        "Echolo Pulsar 201"
    ]
}

export default SensorTypes