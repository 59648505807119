/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      name
      appId
      appSecret
      token
      type
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      savedResults {
        items {
          id
          ruleId
          resultId
          resultCount
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      name
      appId
      appSecret
      token
      type
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      savedResults {
        items {
          id
          ruleId
          resultId
          resultCount
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      name
      appId
      appSecret
      token
      type
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      savedResults {
        items {
          id
          ruleId
          resultId
          resultCount
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateDashboard = /* GraphQL */ `
  subscription OnCreateDashboard {
    onCreateDashboard {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      geofences {
        items {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        nextToken
      }
      widgets {
        items {
          id
          name
          type
          sensorKey
          range
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateDashboard = /* GraphQL */ `
  subscription OnUpdateDashboard {
    onUpdateDashboard {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      geofences {
        items {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        nextToken
      }
      widgets {
        items {
          id
          name
          type
          sensorKey
          range
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteDashboard = /* GraphQL */ `
  subscription OnDeleteDashboard {
    onDeleteDashboard {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      geofences {
        items {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        nextToken
      }
      widgets {
        items {
          id
          name
          type
          sensorKey
          range
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
    }
  }
`;
export const onCreateDashboardTag = /* GraphQL */ `
  subscription OnCreateDashboardTag {
    onCreateDashboardTag {
      id
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateDashboardTag = /* GraphQL */ `
  subscription OnUpdateDashboardTag {
    onUpdateDashboardTag {
      id
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteDashboardTag = /* GraphQL */ `
  subscription OnDeleteDashboardTag {
    onDeleteDashboardTag {
      id
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const onCreateSavedResults = /* GraphQL */ `
  subscription OnCreateSavedResults {
    onCreateSavedResults {
      id
      ruleId
      resultId
      resultCount
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateSavedResults = /* GraphQL */ `
  subscription OnUpdateSavedResults {
    onUpdateSavedResults {
      id
      ruleId
      resultId
      resultCount
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteSavedResults = /* GraphQL */ `
  subscription OnDeleteSavedResults {
    onDeleteSavedResults {
      id
      ruleId
      resultId
      resultCount
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateResultsTag = /* GraphQL */ `
  subscription OnCreateResultsTag {
    onCreateResultsTag {
      id
      createdAt
      updatedAt
      savedResults {
        id
        ruleId
        resultId
        resultCount
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateResultsTag = /* GraphQL */ `
  subscription OnUpdateResultsTag {
    onUpdateResultsTag {
      id
      createdAt
      updatedAt
      savedResults {
        id
        ruleId
        resultId
        resultCount
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteResultsTag = /* GraphQL */ `
  subscription OnDeleteResultsTag {
    onDeleteResultsTag {
      id
      createdAt
      updatedAt
      savedResults {
        id
        ruleId
        resultId
        resultCount
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const onCreateGeoFence = /* GraphQL */ `
  subscription OnCreateGeoFence {
    onCreateGeoFence {
      id
      name
      paths
      center
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateGeoFence = /* GraphQL */ `
  subscription OnUpdateGeoFence {
    onUpdateGeoFence {
      id
      name
      paths
      center
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteGeoFence = /* GraphQL */ `
  subscription OnDeleteGeoFence {
    onDeleteGeoFence {
      id
      name
      paths
      center
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateWidget = /* GraphQL */ `
  subscription OnCreateWidget {
    onCreateWidget {
      id
      name
      type
      sensorKey
      range
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateWidget = /* GraphQL */ `
  subscription OnUpdateWidget {
    onUpdateWidget {
      id
      name
      type
      sensorKey
      range
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteWidget = /* GraphQL */ `
  subscription OnDeleteWidget {
    onDeleteWidget {
      id
      name
      type
      sensorKey
      range
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      email
      phone_number
      notify_email
      notify_sms
      first_name
      last_name
      cognito_id
      status
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      companies {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      email
      phone_number
      notify_email
      notify_sms
      first_name
      last_name
      cognito_id
      status
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      companies {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      email
      phone_number
      notify_email
      notify_sms
      first_name
      last_name
      cognito_id
      status
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      companies {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateUserCompanies = /* GraphQL */ `
  subscription OnCreateUserCompanies {
    onCreateUserCompanies {
      id
      level
      status
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateUserCompanies = /* GraphQL */ `
  subscription OnUpdateUserCompanies {
    onUpdateUserCompanies {
      id
      level
      status
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteUserCompanies = /* GraphQL */ `
  subscription OnDeleteUserCompanies {
    onDeleteUserCompanies {
      id
      level
      status
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const onCreateItem = /* GraphQL */ `
  subscription OnCreateItem {
    onCreateItem {
      id
      name
      sn
      customId
      type
      year
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateItem = /* GraphQL */ `
  subscription OnUpdateItem {
    onUpdateItem {
      id
      name
      sn
      customId
      type
      year
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteItem = /* GraphQL */ `
  subscription OnDeleteItem {
    onDeleteItem {
      id
      name
      sn
      customId
      type
      year
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag {
    onCreateTag {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      items {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag {
    onUpdateTag {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      items {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag {
    onDeleteTag {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      items {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateItemTags = /* GraphQL */ `
  subscription OnCreateItemTags {
    onCreateItemTags {
      id
      createdAt
      updatedAt
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateItemTags = /* GraphQL */ `
  subscription OnUpdateItemTags {
    onUpdateItemTags {
      id
      createdAt
      updatedAt
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteItemTags = /* GraphQL */ `
  subscription OnDeleteItemTags {
    onDeleteItemTags {
      id
      createdAt
      updatedAt
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const onCreateSensorTags = /* GraphQL */ `
  subscription OnCreateSensorTags {
    onCreateSensorTags {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateSensorTags = /* GraphQL */ `
  subscription OnUpdateSensorTags {
    onUpdateSensorTags {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteSensorTags = /* GraphQL */ `
  subscription OnDeleteSensorTags {
    onDeleteSensorTags {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const onCreateSensor = /* GraphQL */ `
  subscription OnCreateSensor {
    onCreateSensor {
      id
      sid
      sn
      customId
      class
      type
      sku
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateSensor = /* GraphQL */ `
  subscription OnUpdateSensor {
    onUpdateSensor {
      id
      sid
      sn
      customId
      class
      type
      sku
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteSensor = /* GraphQL */ `
  subscription OnDeleteSensor {
    onDeleteSensor {
      id
      sid
      sn
      customId
      class
      type
      sku
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateRule = /* GraphQL */ `
  subscription OnCreateRule {
    onCreateRule {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      conditions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateRule = /* GraphQL */ `
  subscription OnUpdateRule {
    onUpdateRule {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      conditions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteRule = /* GraphQL */ `
  subscription OnDeleteRule {
    onDeleteRule {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      conditions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateRuleTag = /* GraphQL */ `
  subscription OnCreateRuleTag {
    onCreateRuleTag {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateRuleTag = /* GraphQL */ `
  subscription OnUpdateRuleTag {
    onUpdateRuleTag {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteRuleTag = /* GraphQL */ `
  subscription OnDeleteRuleTag {
    onDeleteRuleTag {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const onCreateRuleCondition = /* GraphQL */ `
  subscription OnCreateRuleCondition {
    onCreateRuleCondition {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      condition {
        id
        operator
        metric
        value
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        geofence {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateRuleCondition = /* GraphQL */ `
  subscription OnUpdateRuleCondition {
    onUpdateRuleCondition {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      condition {
        id
        operator
        metric
        value
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        geofence {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteRuleCondition = /* GraphQL */ `
  subscription OnDeleteRuleCondition {
    onDeleteRuleCondition {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      condition {
        id
        operator
        metric
        value
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        geofence {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const onCreateCondition = /* GraphQL */ `
  subscription OnCreateCondition {
    onCreateCondition {
      id
      operator
      metric
      value
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      geofence {
        id
        name
        paths
        center
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        dashboard {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCondition = /* GraphQL */ `
  subscription OnUpdateCondition {
    onUpdateCondition {
      id
      operator
      metric
      value
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      geofence {
        id
        name
        paths
        center
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        dashboard {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCondition = /* GraphQL */ `
  subscription OnDeleteCondition {
    onDeleteCondition {
      id
      operator
      metric
      value
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      geofence {
        id
        name
        paths
        center
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        dashboard {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateRuleAction = /* GraphQL */ `
  subscription OnCreateRuleAction {
    onCreateRuleAction {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateRuleAction = /* GraphQL */ `
  subscription OnUpdateRuleAction {
    onUpdateRuleAction {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteRuleAction = /* GraphQL */ `
  subscription OnDeleteRuleAction {
    onDeleteRuleAction {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const onCreateAction = /* GraphQL */ `
  subscription OnCreateAction {
    onCreateAction {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateAction = /* GraphQL */ `
  subscription OnUpdateAction {
    onUpdateAction {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteAction = /* GraphQL */ `
  subscription OnDeleteAction {
    onDeleteAction {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateActionUser = /* GraphQL */ `
  subscription OnCreateActionUser {
    onCreateActionUser {
      id
      createdAt
      updatedAt
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateActionUser = /* GraphQL */ `
  subscription OnUpdateActionUser {
    onUpdateActionUser {
      id
      createdAt
      updatedAt
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteActionUser = /* GraphQL */ `
  subscription OnDeleteActionUser {
    onDeleteActionUser {
      id
      createdAt
      updatedAt
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
