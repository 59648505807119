import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { Item } from '../../Controllers'

import SearchBar from '../../Components/Search/SearchBar'
import ItemTable from '../../Components/Item/ItemTable'
import LoadingTable from '../../Helpers/Components/LoadingTable'

class ItemIndex extends Component {

    state = {
        items: null,
        filteredResults: null,
        loadingItems: true
    }

    fetchItems = async () => {
        let items = await Item.All(this.props.currentCompany)
        
        this.setState({
            items: items,
            filteredResults: items,
            loadingItems: false
        })
    }

    componentDidMount = () => {
        this.fetchItems()
    }

    searchHandler = (results) => {
        this.setState({
            filteredResults: results
        })
    }

    render() {
        const { items, filteredResults, loadingItems } = this.state
        const { t } = this.props
        return (
            <div className="row InnerContainer">
                <div className="col-6 mb-3">
                    <h2><FontAwesomeIcon icon={['fad', 'barcode-read']} /> {t('item.titles')}</h2>
                </div>
                <div className="col-6 mb-3 text-right">
                    <Link to="/item/new" className="btn btn-outline-primary"><FontAwesomeIcon icon={['fad', 'plus']} /> New {t('item.title')}</Link>
                </div>
                
                <SearchBar labelText={`Search ${t('item.titles')}`} itemToSearch={items} keysToSearch={["name", "sn", "customId", "type", "sensors.items", "tags.items"]} handler={this.searchHandler} />
                
                <div className="col-12">
                    { loadingItems ? <section><LoadingTable /></section> : <ItemTable items={filteredResults} /> }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currentCompany: state.user.currentCompany
})

export default connect(mapStateToProps)(withTranslation()(ItemIndex))

