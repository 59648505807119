import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Tag } from '../../Controllers'

import TagTable from '../../Components/Tag/TagTable'
import LeftNav from "../../Components/Settings/LeftNav"

class TagIndex extends Component {
    state = {
        tags: null
    }

    fetchTags = async () => {
        let tags = await Tag.All(this.props.currentCompany)
        console.log("Tags", tags)
        this.setState({
            tags: tags
        })
    }

    componentDidMount = () => {
        this.fetchTags()
    }

    deleteTagHandler = async (tagId) => {
        await Tag.DeleteById(tagId)
        this.fetchTags()
    }
    
    render() {
        const { tags } = this.state
        return (
            <div className="row">
                <LeftNav location={'reporting-tags'} currentUser={this.props.currentUser} currentCompany={this.props.currentCompany} />

                <div className="col-10 ml-auto pl-5">
                    <div className="row">
                        <div className="col-12 pl-4">
                            <div className="row InnerContainer">
                                <div className="col-12">
                                    <h2>Reporting Tags</h2>
                                    <p>Tags that are not attached to any record can be deleted; however, tags in use may not be removed until removed from all records.</p>
                                </div>

                                <div className="col-12">
                                    <section>
                                        { tags ? <TagTable tags={tags} deleteTag={this.deleteTagHandler} /> : null }
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currentCompany: state.user.currentCompany,
    currentUser: state.user.currentUser
})

export default connect(mapStateToProps)(TagIndex)