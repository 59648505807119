import { API, graphqlOperation } from 'aws-amplify'

import { createWidget, deleteWidget } from '../graphql/mutations'
import { listWidgets } from '../graphql/queries'
import { onCreateWidget, onUpdateWidget, onDeleteWidget } from '../graphql/subscriptions'

export const Create = async (dashboard, widget) => {
    let input = {
        widgetDashboardId: dashboard.id,
        widgetSensorId: widget.sensor,
        widgetItemId: widget.itemId
    }
    delete widget.tags
    delete widget.itemId
    delete widget.sensor
    delete widget.sensorId
    input = { ...input, ...widget }

    try {
        return await API.graphql(graphqlOperation(createWidget, { input }))
    } catch (e) {
        console.warn("Failed to create a new Widget.", e)
        return false
    }
}

export const FindByItemId = async (itemId) => {
    try {
        return (await API.graphql(graphqlOperation(listWidgets))).data.listWidgets.items.filter(w => w.item && w.item.id === itemId)
    } catch (e) {
        console.warn("Failed to find Widget by Item ID", itemId, e)
    }
}

export const All = async (dashboard) => {
    try {
        return (await API.graphql(graphqlOperation(listWidgets))).data.listWidgets.items.filter(w => w.dashboard.id === dashboard.id)
    } catch (e) {
        console.warn("There was an issue getting all widgets.", e)
        return false
    }
}

export const Delete = async (widget) => {
    try {
        return await API.graphql(graphqlOperation(deleteWidget, { input: { id: widget.id } }))
    } catch (e) {
        console.warn("Failed to delete the widget.", e)
        return false
    }
}

export const CreateSubscription = () => {
    return API.graphql(graphqlOperation(onCreateWidget))
}

export const UpdateSubscription = () => {
    return API.graphql(graphqlOperation(onUpdateWidget))
}

export const DeleteSubscription = () => {
    return API.graphql(graphqlOperation(onDeleteWidget))
}
