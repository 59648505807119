import React from "react"

const EcholoProfileForm = () => {
    return (
        <div className={'row'}>
            <div className={'col-12'}>
                <h5>Echolo Rule Profile</h5>
                <p>Echolo delivery allows all results from a Rule to be saved to Echolo's Result Reports. This will give you access to reports after a Rule has run. No configuration is needed for this type of Profile!</p>
            </div>
        </div>
    )
}

export default EcholoProfileForm
