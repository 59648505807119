import React, { Component } from 'react'
import { Hub } from '@aws-amplify/core'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Company } from '../../Controllers'

import CompanyForm from '../../Components/Company/CompanyForm'
import LeftNav from "../../Components/Settings/LeftNav"

class CompanyEdit extends Component {

    state = {
        company: null,
    }

    _companyId = this.props.companyId || this.props.match.params.id

    componentDidMount = async () => {
        let company = await Company.Find({id: this._companyId})
        
        if (!_.isEmpty(company)) {
            this.setState({
                company: company
            })
        }
    }

    updateCompanyhandler = async (companyData) => {
        let updatedCompany = await Company.Update(this.state.company, companyData)
        if (updatedCompany) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Saved!'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue saving your update, please try again.'
                }
            })
        }
    }

    render() {
        const { company } = this.state
        return (
            <div className="row">
                <LeftNav location={'company-profile'} currentUser={this.props.currentUser} currentCompany={this.props.currentCompany} />

                <div className="col-10 ml-auto pl-5">
                    <div className="row">
                        <div className="col-12 pl-4">
                            <div className="row InnerContainer">
                                <div className="col-12">
                                    <h2>Update Company Profile</h2>
                                </div>
                                <div className="col-12">
                                    <section>
                                        {company ? <CompanyForm submitHandler={this.updateCompanyhandler} company={company} /> : null}
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(CompanyEdit)