import React from 'react'

const MinimalLayout = ({ children }) => {
    return (
        <div className="container-fluid MinimalLayout">
            <div className="row justify-content-md-center">
                <div className="col-6 text-center">
                    <img src="/images/explorer_logotype_white_transparent.png" className="mt-4 mb-4" alt="Explorer" />
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-6">
                    {children}
                    <p className="text-center">&copy; Copyright Echolo, LLC - Explorer</p>
                </div>
            </div>
        </div>
    )
}

export default MinimalLayout