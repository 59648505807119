import React, { useState } from 'react'
import { Hub } from '@aws-amplify/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-bootstrap/Modal'
import {  FormControl, 
    InputLabel, 
    Select, 
    MenuItem,
    Tooltip} from '@material-ui/core'
import $ from 'jquery'

import { User } from '../../Controllers'
import { TextHelpers } from '../../Helpers'
import AreYouSure from '../../Helpers/Components/AreYouSure'


const UserTable = ({ users, currentCompany, currentUser }) => {
    const initSelectedUser = { email: '', first_name: '', last_name: '', level: 'member' }
    const initAreYouSure = { show: false, Confirm: () => true, Cancel: () => closeAreYouSureModal(), Title: '', Message: '' }

    const [showModel, setShowModel] = useState(false)
    const [selectedUser, setSelectedUser] = useState(initSelectedUser)
    const [AreYouSureData, setAreYouSureData] = useState(initAreYouSure)

    const openPermissionModel = (user) => {
        setSelectedUser(user)
        setShowModel(true)
    }

    const savePermissionHandler = async () => {
        let results = await User.UpdateCompanyPermission(selectedUser, currentCompany)
        if (results) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success',
                    message: 'Saved!'
                }
            })
            setShowModel(false)
            setSelectedUser(initSelectedUser)
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: "Sorry, this Person's permissions could not be changed; please try again."
                }
            })
        }
    }

    const updateUserStatus = async (user, status) => {
        let results = await User.UpdateStatus(user, currentCompany, status)
        if (results) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: `${user.first_name} ${user.last_name} is now ${status}.`
                }
            })
            setShowModel(false)
            setSelectedUser(initSelectedUser)
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: `Sorry, this user could not be ${status}, please try again.`
                }
            })
        }
    }

    const beforeDeleteUserFromCompany = (user) => {
        setAreYouSureData({ ...AreYouSureData,
            show: true, 
            Title: "Remove this Person?", 
            Message: `Are you sure you would like to Remove this ${user.first_name} ${user.last_name}?`, 
            Confirm: () => deleteUserFromCompany(user) })
    }

    const deleteUserFromCompany = async (user) => {
        let results = await User.Delete(user, currentCompany)
        if (results) {
            closeAreYouSureModal()

            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: `${user.first_name} ${user.last_name} has been removed from ${currentCompany.name}.`
                }
            })

            setShowModel(false)
            setSelectedUser(initSelectedUser)
            $('#userTable_' + user.id).hide('fast')
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: `Sorry, this user could not be Removed, please try again.`
                }
            })
        }
    }

    const closeAreYouSureModal = () => {
        setAreYouSureData(initAreYouSure)
    }

    let permissionModel = (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Update Permissions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Below you can change the application access permissions for {selectedUser.first_name} {selectedUser.last_name} ({selectedUser.email})</p>
                <div className="form-group">
                    <FormControl variant="outlined" className="form-control">
                        <InputLabel id="sensorTypeLabel">Sensor Type</InputLabel>
                        <Select
                            fullWidth
                            labelId="sensorTypeLabel"
                            id="demo-simple-select-helper"
                            value={selectedUser.level}
                            onChange={(e) => setSelectedUser( {...selectedUser, level: e.target.value} )}>
                            <MenuItem value={'member'}>Member</MenuItem>
                            <MenuItem value={'owner'}>Owner</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="row pt-4">
                    <div className="col-12">
                        <h4>Permissions</h4>
                        <ul>
                            <li><strong>Member:</strong> Has access to create, update, delete and view all Application Items or Sensors.</li>
                            <li><strong>Owner:</strong> Has `Member` access plus acccess to alter Application details.</li>
                        </ul>
                    </div>
                </div>

                {currentUser.id !== selectedUser.id ?
                    <div className="row pt-4">
                        <div className="col-12">
                            <section className="callout">
                                <h4>Suspend/Delete {selectedUser.first_name} {selectedUser.last_name}</h4>

                                <hr />

                                <div className="row DeleteBoxInCallout">

                                    <div className="col">
                                        {selectedUser.company_user_status === 'suspended' ?
                                        <button type="button" onClick={() => updateUserStatus(selectedUser, 'active')} className="btn btn-block btn-success"><FontAwesomeIcon icon={['fad', 'play']} /> Activate</button>
                                        : <button type="button" onClick={() => updateUserStatus(selectedUser, 'suspended')} className="btn btn-block btn-warning"><FontAwesomeIcon icon={['fad', 'pause']} /> Suspend</button> }
                                    </div>


                                    <div className="col">
                                        <button type="button" onClick={() => beforeDeleteUserFromCompany(selectedUser)} className="btn btn-block btn-danger"><FontAwesomeIcon icon={['fad', 'trash-alt']} /> Delete</button>
                                    </div>

                                    <div className="col-12 mt-2 text-center">
                                        <pre>Person ID: {selectedUser.cognito_id}</pre>
                                    </div>

                                </div>

                                <div className="row mt-4">
                                    <div className="col-12 mb-2">
                                        <h5><FontAwesomeIcon icon={['fad', 'pause']} /> Suspend</h5>
                                        <p>When you suspend this Person, the system will no longer allow them to log in. Alert notifications are still sent to this Person during Suspension.</p>
                                        <div className="alert alert-warning" role="alert">
                                            <strong><FontAwesomeIcon icon={['fad', 'info-circle']} /> Tip:</strong> If you would like the Suspended Person to stop receiving Alert notifications, you can remove them from any Action they may belong to.
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <h5><FontAwesomeIcon icon={['fad', 'trash-alt']} /> Delete</h5>
                                        <p>On delete, the Person will lose access to the system immediately. If the Person has created dashboards, they will also be removed; this includes Actions. 
                                            While the entire Action will not be removed, the Person will be removed from the Action.</p>
                                    </div>
                                </div>
                                
                            </section>
                        </div>
                    </div>:
                null }
            </Modal.Body>
            <Modal.Footer>
                <button className="btn" onClick={() => setShowModel(false)}>
                    Close
                </button>
                <button className="btn btn-primary" onClick={() => savePermissionHandler()}>
                    Save Changes
                </button>
            </Modal.Footer>
        </Modal>
    )

    let userRows = users.map((user) => {
        return (
            <tr id={`userTable_${user.id}`} className={user.company_user_status === 'suspended' ? 'table-warning' : null} key={user.id}>
                <td>{user.company_user_status === 'suspended' ? <strong>({user.company_user_status}) </strong> : null}{user.email}</td>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>
                    <Tooltip arrow title="Update Permissions">
                        <span className="FakeLink" onClick={() => openPermissionModel(user)}><FontAwesomeIcon icon={['fad', 'user-shield']} /> {TextHelpers.capitalize(user.level)}</span>
                    </Tooltip>
                </td>
            </tr>
        )
    })

    return (
        <>
            {permissionModel}
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Email Address</th>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                        <th scope="col">Permission</th>
                    </tr>
                </thead>
                <tbody>
                    {users ? userRows : null}
                </tbody>
            </table>
            <AreYouSure show={AreYouSureData.show} Confirm={AreYouSureData.Confirm} Cancel={AreYouSureData.Cancel} Title={AreYouSureData.Title} Message={AreYouSureData.Message} />
        </>
    )
}

export default UserTable