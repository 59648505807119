import { EcholoApi, Headers } from './config'


export const All = async (application) => {
    try {
        let devices = await EcholoApi.get('/devices', Headers(application))
        if (devices.data.error) throw new Error(devices.data.error)
        return devices
    } catch (e) {
        return false
    }
}

export const Find = async (application, deviceId) => {
    try {
        let devices = await EcholoApi.get('/devices/' + deviceId, Headers(application))
        if (devices.data.error) throw new Error(devices.data.error)
        return devices.data
    } catch (e) {
        console.error("FAILED to Find Device", e)
        return false

    }
}

export const Search = async (application, nodeId) => {
    try {
        let input = [{ "fields.packet.appId": application.appId }]
        input.push({ "fields.packet.node": nodeId })
        let search = {search: input, limit: 10}
        let searchData = await EcholoApi.post('/search', JSON.stringify(search), Headers(application))
        return searchData
    } catch (e) {
        
        return false
        
    }
}

export const SearchProfiles = async (application, nodeId, widgetData) => {
    try {
        let range = widgetData.range ? widgetData.range : "now-15m"
        let input = [{ "fields.packet.appId": application.appId}, {"fields.packet.nodeId": nodeId}, {"fields.node_status": "profiled"}]
        let search = {search: input, limit: 1000, fromDate: range}
        let searchData = await EcholoApi.post('/search', JSON.stringify(search), Headers(application))

        if (searchData.data.error) throw new Error(searchData.data.error)
        return searchData.data
    } catch (e) {
        return false
    }
}