import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import SelectCompany from '../../Components/Company/SelectCompany'

class CompanySelect extends Component {
    state = {
        selected: null
    }

    selectHandler = () => {
        this.props.history.push('/dashboard')
    }

    render() {
        return (
            <div className="row InnerContainer">
                <div className="col-12">
                    <h2>Select your Application</h2>
                </div>
                <div className="col-12">
                    <SelectCompany handler={this.selectHandler} />
                </div>
            </div>
        )
    }
}

export default withRouter(CompanySelect)