import React, { Component } from 'react'
import { Hub } from '@aws-amplify/core'
import { connect } from 'react-redux'

import { UserAuth } from '../../Functions/Auth'
import { Company, User } from '../../Controllers'

import CompanyForm from '../../Components/Company/CompanyForm'
import LeftNav from "../../Components/Settings/LeftNav"

class CompanyNew extends Component {
    createCompanyHandler = async (companyData) => {
        try {
            let company = await Company.Create(companyData)

            let assignment = await User.AssignCompany(this.props.currentUser, company)

            if (assignment) {
                UserAuth.RefreshCurrentUser()
                Hub.dispatch('Alert', {
                    event: 'show',
                    data: {
                        type: 'success', 
                        message: 'Saved!'
                    }
                })
            } else {
                Hub.dispatch('Alert', {
                    event: 'show',
                    data: {
                        type: 'error', 
                        message: 'Sorry, there was an issue saving your update, please try again.'
                    }
                })
            }
            
            
        } catch (e) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue saving your update, please try again: ' + e.message
                }
            })
        }
    }

    render() {
        return (
            <div className="row">
                <LeftNav location={'company-new'} currentUser={this.props.currentUser} currentCompany={this.props.currentCompany} />

                <div className="col-10 ml-auto pl-5">
                    <div className="row">
                        <div className="col-12 pl-4">
                            <div className="row InnerContainer">
                                <div className="col-12">
                                    <h2>Create a new Company</h2>
                                </div>

                                <div className="col-12">
                                    <section>
                                        <CompanyForm submitHandler={this.createCompanyHandler} />
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany,
        currentUser: state.user.currentUser
    }
}

export default connect(mapStateToProps)(CompanyNew)