import React, { useState } from 'react'
import { connect } from 'react-redux'
import { SET_CURRENT_USER } from '../../Store/Actions'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { withRouter } from 'react-router-dom'
import { Hub } from '@aws-amplify/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from '../../Helpers/Components/Loading'

const Login = ({ history }) => {
    const logValues = {
        email: '',
        password: ''
    }
    const { register, handleSubmit, errors } = useForm()
    const [userLogin, setUserLogin] = useState(logValues)
    const [submitDisabled, setSubmitDisabled] = useState(false)

    const loginHandler = async (userData) => {
        setSubmitDisabled(true)
        try {
            let result = await Auth.signIn(userData.email, userData.password)
            if (result) {
                Hub.dispatch('Alert', {
                    event: 'show',
                    data: {
                        type: 'success', 
                        message: 'Signing you in...'
                    }
                })
            }
        } catch (error) {
            // If user was converted OR invited they will have to set a password.
            if (error.code === "PasswordResetRequiredException") {
                // Redirect to reset password
                history.push('/forgot-password')
            } else {
                console.log(error)
                setSubmitDisabled(false)
                Hub.dispatch('Alert', {
                    event: 'show',
                    data: {
                        type: 'error', 
                        message: `I am sorry! There was an issue. ${error.message}`
                    }
                })
                
            }
        }
    }

    return (
        <div className="row justify-content-md-center">

            <div className="col-6">
                <section className="iotbg-light">
                    <form onSubmit={handleSubmit(loginHandler)}>
                        <div className="LoginForm">
                            <div className="row mb-4">
                                <div className="col-12">
                                    <div className="form-group">
                                        {errors.email && <p className="text-danger">Email is required.</p>}
                                        <input value={userLogin.email} 
                                            name="email"
                                            onChange={e => setUserLogin({ ...userLogin, email: e.target.value })} 
                                            type="email" 
                                            placeholder="Email Address" 
                                            className="form-control form-control-lg" id="email"
                                            ref={register({ required: true })} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-12">
                                    <div className="form-group">
                                        {errors.password && <p className="text-danger">Password is required.</p>}
                                        <input value={userLogin.password} 
                                            name="password"
                                            onChange={e => setUserLogin({ ...userLogin, password: e.target.value })} 
                                            type="password" 
                                            placeholder="Password" 
                                            className="form-control form-control-lg" id="password"
                                            ref={register({ required: true })} />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12">
                                    <div className="form-group text-center">
                                        {submitDisabled ? <Loading isOpen={true} /> : 
                                            <button disabled={submitDisabled} type="submit" className="btn btn-primary btn-lg btn-block">Sign In <FontAwesomeIcon icon={['fad', 'chevron-right']} /></button>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <p>Did you forget your password? Don't worry, it happens! <Link to="/forgot-password">Rest your password here!</Link></p>
                                </div>
                                <div className="col-8">
                                    <p className="text-size-medium">Don't have an account? <Link to="/register">Register here</Link>.</p>
                                </div>
                            </div>
                            
                        </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentUser: (user) => dispatch({ type: SET_CURRENT_USER, payload: user })
    }
}

export default connect(null, mapDispactToProps)(withRouter(Login))