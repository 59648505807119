import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'

const CompanyForm = ({ submitHandler, company = { name: '', type: 'base' } }) => {

    const initCompany = {
        name: company.name,
        type: company.type
    }
    const [companyState, setCompanyState] = useState(initCompany)

    const CompanyHandler = async (event) => {
        event.preventDefault()
        submitHandler(companyState)
        if (!company.name) {
            setCompanyState({ name: '', type: 'base' })
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={CompanyHandler}>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="form-group">
                                <TextField className="form-control" variant="outlined" value={companyState.name} label="Application Name" onChange={(e) => setCompanyState({ ...companyState, name: e.target.value })} name="name" required />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="form-group">
                                <FormControl className="form-control" variant="outlined">
                                    <InputLabel id="sensorTypeLabel">Application Type</InputLabel>
                                    <Select
                                        labelId="sensorTypeLabel"
                                        id="demo-simple-select-helper"
                                        value={companyState.type}
                                        onChange={(e) => setCompanyState( {...companyState, type: e.target.value} )}>
                                        <MenuItem value={'base'}>Basic</MenuItem>
                                        <MenuItem value={'construction'}>Construction</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-12 text-right">
                            <button className="btn btn-primary"><FontAwesomeIcon icon={['fad', 'save']} /> Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CompanyForm