import { API, graphqlOperation } from 'aws-amplify'
import _ from 'lodash'

import { createCompany, updateCompany } from '../graphql/mutations'
import { getCompany, listUserCompaniess, listCompanys, companyByAppId } from '../graphql/queries'
import { onUpdateCompany, onUpdateUserCompanies } from '../graphql/subscriptions'

import { Application } from '../Services/Echolo'

export const Create = async (company) => {
    try {
        let app = await Application.Create({ name: company.name, type: company.type || 'basic' })
        const input = {
            name: company.name,
            appId: app.data.appId,
            appSecret: app.data.secret,
            token: app.data.token
        }
        let newCompany = (await API.graphql(graphqlOperation(createCompany, { input }))).data.createCompany
        newCompany.userLevel = 'owner'
        return newCompany
    } catch (e) {
        return e
    }
}

export const Activate = async (appId) => {
    let localApp = {}

    // First Looking up if we have a local 'company/app'
    try {
        localApp = (await API.graphql(graphqlOperation(companyByAppId, { appId: appId } ))).data.companyByAppId.items[0]
        localApp.userLevel = 'member'
    } catch (e) {
        console.log("Failed to find Local Company:", e)
    }
    

    // If we do not have a company, create a new local company.
    if (_.isEmpty(localApp)) {
        console.log("localApp is empty, create new")
        try {
            let app = await Application.Activate(appId)
            let company = await CreateLocal(app)
            let newCompany = company.data.createCompany
            newCompany.userLevel = 'owner'
            return newCompany
        } catch (e) {
            console.warn("Failed to create a new company in Activation.", e)
            return false
        }
    } else {
        return localApp
    }
    
}

export const Update = async (company, updatedData) => {
    const input = updatedData
    input.id = company.id

    if (!updatedData.token) {
        try {
            await Application.Update(company, updatedData)
        } catch (e) {
            console.warn("Warning, failed to update the Application on the API.", e)
            return false
        }
    }

    try {
        return (await API.graphql(graphqlOperation(updateCompany, { input }))).data.updateCompany
    } catch (e) {
        console.warn("Warning, failed to update the Company", e)
        return false
    }
}

export const Find = async (company) => {
    return (await API.graphql(graphqlOperation(getCompany, { id: company.id } ))).data.getCompany
}

export const FindByAppId = async (companyAppId) => {
    return (await API.graphql(graphqlOperation(companyByAppId, { appId: companyAppId }))).data.companyByAppId.items[0]
}

export const Users = async (company) => {
    let userAndCompany = (await API.graphql(graphqlOperation(listUserCompaniess))).data.listUserCompaniess.items.filter(uc => uc.company.id === company.id)
    let users = []
    userAndCompany.forEach(uc => {
        uc.user.level = uc.level
        uc.user.company_user_status = uc.status
        users.push(uc.user)
    })
    return users
}

export const UsersSubscription = (company) => {
    return API.graphql(graphqlOperation(onUpdateUserCompanies))
}

export const All = async () => {
    return await API.graphql(graphqlOperation(listCompanys))
}

export const Subscribe = () => {
    return API.graphql(graphqlOperation(onUpdateCompany))
}

export const InviteUser = async (currentUser, company, invite) => {
    return await API.post('explorerRestApi', '/invite', {
        body: {
            invite: invite,
            company: company,
            currentUser: currentUser
        }
    })
}

// Private
const CreateLocal = async (app) => {
    const input = {
        name: app.name,
        appId: app.appId,
        appSecret: app.secret,
        type: 'basic',
        token: app.token
    }
    try {
        return await API.graphql(graphqlOperation(createCompany, { input }))
    } catch (e) {
        console.warn("Failed to create a local Company:", e)
        return false
    }
}