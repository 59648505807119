import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '@material-ui/core/Tooltip'
import _ from 'lodash'
import $ from 'jquery'
import { Hub } from '@aws-amplify/core'

import { Sensor } from '../../Controllers'
import { Device } from '../../Services/Echolo'

import SensorInfoModel from './SensorInfoModel'
import Loading from '../../Helpers/Components/Loading'

const SensorSelectList = ({ currentCompany, selectHandler }) => {

    const [devices, setDevices] = useState([])
    const [fetchDevicesLoading, setFetchDevicesLoading] = useState(true)
    const [showModel, setShowModel] = useState(false)
    const [selectedSensorInfo, setSelectedSensorInfo] = useState(null)

    const fetchDevices = async () => {
        setFetchDevicesLoading(true)
        let currentSensors = (await Sensor.All(currentCompany)).map(sensor => sensor.sid)
        let ApiDevices = []
        try {
            ApiDevices = (await Device.All(currentCompany)).data.filter(device => !currentSensors.includes(device._id))
        } catch (error) {
            console.log(error)
        }
        
        if (ApiDevices.length > 0) {
            setDevices(ApiDevices)
            setFetchDevicesLoading(false)
        } else {
            setFetchDevicesLoading(false)
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'info', 
                    message: "You don't have any active Sensors to import."
                }
            })
        }
    }
    
    useEffect(() => {
        fetchDevices()
    }, [])

    const select = (data) => {
        $("#sensorSelectList li").removeClass('active')
        $('#sensorList_'+ data._id).addClass('active')

        let selectedSensor = {
            class: '',
            type: ''
        }

        switch (data.nodeType) {
            case "OEM":
                selectedSensor.class = 'ble'
                break
            default:
                selectedSensor.class = 'ble'
                break
        }

        if (data.profilePacket) {
            switch (data.profilePacket.profile_type) {
                case "OEM-1":
                    selectedSensor.type = 'Beacon'
                    break
                case "OEM-3":
                    selectedSensor.type = 'Beacon'
                    break
                default:
                    selectedSensor.type = 'Beacon'
                    break
            }
        } else {
            selectedSensor.type = 'Beacon'
        }

        selectHandler({
            sid: data.nodeId,
            class: selectedSensor.class,
            type: selectedSensor.type
        })
    }

    const sensorModel = (sensor) => {
        setSelectedSensorInfo(sensor)
        setShowModel(true)
    }

    let devicesDipslay = null
    if (devices.length > 0) {
        devicesDipslay = devices.map((device, key) => {
            if (!_.isEmpty(device)) {
                return (
                    <li id={'sensorList_' + device.nodeId} className="list-group-item list-group-item-action FakeLink" key={key}>
                        <p onClick={() => select(device)} className="p-0 m-0"><FontAwesomeIcon icon={['fad', 'clone']} /> <span className="float-right pr-2">
                            {device.nodeId}
                            {
                                device.profilePacket && !device.nodeType ? '(' + device.profilePacket.profile_type + ')' : null
                            }
                            {
                                !device.profilePacket && device.nodeType ? '(' + device.nodeType + ')' : '(No Profile)'
                            }
                            </span></p>
                        <FontAwesomeIcon onClick={() => sensorModel(device)} className="SensorSelectListInfoIcon" icon={['fad', 'info-square']} />
                    </li>
                )
            }
        })
    } else {
        devicesDipslay = (
            <div className="alert alert-info" role="alert">
                <FontAwesomeIcon icon={['fad', 'times']} /> Your Application does not have any active Sensors seen by the network.
            </div>
        )
    }

    return (
        <div className="row">
            <div className="col-12 text-right mb-2">
                <Tooltip title="Sync active Devices" arrow>
                    <span className="FakeLink" onClick={fetchDevices}><FontAwesomeIcon icon={['fad', 'sync']} /></span>
                </Tooltip>
            </div>

            <div className="col-12 sensorSelectList">
                <ul id="sensorSelectList" className="list-group">
                    { fetchDevicesLoading ? <Loading isOpen={true} /> : devicesDipslay }
                </ul>
            </div>
            <SensorInfoModel sensor={selectedSensorInfo} showModel={showModel} setShowModel={setShowModel} />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(SensorSelectList)