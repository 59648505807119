import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

const LoadingTable = () => {
    return (
        <table className="table table-borderless">
            <thead>
                <tr>
                    <th><Skeleton /></th>
                    <th><Skeleton /></th>
                    <th><Skeleton /></th>
                    <th><Skeleton /></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                </tr>
                <tr>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                </tr>
                <tr>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                </tr>
            </tbody>
        </table>
    )
}

export default LoadingTable