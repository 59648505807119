import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Hub } from "@aws-amplify/core"
import $ from 'jquery'

import ReportsMenu from "../../Components/Reports/ReportsMenu"

import { SavedResults } from "../../Controllers"
import { GetReportById } from '../../Services/Echolo/Reports'
import LoadingTable from "../../Helpers/Components/LoadingTable"
import { GetRule } from "../../Services/Echolo/Rules/Rule"
import { Link } from "react-router-dom"
import { StDateToTimeStamp } from "../../Helpers/DateHelper"
import TagChip from "../../Components/Tag/TagChip"
import Loading from "../../Helpers/Components/Loading"
import SavedReportModel from "../../Components/Reports/SavedReportModel"
import SavedResultsDeleteModel from '../../Components/Reports/Results/SavedResultsDeleteModel'
import DeviceReportTable from "../../Components/Reports/DeviceReportTable"

class SavedReportsShow extends Component {
    state = {
        savedResults: null,
        results: null,
        showResultOptions: false,
        showResultDeleteModel: false,
    }

    fetchResults = async (resultId) => {
        const { currentCompany } = this.props
        const results = await GetReportById(currentCompany, resultId)
        this.setState({
            results: results[0]
        })
    }

    fetchSavedResults = async () => {
        const { currentCompany } = this.props
        const resultsData = await SavedResults.GetSavedResultsById(currentCompany, this.props.match.params.id)
        if (resultsData) {
            const rule = await GetRule(currentCompany, resultsData.ruleId)
            this.setState({
                savedResults: { ...resultsData, rule: rule }
            })
            await this.fetchResults(resultsData.resultId)
        }
    }

    componentDidMount() {
        this.fetchSavedResults()
    }

    listTags = (tags) => {
        return tags.map((tag, key) => {
            return (
                <TagChip key={key} tag={tag.tag} />
            )
        })
    }

    setShowOptions = (status) => {
        this.setState({
            showResultOptions: !status
        })
        $('#show_delete_option').slideToggle(1000)
    }

    saveReportModelState = (status) => {
        this.setState({
            saveReportModelStatus: status
        })
    }

    saveResultDeleteModel = (status) => {
        this.setState({
            showResultDeleteModel: status
        })
    }

    saveReportHandler = async (resultUpdate) => {
        const { currentCompany } = this.props
        const result = await SavedResults.UpdateSavedReport(currentCompany, resultUpdate)
        if (result) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success',
                    message: 'Saved!'
                }
            })
            const rule = await GetRule(currentCompany, result.ruleId)
            this.setState({
                savedResults: { ...result, rule: rule }
            })
            this.saveReportModelState(false)
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue saving the updates. Please try again.'
                }
            })
        }
        console.log('saving', result)
    }

    deleteResults = async (result) => {
        const removed = await SavedResults.Delete(result.id)
        if (removed) {
            this.props.history.push('/reports/view/saved')
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue removing the results. Please try again.'
                }
            })
        }
    }

    render() {
        const { savedResults, results, showResultOptions, saveReportModelStatus, showResultDeleteModel } = this.state
        return (
            <div className={'row'}>
                <div className={'col-2 sidebarSubMenu'}>
                    <ReportsMenu location={'savedReports'} />
                </div>
                <div className={'col-10 ml-auto pl-5'}>
                    <div className="row InnerContainer">
                        <div className={'col-12'}>
                            { savedResults ?
                            <h2><FontAwesomeIcon icon="fa-duotone fa-bookmark" /> {savedResults.name}</h2>
                                : null }
                        </div>

                        <div className={'col-6'}>
                            <section>
                                {results && savedResults ? null :
                                    <div className="alert alert-warning" role="alert">
                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <small> NOTE: Older saved Reports may take a little longer to load...</small>
                                    </div>
                                }
                                {results && savedResults ?
                                <table className="table table-sm table-hover">
                                    <tbody>
                                        <tr>
                                            <td scope={'col'}>Results Reported On:</td>
                                            <td><FontAwesomeIcon icon="fa-duotone fa-calendar-clock" /> {StDateToTimeStamp(results['@timestamp'])}</td>
                                        </tr>
                                        <tr>
                                            <td scope={'col'}>Collected From Rule:</td>
                                            <td><Link to={'/rule/'+ savedResults.rule.ruleId +'/view'}><FontAwesomeIcon icon="fa-duotone fa-code-branch" /> {savedResults.rule.name}</Link></td>
                                        </tr>

                                        <tr>
                                            <td scope={'col'}>Results Saved On:</td>
                                            <td>{StDateToTimeStamp(savedResults.createdAt)}</td>
                                        </tr>
                                        <tr>
                                            <td scope={'col'}>Tags:</td>
                                            <td>{this.listTags(savedResults.tags.items)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                    : <LoadingTable /> }
                            </section>
                        </div>

                        <div className={'col-6'}>
                            <section>
                                {results && savedResults ?
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <section className={'callout'}>
                                            <h4 onClick={() => this.setShowOptions(showResultOptions)} className={'FakeLink text-center'}><FontAwesomeIcon icon={showResultOptions ? 'fa-duotone fa-caret-down' : 'fa-duotone fa-caret-right'} /> Saved Results Options</h4>
                                            <div id={'show_delete_option'} className={'hiddenOptions'}>

                                                <hr />
                                                <div className={'row DeleteBoxInCallout'}>
                                                    <div className={'col-12'}>
                                                        <button onClick={() => this.saveReportModelState(true)} className={'btn btn-block btn-primary'}><FontAwesomeIcon icon="fa-duotone fa-pen-to-square" /> Edit</button>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <p>If you delete the saved results you may not be able to access them again unless you use the Report Builder. If you plan on getting to the results again please save the result ID below.</p>
                                                <div className={'row DeleteBoxInCallout'}>
                                                    <div className={'col-12'}>
                                                        <button onClick={() => this.saveResultDeleteModel(true)} className={'btn btn-block btn-danger'}><FontAwesomeIcon icon="fa-duotone fa-trash-can" /> Delete</button>
                                                    </div>
                                                    <div className={'col-12 mt-2 text-center'}>
                                                        <pre>Result ID: {savedResults.resultId}</pre>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                    : <Loading isOpen={true} /> }
                            </section>
                        </div>

                        <div className={'col-12'}>
                            <section>
                                {results ? <DeviceReportTable devicesData={results.fields.ruleResults.results} /> : <LoadingTable />}
                            </section>
                        </div>
                    </div>
                </div>

                { savedResults ?
                    <>
                        <SavedResultsDeleteModel showModel={showResultDeleteModel} savedResult={savedResults} setShowModel={this.saveResultDeleteModel} submitHandler={this.deleteResults} />
                        <SavedReportModel inEdit={true} showModel={saveReportModelStatus} setShowModel={this.saveReportModelState} ruleId={savedResults.ruleId} resultData={savedResults} submitHandler={this.saveReportHandler} />
                    </>
                    : null }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany,
        currentUser: state.user.currentUser
    }
}
export default connect(mapStateToProps)(withTranslation()(SavedReportsShow))