// const months = [
//     'January',
//     'February',
//     'March',
//     'April',
//     'May',
//     'June',
//     'July',
//     'August',
//     'September',
//     'October',
//     'November',
//     'December'
// ]
// const days = [
//     'Sun',
//     'Mon',
//     'Tue',
//     'Wed',
//     'Thu',
//     'Fri',
//     'Sat'
// ]

const addZero = (i) => {
    if (i < 10) {
        i = "0" + i
    }
    return i
}

export const StDateToTimeStamp = (incomingDate) => {
    let d = Date.parse(incomingDate)
    d = new Date(d)

    const year = d.getFullYear()

    const hour = d.getHours()
    const mins = addZero(d.getMinutes())
    //const seconds = addZero(d.getSeconds())

    return `${d.getMonth()+1}/${d.getDate()}/${year} ${hour}:${mins}`
    // var datum = new Date(Date.UTC(year, d.getMonth(), d.getDay(), hour, mins, seconds))
    // return datum.getTime()/1000
}