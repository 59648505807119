import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { SET_SELECTED_DASHBOARD } from '../../Store/Actions'

import { Dashboards } from '../../Controllers'

import { getParamsObj } from '../../Helpers/UrlParams'

import DashboardSelect from '../../Components/Dashboard/DashboardSelect'
import DashboardShow from '../../Components/Dashboard/DashboardShow'

class DashboardIndex extends Component {
    state = {
        dashboards: [],
        loading: true,
        selectedDash: null
    }

    subscribeDashboardUpdates = () => {
        const { currentUser, currentCompany } = this.props
        // New dashboard
        Dashboards.CreateSubscription().subscribe({
            next: async (newDashboard) => {
                let dash = newDashboard.value.data.onCreateDashboard
                if (dash.company.id === currentCompany.id && dash.user.id === currentUser.id) {
                    let dashboards = await Dashboards.All(currentCompany, currentUser)
                    this.setState({
                        dashboards: dashboards
                    })
                }
            }
        })

        Dashboards.DeleteSubscription().subscribe({
            next: oldDashboard => {
                let dash = oldDashboard.value.data.onDeleteDashboard
                if (dash.company.id === currentCompany.id && dash.user.id === currentUser.id) {

                    let dashboards = this.state.dashboards.filter(stateDash => stateDash.id !== dash.id)
                    this.setState({
                        dashboards: dashboards
                    })
                }
            }
        })

        Dashboards.UpdateSubscription().subscribe({
            next: updatedDashboard => {
                let dash = updatedDashboard.value.data.onUpdateDashboard
                if (dash.company.id === currentCompany.id && dash.user.id === currentUser.id) {
                    let dashboards = this.state.dashboards.filter(stateDash => stateDash.id !== dash.id)
                    // Array without the updated dash
                    this.setState({
                        dashboards: [ ...dashboards, dash ]
                    })
                }
            }
        })
    }

    fetchDashboards = async () => {
        const { currentUser, currentCompany } = this.props
        let dashboards = await Dashboards.All(currentCompany, currentUser)
        
        this.setState({
            dashboards: dashboards,
            loading: false
        })
    }

    fetchDashboard = async (dashboardId) => {
        const { currentUser, currentCompany } = this.props
        let dashboard = await Dashboards.Find(currentCompany, currentUser, dashboardId)

        if (dashboard) {
            this.props.setSelectedDashboard(dashboard)
            this.setState({
                selectedDash: dashboardId
            })
        }
    }

    componentDidMount = () => {
        const { selectedDashboard, currentCompany } = this.props
        if (!_.isEmpty(currentCompany)) {
            const urlParams = getParamsObj(this.props.location.search)
        
            if (_.isString(urlParams.dash) && !_.isEmpty(urlParams.dash)){
                // Set setSelectedDashboard
                this.fetchDashboard(urlParams.dash)
            }

            if (selectedDashboard) {
                this.props.history.push('/dashboard?dash='+selectedDashboard.id)
                this.setState({
                    selectedDash: selectedDashboard.id
                })
            }
            this.fetchDashboards()
            this.subscribeDashboardUpdates()
        }
    }

    selectDashboard = (dashboard) => {
        this.props.setSelectedDashboard(dashboard)
        this.setState({
            selectedDash: dashboard.id
        })
        this.props.history.push('/dashboard?dash='+dashboard.id)
    }

    clearSelectedDashboard = () => {
        this.props.setSelectedDashboard(null)
        this.setState({
            selectedDash: null
        })
        this.props.history.push('/dashboard')
    }

    render() {
        const { dashboards, selectedDash, loading } = this.state
        return (
            <div className="row">
                <div className="col-12">
                    { selectedDash ? <DashboardShow clearSelectedDashboard={this.clearSelectedDashboard} dashboardData={this.props.selectedDashboard} /> : null }
                    { selectedDash ? null : <DashboardSelect loading={loading} dashboards={dashboards} selectHandler={this.selectDashboard} /> }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany,
        selectedDashboard: state.user.selectedDashboard
    }
}

const mapDispactToProps = dispatch => {
    return {
        setSelectedDashboard: (dashboard) => dispatch({ type: SET_SELECTED_DASHBOARD, payload: dashboard })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(withTranslation()(DashboardIndex))