export const SensorIsOnline = (lastSeenDate, minsInPast = 20) => {
    let deviceDate = new Date(Date.parse(lastSeenDate))
    let dateInPast = new Date()
    dateInPast.setMinutes(dateInPast.getMinutes() - minsInPast)

    if (dateInPast < deviceDate && lastSeenDate) {
        // Online
        return true
    } else {
        // Offline
        return false
    }
}
