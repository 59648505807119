import { EcholoApi, Headers } from '../config'

export const GetAllConditions = async (application, ruleId) => {
    try {
        const conditions = await EcholoApi.get('/rules/' + ruleId + '/conditions', Headers(application))
        return conditions.data
    } catch (error) {
        console.log(error)
        return false
    }
}

export const CreateCondition = async (application, conditionData) => {
    try {
        const ruleId = conditionData.ruleId
        delete conditionData.ruleId
        const condition = await EcholoApi.post('/rules/' + ruleId + '/conditions', conditionData, Headers(application))
        conditionData.ruleId = ruleId
        return condition.data
    } catch (error) {
        console.log(error)
        return false
    }
}

export const RemoveCondition = async (application, condition) => {
    try {
        await EcholoApi.delete('/rules/' + condition.ruleId + '/conditions/' + condition.conditionId, Headers(application))
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}
