import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'

import { Item } from '../../Controllers'

import ItemForm from '../../Components/Item/ItemForm'

class ItemEdit extends Component {

    state = {
        item: null
    }

    fetchItem = async () => {
        let item = await Item.Find(this.props.currentCompany, this.props.match.params.id)
        item.sensors = item.sensors.items
        
        let tags = []
        item.tags.items.forEach(tagItem => {
            tags.push(tagItem.tag.name)
        })

        this.setState({
            item: { ...item, tags: tags }
        })
    }

    componentDidMount = () => {
        this.fetchItem()
    }

    updateHandler = async (item) => {
        let updatedData = await Item.Update(this.props.currentCompany, this.props.match.params.id, item)
        console.log("updatedData", updatedData)

        if (updatedData) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Saved!'
                }
            })
            this.props.history.push('/item')
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue saving this.'
                }
            })
        }
    }

    render() {
        const { currentCompany } = this.props
        return (
            <div className="row InnerContainer">
                <div className="col-12">
                    <h2>Update {this.state.item ? this.state.item.name : null}</h2>
                </div>

                <div className="col-12">
                    { this.state.item ? <ItemForm handler={this.updateHandler} itemData={this.state.item} currentCompany={currentCompany} /> : null }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(ItemEdit)