import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ProfileForm from '../../Components/Profile/ProfileForm'
import LeftNav from "../../Components/Settings/LeftNav"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

class ProfileNew extends Component {
    render() {
        return (
            <div className="row">
                <LeftNav location={'profiles-index'} currentUser={this.props.currentUser} currentCompany={this.props.currentCompany} />

                <div className="col-10 ml-auto pl-5">
                    <div className="row">
                        <div className="col-12 pl-4">
                            <div className="row InnerContainer">
                                <div className="col-12">
                                    <h2><FontAwesomeIcon icon={['fad', 'binary']} /> Create a new Profile</h2>
                                </div>

                                <div className="col-12">
                                    <section>
                                        <ProfileForm />
                                    </section>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany,
        currentUser: state.user.currentUser
    }
}
export default connect(mapStateToProps)(withTranslation()(ProfileNew))