import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Auth } from 'aws-amplify'
import { Redirect } from 'react-router-dom'

import * as UrlParamHelper from '../../Helpers/UrlParams'

const ResetPassword = (props) => {
    const params = UrlParamHelper.getParams(props.location.search)
    const [userEmail] = useState(params[0].user)
    const [showalert, setShowalert] = useState(null)

    const userState = {
        password: '',
        confirm_password: '',
        code: ''
    }
    const { register, handleSubmit, errors, getValues } = useForm()
    const [userForm, setUserForm] = useState(userState)

    const passwordResetHandler = (userData) => {
        if (userData.password === userData.confirm_password) {
            Auth.forgotPasswordSubmit(userEmail, userData.code, userData.password).then(data => {
                console.log(data)
                // Redirect
                setShowalert(<Redirect to="/" />)
                
            }).catch(err => {
                console.log(err)
                setShowalert(<div className="alert alert-danger" role="alert">
                                I am sorry! There was an issue, please try again. {err.message}
                            </div>)
                
            })
        } else {
            setShowalert(<div className="alert alert-danger" role="alert">
                            I am sorry! There was an issue, please try again.
                        </div>)
        }
    }

    return (
        <div className="row justify-content-md-center">
            <div className="col-12">
                <section>
                    {showalert}
                    <form onSubmit={handleSubmit(passwordResetHandler)}>
                        <div className="form-group">
                            {errors.code && <p className="text-danger">Verification Code is required.</p>}
                            <label htmlFor="code">Verification Code:</label>
                            <input 
                            value={userForm.code}
                            name="code"
                            onChange={e => setUserForm({ ...userForm, code: e.target.value })}
                            placeholder="000000"
                            ref={register({ required: true })}
                            type="text" className="form-control form-control-lg" id="code" />
                        </div>

                        <hr />

                        <div className="form-group">
                            {errors.password && <p className="text-danger">{errors.password.message}</p>}
                            <label htmlFor="password">Password:</label>
                            <input 
                            value={userForm.password}
                            name="password"
                            onChange={e => setUserForm({ ...userForm, password: e.target.value })}
                            placeholder="Password"
                            ref={register({ required: true, validateLength: (value) => {
                                if (value !== "") {
                                    return value.length >= 8 || 'Your password must be at least 8 characters'
                                }
                            } })}
                            type="password" className="form-control form-control-lg" id="password" />
                            <small id="emailHelp" className="form-text text-muted">Your password must be at least 8 characters long with a minimum of one uppercase letter and one special character.</small>
                        </div>

                        <div className="form-group">
                            {errors.confirm_password && <p className="text-danger">{errors.confirm_password.message}</p>}
                            <label htmlFor="confirm_password">Confirm Password:</label>
                            <input 
                            value={userForm.confirm_password}
                            name="confirm_password"
                            onChange={e => setUserForm({ ...userForm, confirm_password: e.target.value })}
                            placeholder="Password"
                            ref={register({ required: true, validate: {
                                matchesPreviousPassword: (value) => {
                                    const { password } = getValues()
                                    return password === value || 'Passwords should match!'
                                },
                                validateLength: (value) => {
                                    if (value !== "") {
                                        return value.length >= 8 || 'Your password must be at least 8 characters'
                                    }
                                }
                            } })}
                            type="password" className="form-control form-control-lg" id="confirm_password" />

                        </div>

                        <div className="row">
                            <div className="col-12">
                                <button type="submit" className="btn btn-outline-primary btn-lg btn-block">Reset Password <FontAwesomeIcon icon={['fad', 'chevron-right']} /></button>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

export default ResetPassword