import React, { useState, useEffect } from 'react'
import { Input, FormControl, InputLabel, InputAdornment, Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { search } from 'ss-search'

const SearchBar = ({ itemToSearch, keysToSearch = ["name"], labelText, handler }) => {
    const [searchText, setSearchText] = useState('')
    const [activeSearch, setActiveSearch] = useState(false)
    const [searchItems, setSearchItems] = useState(itemToSearch)

    // This will watch for updates to itemToSearch and 
    //   update the searchItems so we can search the latest data
    useEffect(() => {
        setSearchItems(itemToSearch)
    }, [itemToSearch])

    const searchHandler = (e) => {
        setActiveSearch(true)
        let searchValue = e.target.value
        setSearchText(searchValue)

        if (searchValue.length >= 2) {
            let results = search(searchItems, keysToSearch, searchValue)
            handler(results)
        }

        if (searchValue.length === 0) {
            clearSearchHandler()
        }
    }

    const clearSearchHandler = () => {
        setSearchText('')
        handler(searchItems)
        setActiveSearch(false)
    }

    return (
        <div className="col-12 SearchBar">
            <section>
                <FormControl className="form-control">
                    <InputLabel htmlFor="SearchBarSearchInput">{labelText}</InputLabel>
                    <Input
                        id="SearchBarSearchInput"
                        value={searchText}
                        onChange={(e) => searchHandler(e)}
                        placeholder="Search..."
                        startAdornment={
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={['fad', 'search']} />
                            </InputAdornment>
                        }
                        />
                </FormControl>
                { activeSearch ?
                <Tooltip title="Clear search results." arrow>
                    <span className="FakeLink MapIndexSearchClear"><FontAwesomeIcon onClick={() => clearSearchHandler()} icon={['fad', 'times-circle']} /></span>
                </Tooltip> : null }
            </section>
        </div>
    )
}

export default SearchBar