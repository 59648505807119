import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import NewRuleModel from '../../Components/Rule/RuleModel'
import LoadingTable from '../../Helpers/Components/LoadingTable'

import { GetAllRules, CreateRule } from '../../Services/Echolo/Rules/Rule'
import { Hub } from "@aws-amplify/core"
class RuleIndex extends Component {
    state = {
        rules: [],
        loading: true,
        showRuleModal: false
    }

    fetchRules = async () => {
        const { currentCompany } = this.props
        const rules = await GetAllRules(currentCompany)
        if (rules.length > 0) {
            this.setState({
                rules: rules,
                loading: false
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }

    saveNewRule = async (newRule) => {
        const { currentCompany } = this.props
        const rule = await CreateRule(currentCompany, newRule)
        if (rule) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success',
                    message: 'Your new Rule has been saved!'
                }
            })
            this.setState({
                loading: true
            })
            await this.fetchRules()
            this.setShowRuleModelHandler(false)
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue creating your new Rule.'
                }
            })
        }
    }

    componentDidMount = async () => {
        await this.fetchRules()
    }

    setShowRuleModelHandler = (status) => {
        this.setState({
            showRuleModal: status
        })
    }

    render() {
        const { rules, loading, showRuleModal } = this.state
        const { t } = this.props
        return (
            <div className="row InnerContainer">
                <div className="col-6">
                    <h2><FontAwesomeIcon icon="fa-duotone fa-code-branch" /> Rules</h2>
                </div>
                <div className="col-6 text-right">
                    <button onClick={() => this.setShowRuleModelHandler(true)} className={'btn btn-outline-primary'}><FontAwesomeIcon icon={['fad', 'plus']} /> New Rule</button>
                </div>

                <div className="col-12 mt-4">
                    { loading ? <section><LoadingTable /></section> :
                        <>
                            { rules.length > 0 ?
                            <section>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Interval</th>
                                            <th scope="col"># Conditions</th>
                                            <th scope="col"># Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rules.map(rule => (
                                            <tr key={rule.ruleId}>
                                                <td><Link to={`/rule/${rule.ruleId}/view`}><FontAwesomeIcon icon="fa-duotone fa-code-branch" /> {rule.name}</Link></td>
                                                <td><span className="badge badge-primary">{rule.interval}</span></td>
                                                <td>{ rule.conditions ? rule.conditions.length : '0' }</td>
                                                <td>{ rule.actions ? rule.actions.length : '0' }</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </section>
                                :
                                    <div className={'row justify-content-md-center'}>
                                        <div className={'col-8'}>
                                            <section>
                                                <div className={'row justify-content-md-center'}>
                                                    <div className={'col-12 p-3'}>
                                                        <h3><FontAwesomeIcon icon="fa-duotone fa-code-branch" /> No Rules Yet!</h3>
                                                        <p>
                                                            Rules are ways to find and collect data segments; within this
                                                            rule, you can add special conditions that allow you to narrow
                                                            down the massive amount of data flowing into your deployment.
                                                            Once you have segmented your data, you can connect to services
                                                            like <strong>IFTTT, MySQL, Redlist, and more</strong> to further store or process your data.
                                                        </p>
                                                    </div>
                                                    <div className={'col-8'}>
                                                        <h4><FontAwesomeIcon icon="fa-duotone fa-grid-2-plus" /> Creating your first Rule</h4>
                                                        <p>Creating a new Rule is easy! However, below are a few things you should think about and gather before starting.</p>
                                                        <ul>
                                                            <li><strong>Rule:</strong> How often will this Rule need to run? Every 15 minutes or just once an hour?</li>
                                                            <li><strong>Condition:</strong> How should I filter all of my data? Am I only looking for devices reporting a humidity reading of more than 65%?</li>
                                                            <li><strong>Action:</strong> Once I have my data filtered, where should it be sent? If you need it shipped to MySQL, you may want to get your host, username, password, and database name before starting.</li>
                                                        </ul>
                                                        <button onClick={() => this.setShowRuleModelHandler(true)} className={'btn btn-primary btn-block'}><FontAwesomeIcon icon={['fad', 'plus']} /> Create your First Rule!</button>
                                                        <hr />

                                                        <h5>Are you looking for more integrations?</h5>
                                                        <p>Check out our integration with IFTTT; you can connect your data with over 700 applications and services!</p>
                                                        <a target={'_blank'} href={'https://ifttt.com/echolo_iot'}><img src={'/images/IFTTT-White-EcholoLogo-badge.png'}  alt={'IFTTT'}/></a>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                            }
                        </>
                    }

                </div>
                <NewRuleModel saveRule={this.saveNewRule} showModel={showRuleModal} setShowModel={this.setShowRuleModelHandler} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(withTranslation()(RuleIndex))