import React, { useState } from 'react'
import { TextField, Button } from '@material-ui/core'

const UserPasswordForm = ({ handler }) => {
    const initPassword = {
        currentPW: '',
        newPW: '',
        newPWRetype: ''
    }
    const [userPassword, setUserPassword] = useState(initPassword)

    const userFormHandler = (event) => {
        event.preventDefault()
        let status = handler(userPassword)
        if (status) {
            setUserPassword(initPassword)
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={userFormHandler}>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="form-group">
                                <TextField type="password" required value={userPassword.currentPW} onChange={(e) => setUserPassword({ ...userPassword, currentPW: e.target.value })} className="form-control" label="Current Password" variant="outlined" />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col">
                            <div className="form-group">
                                <TextField type="password" required value={userPassword.newPW} onChange={(e) => setUserPassword({ ...userPassword, newPW: e.target.value })} className="form-control" label="New Password" variant="outlined" />
                            </div>
                        </div>

                        <div className="col">
                            <div className="form-group">
                                <TextField type="password" required value={userPassword.newPWRetype} onChange={(e) => setUserPassword({ ...userPassword, newPWRetype: e.target.value })} className="form-control" label="Retype Password" variant="outlined" />
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-3 text-right">
                            <Button type="submit" variant="outlined" color="primary">Save</Button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default UserPasswordForm