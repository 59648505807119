import { EcholoApi, Headers } from '../config'
import { GetProfileById } from '../Profile'

export const GetAllRules = async (application) => {
    try {
        const rulesData = []
        const rules = await EcholoApi.get('/rules', Headers(application))
        return rules.data
    } catch (error) {
        console.log(error)
        return false
    }
}

export const GetRule = async (application, ruleId) => {
    try {
        const rule = await EcholoApi.get('/rules/' + ruleId, Headers(application))
        return rule.data
    } catch (error) {
        console.log(error)
        return false
    }
}

export const GetRuleWithProfiles = async (application, ruleId) => {
    try {
        const rule = await EcholoApi.get('/rules/' + ruleId, Headers(application))
        if ('actions' in rule.data) {
            let actionsData = []
            for (const action of rule.data.actions) {
                const profile = await GetProfileById(application, action.serviceId)
                actionsData.push({ ...action, profile: profile })
            }
            rule.data.actions = actionsData
        }
        return rule.data
    } catch (error) {
        console.log(error)
        return false
    }
}

export const CreateRule = async (application, ruleData) => {
    try {
        const rule = await EcholoApi.post('/rules/', ruleData, Headers(application))
        return rule.data
    } catch (error) {
        console.log(error)
        return false
    }
}

export const UpdateRule = async (application, ruleId, ruleData) => {
    try {
        const rule = await EcholoApi.put('/rules/' + ruleId, ruleData, Headers(application))
        console.log('UpdateRule', rule)
        return rule.data
    } catch (error) {
        console.log(error)
        return false
    }
}
