import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AppFooter = () => {
    return (
        <div className="AppFooter">
            <p>Hand-crafted & Designed by Echolo, LLC in the USA <FontAwesomeIcon icon={['fad', 'flag-usa']} /> Explorer v1.0.1 <span className="badge badge-primary">BETA</span></p>
        </div>
    )
}

export default AppFooter