import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import { withTranslation } from 'react-i18next'

import { Item } from '../../Controllers'

import ItemForm from '../../Components/Item/ItemForm'

class ItemNew extends Component {

    newItemHandler = async (item) => {
        let newItem = await Item.Create(this.props.currentCompany, item)
        if (newItem) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Saved!'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue saving this.'
                }
            })
        }
    }

    render() {
        const { currentCompany } = this.props
        return (
            <div className="row InnerContainer">
                <div className="col-12">
                    <ItemForm handler={this.newItemHandler} currentCompany={currentCompany} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(withTranslation()(ItemNew))