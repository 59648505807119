import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ConditionForm from '../../Components/Rule/ConditionForm'
class ConditionNew extends Component {

    render() {
        return (
            <div className="row InnerContainer">
                <div className="col-12">
                    <h2><FontAwesomeIcon icon={['fad', 'not-equal']} /> Create a new Condition</h2>
                </div>

                <div className="col-12">
                    <section>
                        <ConditionForm />
                    </section>
                </div>
            </div>
        )
    }
}

export default ConditionNew