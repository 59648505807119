import { EcholoApi } from './config'
import { Company } from '../../Controllers'
export const RenewToken = async (application) => {
    console.log("APPLICATION.RenewToken", application)
    try {
        let tokenData = await EcholoApi.post('/applications/token', JSON.stringify({}), TokenHeaders(application))
        if (tokenData.data.error) throw new Error("ERROR: " + tokenData.data.error)
        Company.Update(application, {token: tokenData.data.token})

        console.log("APPLICATION.RenewToken RENEWED", tokenData.data)
        return tokenData.data
    } catch (e) {
        return e
    }
}

export const GetToken = async (application) => {
    try {
        let tokenData = await EcholoApi.get('/applications/token', TokenHeaders(application))
        if (tokenData.data.error) throw new Error("ERROR: " + tokenData.data.error)
        Company.Update(application, {token: tokenData.data.token})
        return tokenData.data.token
    } catch (e) {
        console.warn("Failed to GET TOKEN from API", e)
        return false
    }
}

export const GetLocalToken = async (application) => {
    try {
        // Get The token from the DB
        return (await Company.Find(application)).token
    } catch (e) {
        return e
    }
}

export const Activate = async (appId) => {
    try {
        let app = await EcholoApi.post('/applications/activate', JSON.stringify({ appId: appId }))
        if (app.data.error) throw new Error(app.data.error)
        return app.data
    } catch (e) {
        console.warn("Failed to activate application: ", appId)
        return e
    }
}

export const Get = async (application, retry = 0) => {
    try {
        let app = await EcholoApi.get('/applications', Headers(application))
        if (app.data.error) throw new Error(app.data.error)
        return app
    } catch (e) {
        return false
    }
}

export const Create = async (application) => {
    let data = { name: application.name }
    try {
        let app = await EcholoApi.post('/applications', JSON.stringify(data))
        if (app.data.error) throw new Error(app.data.error)
        return app
    } catch (e) {
        return e
    }
}

export const Update = async (application, updatedData, retry = 0) => {
    let data = { name: updatedData.name }
    try {
        let app = await EcholoApi.put('/applications', JSON.stringify(data), Headers(application))
        if (app.data.error) throw new Error(app.data.error)
        return app
    } catch (e) {
        return false
    }
}

// Private
const Headers = (application) => {
    return {
        headers: {
            'appId': application.appId,
            'token': application.token || ''
        }
    }
}

const TokenHeaders = (application) => {
    return {
        headers: {
            'appId': application.appId,
            'secret': application.appSecret
        }
    }
}
