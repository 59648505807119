import React from "react"
import { capitalize } from '../../Helpers/TextHelpers'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ProfileMethods = ({ methods }) => {
    const buildAuth = (auth) => {
        let keys = Object.keys(auth)
        return keys.map((key) => {
            return <li className={'list-group-item'} key={key}><strong>{key}:</strong> <code>{auth[key]}</code></li>
        })
    }

    return (
        <>
        { methods.map((method) => (
                <section key={method.sendMethod} className={'col m-1'}>
                    <div className="row">
                        <div className="col-12"><h4>Delivery Method: <span className="badge badge-primary ChipTag">{capitalize(method.sendMethod)}</span></h4></div>
                        <div className="col-12">
                            {method.sendMethod === 'echolo' ?
                                <ul className={'list-group'}>
                                    <li className={'list-group-item'}><strong>Telemetry:</strong> <code>In Network Delivery</code></li>
                                    <li className={'list-group-item'}><strong>Explorer:</strong> <code>In Network Delivery</code></li>
                                </ul>
                                : null}
                            {method.auth ?
                                <>
                                    <p><strong>Primary Authentication</strong></p>
                                    <ul className={'list-group'}>
                                        {
                                            buildAuth(method.auth)
                                        }
                                    </ul>
                                </>
                            : null}

                            {method.auth2 ?
                                <>
                                    <p><strong>Secondary Authentication</strong></p>
                                    <ul className={'list-group'}>
                                        {
                                            buildAuth(method.auth2)
                                        }
                                    </ul>
                                </>
                                : null}
                        </div>
                    </div>
                </section>
            ))}
        </>
    )
}

export default ProfileMethods
