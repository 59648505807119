import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Item } from '../../../Controllers'
import { Device } from '../../../Services/Echolo'

import SensorInfoModel from '../../Sensor/SensorInfoModel'

const List = ({ currentCompany, widgetData }) => {
    const [widget] = useState(widgetData)
    const [sensors, setSensors] = useState([])
    const [selectedSensor, setSelectedSensor] = useState(null)
    const [showModel, setShowModel] = useState(false)

    const fetchSensors = async () => {
        let item = await Item.Find(currentCompany, widget.item.id)
        setSensors(item.sensors.items)
    }

    useEffect(() => {
        console.log(widget)
        fetchSensors()
    }, [])

    const selectSensorHandler = async (sid) => {
        setShowModel(true)
        let device = await Device.Find(currentCompany, sid)
        setSelectedSensor(device)
    }

    return (
        <>
        {widget ?
        <div className="row m-1 WidgetText">
            <div className="col-12">
                <div className="row WidgetBox rounded">
                    <div className="col-12 WidgetListHeader">
                        <h4>{widget.name} <small>{widget.item.name}</small></h4>
                    </div>
                    <div className="col-12 WidgetListTable">
                        <table className="table table-sm table-hover">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>SID</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { sensors.map(sensor => (
                                    <tr key={sensor.id}>
                                        <td>{sensor.name}</td>
                                        <td>{sensor.sid}</td>
                                        <td><FontAwesomeIcon onClick={() => selectSensorHandler(sensor.sid)} className="FakeLink" icon={['fad', 'info-square']} /></td>
                                    </tr>
                                )) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <SensorInfoModel sensor={selectedSensor} showModel={showModel} setShowModel={setShowModel} />
        </div> : null }
        </>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(List)