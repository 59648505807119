import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const GeoFenceBoxView = ({ geoFenceData }) => {
    const [geoFences, setGeoFences] = useState([])

    const buildGeoFenceImage = () => {
        let updatedGeoFences = []
        geoFenceData.forEach(fence => {
            let img = "https://maps.googleapis.com/maps/api/staticmap?path=color:0x2b1869%7Cweight:0%7Cfillcolor:0x2b186982"
            let paths = JSON.parse(fence.paths)
            let imgPaths = ""
            paths.forEach(path => {
                imgPaths += "%7C" + path.lat + "," + path.lng
            })

            img = img + imgPaths + "&size=300x300&maptype=roadmap&key=AIzaSyBx6xJVPPzXuleTLXm7ot7kn48FgThbOvs"
            fence.image = img
            updatedGeoFences.push(fence)
        })
        setGeoFences(updatedGeoFences)
    }

    useEffect(() => {
        if (geoFenceData) {
            buildGeoFenceImage()
        }
    }, [geoFenceData])

    return (
        <div className="row row-cols-4">
            
            {geoFences.map(geoFence => (
                <div key={geoFence.id} className="col">
                    <section>
                        <div className="card">
                            <img src={geoFence.image} alt="GeoFence Map" />
                            <div className="card-body">
                                <h5 className="card-title pb-0 mb-0">{geoFence.name}</h5>
                                <p>Dashboard: <Link to={`/dashboard?dash=${geoFence.dashboard.id}`}>{geoFence.dashboard.name}</Link></p>
                                <p className="text-center px-0 mb-0"><Link to={`/map/geofence/${geoFence.id}`} className="btn btn-dark"><FontAwesomeIcon icon={['fad', 'draw-polygon']} /> Manage</Link></p>
                            </div>
                        </div>
                    </section>
                </div>
            ))}

        </div>
    )
}

export default GeoFenceBoxView