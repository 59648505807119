import { API, graphqlOperation } from 'aws-amplify'

import { createGeoFence, updateGeoFence, deleteGeoFence } from '../graphql/mutations'
import { listGeoFences, getGeoFence } from '../graphql/queries'

export const Create = async (company, GeoFence) => {
    const input = {
        geoFenceCompanyId: company.id,
        geoFenceDashboardId: GeoFence.dashboard,
        name: GeoFence.name,
        paths: GeoFence.paths,
        center: GeoFence.center
    }

    try {
        return (await API.graphql(graphqlOperation(createGeoFence, { input }))).data.createGeoFence
    } catch (e) {
        console.warn("Failed to create GeoFence", e)
        return false
    }

}

export const Update = async (updatedGeoFence) => {
    let input = {
        name: updatedGeoFence.name,
        id: updatedGeoFence.id
    }

    try {
        return (await API.graphql(graphqlOperation(updateGeoFence, { input }))).data.updateGeoFence
    } catch (e) {
        console.warn("Failed to update this GeoFence", e)
        return false
    }
}

export const Delete = async (GeoFence) => {
    try {
        return (await API.graphql(graphqlOperation(deleteGeoFence, { input: { id: GeoFence.id } }))).data.deleteGeoFence
    } catch (e) {
        console.warn("Failed to delete this GeoFence", e)
        return false
    }
}

export const AllByDashboard = async (dashboardId) => {
    try {
        return (await API.graphql(graphqlOperation(listGeoFences))).data.listGeoFences.items.filter(gf => gf.dashboard.id === dashboardId)
    } catch (e) {
        console.warn("Failed to find all GeoFences", e)
        return false
    }
}

export const All = async (company) => {
    try {
        return (await API.graphql(graphqlOperation(listGeoFences))).data.listGeoFences.items.filter(gf => gf.company.id === company.id)
    } catch (e) {
        console.warn("Failed to find all GeoFences", e)
        return false
    }
}

export const Find = async (company, geoFenceId) => {
    try {
        const GeoFence = (await API.graphql(graphqlOperation(getGeoFence, { id: geoFenceId }))).data.getGeoFence
        if (GeoFence.company.id !== company.id) throw new Error("You do not have access to this GeoFence")
        return GeoFence
    } catch (e) {
        console.warn("Failed to find this GeoFence", e)
        return false
    }
}
