import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Hub } from '@aws-amplify/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Widget } from '../../../Controllers'

const DeleteWidgetModel = ({ widgetData, showModel, setShowModel }) => {
    const [widget] = useState(widgetData)

    const deleteWidget = async () => {
        let deleated = await Widget.Delete(widget)
        if (deleated) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Your new Widget has been delated.'
                }
            })
            setShowModel(false)
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue deleteing your widget. Please try again.'
                }
            })
        }
    }

    return (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            
            <Modal.Header closeButton>
                <Modal.Title>Delete Widget</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <p>Are you sure you want to delete this widget?</p>
                        <ul>
                            <li>Name: <strong>{widget.name}</strong></li>
                            <li>For Sensor: <strong>{widget.sensor.sid}</strong></li>
                        </ul>    
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn" onClick={() => setShowModel(false)}>
                    Close
                </button>
                <button onClick={() => deleteWidget()} type="submit" className="btn btn-danger">
                    <FontAwesomeIcon icon={['fad', 'trash']} /> Delete Widget
                </button>
            </Modal.Footer>
            
        </Modal>
    )
}

export default DeleteWidgetModel