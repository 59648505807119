import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { Sensor } from '../../Controllers'

import SensorTable from '../../Components/Sensor/SensorTable'
import Loading from '../../Helpers/Components/Loading'

class SensorIndex extends Component {
    state = {
        sensors: [],
        loadingSensors: true
    }

    fetchSensors = async () => {
        let data = await Sensor.All(this.props.currentCompany)
        this.setState({
            sensors: data,
            loadingSensors: false
        })
    }

    componentDidMount = () => {
        this.fetchSensors()
    }

    render() {
        let { sensors, loadingSensors } = this.state
        return (
            <div className="row InnerContainer">
                <div className="col-6 mb-3">
                    <h2><FontAwesomeIcon icon={['fad', 'microchip']} /> Sensors</h2>
                </div>
                <div className="col-6 mb-3 text-right">
                    <Link to="/sensor/new" className="btn btn-outline-primary"><FontAwesomeIcon icon={['fad', 'plus']} /> Activate a new Sensor</Link>
                </div>

                <div className="col-12">
                    <section>
                        { loadingSensors ? <Loading isOpen={true} /> : <SensorTable sensors={sensors} /> }
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(SensorIndex)