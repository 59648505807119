/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      appId
      appSecret
      token
      users {
        items {
          id
          level
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      appId
      appSecret
      token
      users {
        items {
          id
          level
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      appId
      appSecret
      token
      users {
        items {
          id
          level
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const createDashboard = /* GraphQL */ `
  mutation CreateDashboard(
    $input: CreateDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    createDashboard(input: $input, condition: $condition) {
      id
      name
      type
      itemType
      item {
        id
        name
      }

      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }

      user {
        id
        email
        first_name
        last_name
        cognito_id
        status
        companies {
          nextToken
        }
        dashboards {
          nextToken
        }
        createdAt
        updatedAt
      }

      tags {
        items {
          id
          tag {
            id
            name
            items {
              items {
                id
                item {
                  id
                  name
                  type
                  sn
                  customId
                  year
                  tags {
                    items {
                      tag {
                        id
                        name
                        type
                      }
                    }
                  }
                  sensors {
                    items {
                      id
                      sid
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }

      widgets {
        items {
          id
          name
          type
          sensor {
            id
            sid
            name
          }
          item {
            id
            name
            type
            sn
            customId
            year
            sensors {

              items {
                id
                sid
                name
              }
            }
          }
          sensorKey
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDashboard = /* GraphQL */ `
  mutation UpdateDashboard(
    $input: UpdateDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    updateDashboard(input: $input, condition: $condition) {
      id
      name
      type
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      user {
        id
        email
        first_name
        last_name
        cognito_id
        companies {
          nextToken
        }
        dashboards {
          nextToken
        }
        createdAt
        updatedAt
      }
      widgets {
        items {
          id
          name
          type
          sensorKey
          createdAt
          updatedAt
        }
        nextToken
      }
      item {
        id
        name
        sn
        customId
        type
        year
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      itemType
      createdAt
      updatedAt
    }
  }
`;
export const deleteDashboard = /* GraphQL */ `
  mutation DeleteDashboard(
    $input: DeleteDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    deleteDashboard(input: $input, condition: $condition) {
      id
      name
      type
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      user {
        id
        email
        first_name
        last_name
        cognito_id
        companies {
          nextToken
        }
        dashboards {
          nextToken
        }
        createdAt
        updatedAt
      }
      widgets {
        items {
          id
          name
          type
          sensorKey
          createdAt
          updatedAt
        }
        nextToken
      }
      item {
        id
        name
        sn
        customId
        type
        year
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      itemType
      createdAt
      updatedAt
    }
  }
`;
export const createDashboardTag = /* GraphQL */ `
  mutation CreateDashboardTag(
    $input: CreateDashboardTagInput!
    $condition: ModelDashboardTagConditionInput
  ) {
    createDashboardTag(input: $input, condition: $condition) {
      id
      dashboard {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        user {
          id
          email
          first_name
          last_name
          cognito_id
          createdAt
          updatedAt
        }
        widgets {
          nextToken
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        itemType
        createdAt
        updatedAt
      }
      tag {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDashboardTag = /* GraphQL */ `
  mutation UpdateDashboardTag(
    $input: UpdateDashboardTagInput!
    $condition: ModelDashboardTagConditionInput
  ) {
    updateDashboardTag(input: $input, condition: $condition) {
      id
      dashboard {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        user {
          id
          email
          first_name
          last_name
          cognito_id
          createdAt
          updatedAt
        }
        widgets {
          nextToken
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        itemType
        createdAt
        updatedAt
      }
      tag {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDashboardTag = /* GraphQL */ `
  mutation DeleteDashboardTag(
    $input: DeleteDashboardTagInput!
    $condition: ModelDashboardTagConditionInput
  ) {
    deleteDashboardTag(input: $input, condition: $condition) {
      id
      dashboard {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        user {
          id
          email
          first_name
          last_name
          cognito_id
          createdAt
          updatedAt
        }
        widgets {
          nextToken
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        itemType
        createdAt
        updatedAt
      }
      tag {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createWidget = /* GraphQL */ `
  mutation CreateWidget(
    $input: CreateWidgetInput!
    $condition: ModelWidgetConditionInput
  ) {
    createWidget(input: $input, condition: $condition) {
      id
      name
      type
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      item {
        id
        name
        sn
        customId
        type
        year
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensorKey
      dashboard {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        user {
          id
          email
          first_name
          last_name
          cognito_id
          createdAt
          updatedAt
        }
        widgets {
          nextToken
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        itemType
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateWidget = /* GraphQL */ `
  mutation UpdateWidget(
    $input: UpdateWidgetInput!
    $condition: ModelWidgetConditionInput
  ) {
    updateWidget(input: $input, condition: $condition) {
      id
      name
      type
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      item {
        id
        name
        sn
        customId
        type
        year
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensorKey
      dashboard {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        user {
          id
          email
          first_name
          last_name
          cognito_id
          createdAt
          updatedAt
        }
        widgets {
          nextToken
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        itemType
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteWidget = /* GraphQL */ `
  mutation DeleteWidget(
    $input: DeleteWidgetInput!
    $condition: ModelWidgetConditionInput
  ) {
    deleteWidget(input: $input, condition: $condition) {
      id
      name
      type
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      item {
        id
        name
        sn
        customId
        type
        year
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensorKey
      dashboard {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        user {
          id
          email
          first_name
          last_name
          cognito_id
          createdAt
          updatedAt
        }
        widgets {
          nextToken
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        itemType
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      first_name
      last_name
      cognito_id
      companies {
        items {
          id
          level
          createdAt
          updatedAt
        }
        nextToken
      }
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      phone_number
      notify_email
      notify_sms
      first_name
      last_name
      cognito_id
      status
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      companies {
        items {
          id
          level
          status
          company {
            id
            name
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      first_name
      last_name
      cognito_id
      companies {
        items {
          id
          level
          createdAt
          updatedAt
        }
        nextToken
      }
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserCompanies = /* GraphQL */ `
  mutation CreateUserCompanies(
    $input: CreateUserCompaniesInput!
    $condition: ModelUserCompaniesConditionInput
  ) {
    createUserCompanies(input: $input, condition: $condition) {
      id
      user {
        id
        email
        first_name
        last_name
        cognito_id
        companies {
          nextToken
        }
        dashboards {
          nextToken
        }
        createdAt
        updatedAt
      }
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      level
      createdAt
      updatedAt
    }
  }
`;
export const updateUserCompanies = /* GraphQL */ `
  mutation UpdateUserCompanies(
    $input: UpdateUserCompaniesInput!
    $condition: ModelUserCompaniesConditionInput
  ) {
    updateUserCompanies(input: $input, condition: $condition) {
      id
      user {
        id
        email
        first_name
        last_name
        cognito_id
        companies {
          nextToken
        }
        dashboards {
          nextToken
        }
        createdAt
        updatedAt
      }
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      level
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserCompanies = /* GraphQL */ `
  mutation DeleteUserCompanies(
    $input: DeleteUserCompaniesInput!
    $condition: ModelUserCompaniesConditionInput
  ) {
    deleteUserCompanies(input: $input, condition: $condition) {
      id
      user {
        id
        email
        first_name
        last_name
        cognito_id
        companies {
          nextToken
        }
        dashboards {
          nextToken
        }
        createdAt
        updatedAt
      }
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      level
      createdAt
      updatedAt
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      name
      sn
      customId
      type
      year
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      name
      sn
      customId
      type
      year
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      name
      sn
      customId
      type
      year
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      name
      type
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      items {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      name
      type
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      items {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      name
      type
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      items {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createItemTags = /* GraphQL */ `
  mutation CreateItemTags(
    $input: CreateItemTagsInput!
    $condition: ModelItemTagsConditionInput
  ) {
    createItemTags(input: $input, condition: $condition) {
      id
      item {
        id
        name
        sn
        customId
        type
        year
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateItemTags = /* GraphQL */ `
  mutation UpdateItemTags(
    $input: UpdateItemTagsInput!
    $condition: ModelItemTagsConditionInput
  ) {
    updateItemTags(input: $input, condition: $condition) {
      id
      item {
        id
        name
        sn
        customId
        type
        year
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteItemTags = /* GraphQL */ `
  mutation DeleteItemTags(
    $input: DeleteItemTagsInput!
    $condition: ModelItemTagsConditionInput
  ) {
    deleteItemTags(input: $input, condition: $condition) {
      id
      item {
        id
        name
        sn
        customId
        type
        year
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSensorTags = /* GraphQL */ `
  mutation CreateSensorTags(
    $input: CreateSensorTagsInput!
    $condition: ModelSensorTagsConditionInput
  ) {
    createSensorTags(input: $input, condition: $condition) {
      id
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSensorTags = /* GraphQL */ `
  mutation UpdateSensorTags(
    $input: UpdateSensorTagsInput!
    $condition: ModelSensorTagsConditionInput
  ) {
    updateSensorTags(input: $input, condition: $condition) {
      id
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSensorTags = /* GraphQL */ `
  mutation DeleteSensorTags(
    $input: DeleteSensorTagsInput!
    $condition: ModelSensorTagsConditionInput
  ) {
    deleteSensorTags(input: $input, condition: $condition) {
      id
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        type
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSensor = /* GraphQL */ `
  mutation CreateSensor(
    $input: CreateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    createSensor(input: $input, condition: $condition) {
      id
      sid
      sn
      customId
      class
      type
      sku
      name
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      item {
        id
        name
        sn
        customId
        type
        year
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSensor = /* GraphQL */ `
  mutation UpdateSensor(
    $input: UpdateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    updateSensor(input: $input, condition: $condition) {
      id
      sid
      sn
      customId
      class
      type
      sku
      name
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      item {
        id
        name
        sn
        customId
        type
        year
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSensor = /* GraphQL */ `
  mutation DeleteSensor(
    $input: DeleteSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    deleteSensor(input: $input, condition: $condition) {
      id
      sid
      sn
      customId
      class
      type
      sku
      name
      company {
        id
        name
        appId
        appSecret
        token
        users {
          nextToken
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        dashboards {
          nextToken
        }
        type
        createdAt
        updatedAt
      }
      item {
        id
        name
        sn
        customId
        type
        year
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRule = /* GraphQL */ `
  mutation UpdateRule(
    $input: UpdateRuleInput!
    $condition: ModelRuleConditionInput
  ) {
    updateRule(input: $input, condition: $condition) {
      id
      company {
        id
        name
        createdAt
        updatedAt
      }
      name
      type
      tags {
        items {
          id
          tag {
            id
            name
            type
            items {
              items {
                id
                item {
                  id
                  name
                  customId
                  sn
                  type
                  year
                }
              }
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      item {
        id
        name
        sn
        customId
        type
        year
        sensors {
          items {
            id
            sid
            name
            sn
            customId
            class
            type
            sku
            tags {
              items {
                tag {
                  id
                  name
                }
              }
            }
          }
          nextToken
        }
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      itemType
      conditions {
        items {
          id
          condition {
            id
            operator
            metric
            value
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          action {
            id
            name
            type
            tag {
              id
              name
              type
            }
            users {
              items {
                user {
                  id
                  first_name
                  last_name
                }
              }
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSavedResults = /* GraphQL */ `
  mutation UpdateSavedResults(
    $input: UpdateSavedResultsInput!
    $condition: ModelSavedResultsConditionInput
  ) {
    updateSavedResults(input: $input, condition: $condition) {
      id
      ruleId
      resultId
      resultCount
      name
      createdAt
      updatedAt
      company {
        id
      }
      tags {
        items {
          id
          tag {
            id
            name
            type
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
