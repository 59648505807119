import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'

const AreYouSure = ({ show = false, Confirm, Cancel, Title, Message }) => {
    const [showModel, setShowModel] = useState(show)

    useEffect(() => {
        setShowModel(show)
    }, [show])

    return (
        <Modal show={showModel} onHide={() => Cancel()}>
            <Modal.Header closeButton>
                <Modal.Title>{Title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{Message}</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn" onClick={() => Cancel()}>
                    Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={() => Confirm()}>
                    Confirm
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default AreYouSure