import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import { SET_SESSION_USERNAME } from '../../Store/Actions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ConfirmRegister = (props) => {
    const { sessionUsername, setSessionUsername } = props
    
    const confirmCode = {
        code: ''
    }
    const [code, setCode] = useState(confirmCode)
    const [redirectToLogin, setRedirectToLogin] = useState(false)
    const { register, handleSubmit, errors } = useForm()

    const confirmHandler = async (formData) => {
        try {
            let reply = await Auth.confirmSignUp(sessionUsername, formData.code)
            if (reply) {
                Hub.dispatch('Alert', {
                    event: 'show',
                    data: {
                        type: 'success', 
                        message: 'Account has been verified!'
                    }
                })
            }
            setRedirectToLogin(true)
            setSessionUsername(null)
        } catch (error) {
            setCode({code: ''})
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: `I am sorry! There was an issue with your Verification Code.`
                }
            })
        }

    }

    const resendHandler = async () => {

        try {
            let reply = await Auth.resendSignUp(sessionUsername)
            if (reply) {
                Hub.dispatch('Alert', {
                    event: 'show',
                    data: {
                        type: 'success', 
                        message: `Your Verification Code has been resent to: ${sessionUsername}.`
                    }
                })
            }
        } catch (err) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: `I am sorry! There was an issue in resending your Verification Code. ${err.message}`
                }
            })
        }
    }

    let redirect = null
    if (redirectToLogin) redirect = <Redirect to="/" />

    return (
        <div className="row">
            <div className="col-12">
                <section>
                    <div className="LoginForm">
                        {redirect}
                        {sessionUsername ? 
                            <>
                                <h3 className="text-center text-dark">Confirm your account</h3>
                                <p className="text-center text-subtext">Check your email, we sent you a verification code.</p>
                                <form onSubmit={handleSubmit(confirmHandler)}>
                                    {errors.code && <p className="text-danger">Verification Code is required.</p>}
                                    <div className="form-group">
                                        <label htmlFor="code">Verification Code:</label>
                                        <input 
                                        value={code.code}
                                        name="code"
                                        onChange={e => setCode({ ...code, code: e.target.value })}
                                        placeholder="000000"
                                        ref={register({ required: true })}
                                        type="text" className="form-control" id="code" />
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                        <button type="submit" className="btn btn-outline-primary btn-lg btn-block">Verify <FontAwesomeIcon icon={['fad', 'chevron-right']} /></button>
                                        </div>
                                    </div>
                                </form>

                                <div className="row">
                                    <div className="col-12">
                                        <hr />
                                        <p className="text-size-medium">Did you not get your verification? Make sure to allow emails sent from <strong>support@echolo.io</strong>. If you still can not find it <span onClick={() => resendHandler()} className="FakeLink">try sending the verification code again</span>.</p>
                                    </div>
                                </div>
                            </>
                        : <Redirect to="/register" />}
                    </div>
                </section>
            </div>
        </div>
    )

}

const mapStateToProps = state => {
    return {
        sessionUsername: state.user.sessionUsername
    }
}

const mapDispactToProps = dispatch => {
    return {
        setSessionUsername: (username) => dispatch({ type: SET_SESSION_USERNAME, payload: username })
    }
}
export default connect(mapStateToProps, mapDispactToProps)(ConfirmRegister)