import React, { Component } from 'react'

import CompaniesTable from '../../Components/Company/CompaniesTable'

class CompanyIndex extends Component {
    render() {
        return (
            <div>
                <CompaniesTable />
            </div>
        )
    }
}

export default CompanyIndex