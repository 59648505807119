import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { createStore, combineReducers } from 'redux'
import { Provider } from 'react-redux'
import { BrowserRouter} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import * as serviceWorker from './Core/serviceWorker'

import App from './App'

import UserReducer from './Store/Reducers/UserReducer'

import { saveState } from './Store/LoadState'

import './Core/i18n'

import 'bootstrap'
import 'bootstrap/dist/js/bootstrap.js'

import FullPageLoading from './Helpers/Components/FullPageLoading'

const mainReducer = combineReducers({
    user: UserReducer
})
const store = createStore(mainReducer)
store.subscribe(() => {
    saveState(store.getState())
})

const app = (
    <Suspense fallback={<FullPageLoading />}>
        <BrowserRouter>
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
    </Suspense>
)

Sentry.init({
    dsn: "https://44a48b109cc24de2b38a7f2f209bd698@o435962.ingest.sentry.io/5681672",
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

ReactDOM.render(
    app,
    document.getElementById('root')
)

serviceWorker.unregister()