import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Action } from '../../Controllers'
import { CreateAction } from '../../Services/Echolo/Rules/Action'

import ActionForm from '../../Components/Rule/ActionForm'

class ActionNew extends Component {

    newActionHandler = async (action) => {
        // const newAction = await CreateAction(this.props.currentCompany, '', action)
        const newAction = false
        console.log("newAction", action)
        if (newAction) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Saved!'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issues saving your new Action, please try again.'
                }
            })
        }
    }

    render() {
        return (
            <div className="row InnerContainer">
                <div className="col-12">
                    <h2><FontAwesomeIcon icon={['fad', 'paper-plane']} /> Create a new Action</h2>
                </div>

                <div className="col-12">
                    <section>
                        <ActionForm handler={this.newActionHandler} />
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(ActionNew)