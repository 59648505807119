import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Hub } from '@aws-amplify/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { GeoFence, Condition } from '../../../Controllers'

import GeoFenceEditForm from '../../../Components/Map/GeoFence/GeoFenceEditForm';

class GeoFenceShow extends Component {
    state = {
        geoFence: null,
        conditions: [],
        loading: true
    }

    buildImagePath = (geofence) => {
        let img = "https://maps.googleapis.com/maps/api/staticmap?path=color:0x2b1869%7Cweight:0%7Cfillcolor:0x2b186982"
        let paths = JSON.parse(geofence.paths)
        let imgPaths = ""
        paths.forEach(path => {
            imgPaths += "%7C" + path.lat + "," + path.lng
        })
        img = img + imgPaths + "&size=600x600&maptype=roadmap&key=AIzaSyBx6xJVPPzXuleTLXm7ot7kn48FgThbOvs"
        return img
    }

    fetchGeoFence = async (geoFenceId) => {
        const { t, currentCompany } = this.props
        let result = await GeoFence.Find(currentCompany, geoFenceId)
        if (result) {
            result.image = this.buildImagePath(result)

            let conditions = await Condition.FindByGeoFence(geoFenceId)
            
            this.setState({
                geoFence: result,
                loading: false,
                conditions: conditions
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue loading this '+t('geofence.title')+', please try reloading this page'
                }
            })
        }
    }

    componentDidMount = () => {
        const { geoFenceId } = this.props
        try {
            this.fetchGeoFence(this.props.match.params.id)
        } catch (e) {
            this.fetchGeoFence(geoFenceId)
        }
    }

    updateGeoFenceHandler = (updatedGeoFence) => {
        updatedGeoFence.image = this.buildImagePath(updatedGeoFence)
        this.setState({
            geoFence: updatedGeoFence
        })
    }

    deleteGeoFenceHandler = () => {
        const { t, onMap, onMapHandler } = this.props
        const { geoFence } = this.state
        let result = GeoFence.Delete(geoFence)
        if (result) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: t('geofence.title') + ' Removed!'
                }
            })
            if (!onMap) {
                this.props.history.push('/map/geofence')
            } else {
                onMapHandler()
            }
        } else  {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue Removing your '+t('geofence.title')+', please try again.'
                }
            })
        }
    }

    render() {
        const { loading, geoFence, conditions } = this.state
        const { t, onMap, onMapHandler } = this.props
        return (
            <div className="row InnerContainer">
                {loading ? <p>loading...</p> : 
                <>
                    <div className="col-10">
                        <h2>{t('geofence.title')}: {geoFence.name}</h2>
                    </div>

                    <div className="col-2 text-right">
                        {onMap ? <FontAwesomeIcon onClick={() => onMapHandler()} className="FakeLink OnMapDashboard_CloseIcon" icon={['fad', 'times']} /> : null }
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <div className="card">
                                    <img src={geoFence.image} alt={geoFence.name} />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="row">
                                    <div className="col-12">
                                        <section>
                                            <h4>Details for {t('geofence.title')}: {geoFence.name}</h4>
                                            <p><small>Used on:</small></p>
                                            <table className="table table-bordered table-sm table-hover">
                                                <tbody>
                                                    <tr>
                                                        <td>Dashboard</td>
                                                        <td><Link to={`/dashboard?dash=${geoFence.dashboard.id}`}>{geoFence.dashboard.name}</Link></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {conditions.length > 0 ?
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4>Alerts Conditions</h4>
                                                    <p>One or more <Link to="/settings?page=rules-conditions">Alert Conditions</Link> use this {t('geofence.title')}.</p>
                                                </div>
                                                <div className="col-6">
                                                    <strong>Alerts Conditions</strong>
                                                </div>
                                                <div className="col-6">
                                                    {conditions.length}
                                                </div>
                                            </div> : null }
                                        </section>
                                    </div>

                                    {!onMap ?
                                    <div className="col-12">
                                        <section>
                                            <h4>Update</h4>
                                            <GeoFenceEditForm handler={this.updateGeoFenceHandler} geoFenceData={geoFence} />
                                        </section>
                                    </div> : null }

                                    
                                    <div className="col-12">
                                        {conditions.length > 0 ? 
                                        <section>
                                            <div className="alert alert-warning" role="alert">
                                                <span className="badge badge-warning">In Use</span>: One or more <Link to="/settings?page=rules-conditions">Alert Conditions</Link> use this {t('geofence.title')}; while it's in use, this {t('geofence.title')} can not be deleted.
                                            </div>
                                        </section> :
                                        <section className="callout">
                                            <h4>Delete this {t('geofence.title')}</h4>
                                            <p>Once this {t('geofence.title')} is deleted, it can not be undone. Also, note that any active Alert's that use this {t('geofence.title')} will also be inactive and removed.</p>

                                            <div className="text-right DeleteBoxInCallout">
                                                <button onClick={() => this.deleteGeoFenceHandler()} className="btn btn-danger btn-block"><FontAwesomeIcon icon={['fad', 'trash']} /> Delete</button>
                                            </div>
                                        </section>}
                                    </div>

                                </div>
                            
                            </div>
                        </div>
                    </div>
                </>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(withTranslation()(GeoFenceShow))
