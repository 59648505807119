import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

import { Condition, GeoFence } from '../../Controllers'
import { CreateCondition } from '../../Services/Echolo/Rules/Condition'

import { GetAllRules } from '../../Services/Echolo/Rules/Rule'
import { Skeleton } from "@material-ui/lab"

const ConditionForm = ({ currentCompany, inWizard = false, wizardHandler, rule = null }) => {
    const { t } = useTranslation()
    const { ruleId } = useParams()
    let initCondition = {
        comparator: '',
        conditionKey: '',
        conditionValue: '',
        ruleId: ruleId || rule.ruleId || ''
    }
    const [condition, setCondition] = useState(initCondition)
    const [geofences, setGeofences] = useState([])

    const fetchGeoFences = async () => {
        let results = await GeoFence.All(currentCompany)
        if (results) {
            setGeofences(results)
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'There was an issing loading your GeoFences'
                }
            })
        }
    }

    useEffect(async () => {
        if (inWizard) {
            wizardHandler('condition', condition)
        }
        if ((rule || ruleId) && !condition.ruleId) {
            setCondition({ ...condition, ruleId: ruleId || rule.ruleId })
        }
    }, [condition])

    const submitHandler = async (e) => {
        e.preventDefault()
        const newCondition = await CreateCondition(currentCompany, condition)
        if (newCondition) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Saved!'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'There was an issue creating your new Condition, please try again.'
                }
            })
        }
    }

    const selectMetric = (e) => {
        setCondition({ ...condition, conditionKey: e.target.value })
        if (e.target.value === 'geofence') {
            fetchGeoFences()
        } else {
            setGeofences([])
        }
    }

    return (
        <form onSubmit={submitHandler}>
            <div className="row">

                <div className="col-12 mb-4">
                    <div className="form-group">
                        <FormControl required className="form-control" variant="outlined">
                            <InputLabel id="ruleType">Key</InputLabel>
                            <Select
                                fullWidth
                                labelId="ruleType"
                                value={condition.conditionKey}
                                onChange={(e) => selectMetric(e)}>
                                    <MenuItem value={'nodeId'}>Sensor ID (string)</MenuItem>
                                    <MenuItem value={'seenAt'}>Hub ID (string)</MenuItem>
                                    <MenuItem value={'profilePacket.cmh'}>Machine Hours (int/float)</MenuItem>
                                    <MenuItem value={'profilePacket.in_motion'}>Motion (bool)</MenuItem>
                                    <MenuItem value={'profilePacket.fTemperature'}>Temperature (&#8457;)</MenuItem>
                                    <MenuItem value={'profilePacket.temperature'}>Temperature (&#8451;)</MenuItem>
                                    <MenuItem value={'profilePacket.humidity'}>Humidity (%)</MenuItem>
                                    <MenuItem value={'measuredDistance'}>Distance from Hub (int - ft.)</MenuItem>
                                    <MenuItem value={'nodeType'}>Node Type (string)</MenuItem>
                                    <MenuItem value={'profilePacket.eConductivity'}>Soil Conductivity (int)</MenuItem>
                                    <MenuItem value={'profilePacket.co2'}>Co2 (int)</MenuItem>
                                    <MenuItem value={'profilePacket.tvoc'}>Total Volatile Organic Compounds (TVOC)</MenuItem>
                                    <MenuItem value={'profilePacket.pressure'}>Pressure (int)</MenuItem>
                                    <MenuItem value={'profilePacket.illumination'}>Illumination (int)</MenuItem>
                                    <MenuItem value={'profilePacket.battery'}>Battery (%)</MenuItem>
                                    <MenuItem value={'profilePacket.battery_lvl'}>[OEM] Battery Level (%)</MenuItem>
                                    <MenuItem value={'profilePacket.serial_number'}>[OEM] Serial Number (int)</MenuItem>
                                    <MenuItem value={'profilePacket.analog_type'}>[OEM] Analog Type (int)</MenuItem>
                                    <MenuItem value={'profilePacket.analog_val'}>[OEM] Analog Value (int)</MenuItem>
                                    <MenuItem value={'profilePacket.tilt_count'}>[OEM] Tilt Count (int)</MenuItem>
                                    <MenuItem value={'profilePacket.tilt_status'}>[OEM] Tilt Status (bool)</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="col-12 mb-4">
                    <div className="form-group">
                        <FormControl required className="form-control" variant="outlined">
                            <InputLabel id="ConditionType">Comparator</InputLabel>
                            <Select
                                fullWidth
                                labelId="ruleType"
                                value={condition.comparator}
                                onChange={(e) => setCondition({ ...condition, comparator: e.target.value })}>
                                    
                                    {condition.comparator === 'geofence' ? <MenuItem value={'geofence_enter'}>Entered</MenuItem> : null }
                                    {condition.comparator === 'geofence' ? <MenuItem value={'geofence_exit'}>Exited</MenuItem> : null }
                                    {condition.comparator === 'geofence' ? <MenuItem value={'geofence_in'}>In {t('geofence.title')}</MenuItem> : null }
                                    {condition.comparator === 'geofence' ? <MenuItem value={'geofence_out'}>Out of {t('geofence.title')}</MenuItem> : null }
                                    {condition.comparator === 'geofence' ? null : <MenuItem value={'eq'}><FontAwesomeIcon icon={['fad', 'equals']} />  (Equals)</MenuItem> }
                                    {condition.comparator === 'geofence' ? null : <MenuItem value={'gte'}><FontAwesomeIcon icon={['fad', 'greater-than-equal']} />  (Greater Than or Equal To)</MenuItem> }
                                    {condition.comparator === 'geofence' ? null : <MenuItem value={'lte'}><FontAwesomeIcon icon={['fad', 'less-than-equal']} />  (Less Than or Equal To)</MenuItem> }
                                    {condition.comparator === 'geofence' ? null : <MenuItem value={'lt'}><FontAwesomeIcon icon={['fad', 'less-than']} />  (Less Than)</MenuItem> }
                                    {condition.comparator === 'geofence' ? null : <MenuItem value={'gt'}><FontAwesomeIcon icon={['fad', 'greater-than']} />  (Greater Than)</MenuItem> }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                
                {condition.comparator === 'geofence' ? null :
                <div className="col-12 mb-4">
                    <div className="form-group">
                        <TextField className="form-control" value={condition.conditionValue} onChange={(e) => setCondition({ ...condition, conditionValue: e.target.value })} name="value" required id="conditionValue" label="Value" variant="outlined" />
                    </div>
                </div>}

                {condition.comparator === 'geofence' ?
                <div className="col-12 mb-4">
                    <div className="form-group">
                        <FormControl required className="form-control" variant="outlined">
                            <InputLabel id="ruleType">Metric</InputLabel>
                            <Select
                                fullWidth
                                labelId="ruleType"
                                value={condition.conditionGeofenceId}
                                onChange={(e) => setCondition({ ...condition, conditionGeofenceId: e.target.value })}>
                                    {geofences.map(geofence => (
                                        <MenuItem key={geofence.id} value={geofence.id}>{geofence.name}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>: null }

                {!inWizard ?
                <div className="col-12 mb-4 text-right">
                    <button className="btn btn-primary"><FontAwesomeIcon icon={['fad', 'save']} /> Save</button>
                </div> : null }

            </div>
        </form>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(ConditionForm)
