import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { Company } from '../../Controllers'

const CompaniesTable = () => {
    const [companies, setCompanies] = useState([])
    const componentIsMounted = useRef(true)

    useEffect(() => {
        return () => {
            componentIsMounted.current = false
        }
    })

    const FetchCompanies = async () => {
        if (componentIsMounted.current) {
            setCompanies(await Company.All())
        }
    }
    FetchCompanies()

    let companiesRows = null
    companiesRows = companies.map((company, key) => {
        return (
            <tr key={key}>
                <th scope="row">{company.id}</th>
                <td><Link to={"/company/" + company.id + "/edit"}>{company.name}</Link></td>
            </tr>
        )
    })

    return (
        <>
        {_.isEmpty(companies) ?
            <>
                <p>No companies</p>
            </> :
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                    </tr>
                </thead>
                <tbody>
                    {companiesRows}
                </tbody>
            </table>
        }
        </>
    )
}

export default CompaniesTable