import { API, graphqlOperation } from 'aws-amplify'
import { createTag, deleteTag } from '../graphql/mutations'
import { tagByName } from '../graphql/queries'
import { getCompanyTags } from './graphql/querys'

export const Create = async (company, data) => {
    // First try and Find the Tag
    const tagExists = await Find(company, data.name, data.type)

    if (tagExists) {
        return tagExists
    } else {
        const input = {
            name: data.name,
            tagCompanyId: company.id,
            type: data.type
        }
    
        return (await API.graphql(graphqlOperation(createTag, { input }))).data.createTag
    }
}

export const Find = async (company, tagName, tagType = 'item') => {
    try {
        return (await API.graphql(graphqlOperation(tagByName, { name: tagName } ))).data.tagByName.items.filter(t => t.company.id === company.id && t.type === tagType)[0]
    } catch(e) {
        console.warn("Tag.Find failed", e)
        return false
    }
}

export const All = async (company) => {
    return (await API.graphql(graphqlOperation(getCompanyTags, { id: company.id }))).data.getCompany.tags.items
}

export const Delete = async (company, tagName) => {
    let tag = await Find(company, tagName)
    // @TODO: Fix this.
    return await API.graphql(graphqlOperation(deleteTag, { input: { id: tag.id } }))
}

export const DeleteById = async (tagId) => {
    return await API.graphql(graphqlOperation(deleteTag, { input: { id: tagId } }))
}