import React from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert as AlertItem } from '@material-ui/lab'

/* 
    type = 'error', 'warning', 'info',  'success'
*/
const Alert = (props) => {
    const {notify, setNotify} = props

    const handleClose = () => {
        setNotify({ ...notify, isOpen: false })
    }

    return (
        <Snackbar open={notify.isOpen} autoHideDuration={4000} onClose={handleClose}>
            <AlertItem severity={notify.type}>
                {notify.message}
            </AlertItem>
        </Snackbar>
    )
}

export default Alert