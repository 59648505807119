import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Checkbox, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { GetAllProfiles, SplitServiceTypes } from '../../Services/Echolo/Profile'

import { useParams } from "react-router-dom"
import { Hub } from "@aws-amplify/core"
import { BuildDeliveryTags } from '../../Helpers/ProfileHelpers'

const ActionForm = ({ currentCompany, handler, inWizard = false, wizardHandler, rule = null }) => {
    const { ruleId } = useParams()
    const initAction = {
        serviceId: '',
        ruleId: ruleId || rule.ruleId || ''
    }
    const [action, setAction] = useState(initAction)
    const [profiles, setProfiles] = useState([])

    useEffect(async () => {
        if (inWizard) {
            wizardHandler('action', action)
        }
        await fetchProfiles()
    }, [action])

    const selectServiceHandler = async (e) => {
        setAction({ ...action, serviceId: e.target.value })
    }

    const fetchProfiles = async () => {
        let profileData = await GetAllProfiles(currentCompany)
        profileData = SplitServiceTypes(profileData)
        if (profileData.rule_delivery) {
            setProfiles([ ...profileData.rule_delivery ])
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'There was an issue loading your Profiles, please try again.'
                }
            })
        }
    }

    const submitHandler = (e) => {
        e.preventDefault()
        handler(action)
    }

    return (
        <form onSubmit={submitHandler}>
            <div className="row">
                <div className={'col-12'}>
                    <p className={'mb-0'}><strong>Select a Profile</strong></p>
                    <p>Actions rely on a Rule delivery Profiles. When the Rule executes and returns data that meet your Conditions the Profile and its delivery methods will fire.</p>
                </div>
                <div className="col-12 mb-4">
                    <div className="form-group">
                        <FormControl required className="form-control" variant="outlined">
                            <InputLabel id="ruleType">Profile</InputLabel>
                            <Select
                                fullWidth
                                labelId="ruleType"
                                value={action.serviceId}
                                onChange={(e) => selectServiceHandler(e)}>
                                {
                                    profiles.map(profile => (
                                        <MenuItem key={profile.serviceId} value={profile.serviceId}><strong className={'pr-1'}>{profile.serviceId}</strong> with delivery to {BuildDeliveryTags(JSON.parse(profile.methods))}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                
                {!inWizard ?
                <div className="col-12 mb-4 text-right">
                    <button className="btn btn-primary"><FontAwesomeIcon icon={['fad', 'save']} /> Save</button>
                </div> : null }
            </div>
        </form>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(ActionForm)