/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      appId
      appSecret
      token
      type
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      savedResults {
        items {
          id
          ruleId
          resultId
          resultCount
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      appId
      appSecret
      token
      type
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      savedResults {
        items {
          id
          ruleId
          resultId
          resultCount
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      appId
      appSecret
      token
      type
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      savedResults {
        items {
          id
          ruleId
          resultId
          resultCount
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createDashboard = /* GraphQL */ `
  mutation CreateDashboard(
    $input: CreateDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    createDashboard(input: $input, condition: $condition) {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      geofences {
        items {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        nextToken
      }
      widgets {
        items {
          id
          name
          type
          sensorKey
          range
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
    }
  }
`;
export const updateDashboard = /* GraphQL */ `
  mutation UpdateDashboard(
    $input: UpdateDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    updateDashboard(input: $input, condition: $condition) {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      geofences {
        items {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        nextToken
      }
      widgets {
        items {
          id
          name
          type
          sensorKey
          range
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
    }
  }
`;
export const deleteDashboard = /* GraphQL */ `
  mutation DeleteDashboard(
    $input: DeleteDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    deleteDashboard(input: $input, condition: $condition) {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      geofences {
        items {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        nextToken
      }
      widgets {
        items {
          id
          name
          type
          sensorKey
          range
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
    }
  }
`;
export const createDashboardTag = /* GraphQL */ `
  mutation CreateDashboardTag(
    $input: CreateDashboardTagInput!
    $condition: ModelDashboardTagConditionInput
  ) {
    createDashboardTag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const updateDashboardTag = /* GraphQL */ `
  mutation UpdateDashboardTag(
    $input: UpdateDashboardTagInput!
    $condition: ModelDashboardTagConditionInput
  ) {
    updateDashboardTag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const deleteDashboardTag = /* GraphQL */ `
  mutation DeleteDashboardTag(
    $input: DeleteDashboardTagInput!
    $condition: ModelDashboardTagConditionInput
  ) {
    deleteDashboardTag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const createSavedResults = /* GraphQL */ `
  mutation CreateSavedResults(
    $input: CreateSavedResultsInput!
    $condition: ModelSavedResultsConditionInput
  ) {
    createSavedResults(input: $input, condition: $condition) {
      id
      ruleId
      resultId
      resultCount
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSavedResults = /* GraphQL */ `
  mutation UpdateSavedResults(
    $input: UpdateSavedResultsInput!
    $condition: ModelSavedResultsConditionInput
  ) {
    updateSavedResults(input: $input, condition: $condition) {
      id
      ruleId
      resultId
      resultCount
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSavedResults = /* GraphQL */ `
  mutation DeleteSavedResults(
    $input: DeleteSavedResultsInput!
    $condition: ModelSavedResultsConditionInput
  ) {
    deleteSavedResults(input: $input, condition: $condition) {
      id
      ruleId
      resultId
      resultCount
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createResultsTag = /* GraphQL */ `
  mutation CreateResultsTag(
    $input: CreateResultsTagInput!
    $condition: ModelResultsTagConditionInput
  ) {
    createResultsTag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      savedResults {
        id
        ruleId
        resultId
        resultCount
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const updateResultsTag = /* GraphQL */ `
  mutation UpdateResultsTag(
    $input: UpdateResultsTagInput!
    $condition: ModelResultsTagConditionInput
  ) {
    updateResultsTag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      savedResults {
        id
        ruleId
        resultId
        resultCount
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const deleteResultsTag = /* GraphQL */ `
  mutation DeleteResultsTag(
    $input: DeleteResultsTagInput!
    $condition: ModelResultsTagConditionInput
  ) {
    deleteResultsTag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      savedResults {
        id
        ruleId
        resultId
        resultCount
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const createGeoFence = /* GraphQL */ `
  mutation CreateGeoFence(
    $input: CreateGeoFenceInput!
    $condition: ModelGeoFenceConditionInput
  ) {
    createGeoFence(input: $input, condition: $condition) {
      id
      name
      paths
      center
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateGeoFence = /* GraphQL */ `
  mutation UpdateGeoFence(
    $input: UpdateGeoFenceInput!
    $condition: ModelGeoFenceConditionInput
  ) {
    updateGeoFence(input: $input, condition: $condition) {
      id
      name
      paths
      center
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteGeoFence = /* GraphQL */ `
  mutation DeleteGeoFence(
    $input: DeleteGeoFenceInput!
    $condition: ModelGeoFenceConditionInput
  ) {
    deleteGeoFence(input: $input, condition: $condition) {
      id
      name
      paths
      center
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createWidget = /* GraphQL */ `
  mutation CreateWidget(
    $input: CreateWidgetInput!
    $condition: ModelWidgetConditionInput
  ) {
    createWidget(input: $input, condition: $condition) {
      id
      name
      type
      sensorKey
      range
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const updateWidget = /* GraphQL */ `
  mutation UpdateWidget(
    $input: UpdateWidgetInput!
    $condition: ModelWidgetConditionInput
  ) {
    updateWidget(input: $input, condition: $condition) {
      id
      name
      type
      sensorKey
      range
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const deleteWidget = /* GraphQL */ `
  mutation DeleteWidget(
    $input: DeleteWidgetInput!
    $condition: ModelWidgetConditionInput
  ) {
    deleteWidget(input: $input, condition: $condition) {
      id
      name
      type
      sensorKey
      range
      createdAt
      updatedAt
      dashboard {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        geofences {
          nextToken
        }
        widgets {
          nextToken
        }
        user {
          id
          email
          phone_number
          notify_email
          notify_sms
          first_name
          last_name
          cognito_id
          status
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      phone_number
      notify_email
      notify_sms
      first_name
      last_name
      cognito_id
      status
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      companies {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      phone_number
      notify_email
      notify_sms
      first_name
      last_name
      cognito_id
      status
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      companies {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      phone_number
      notify_email
      notify_sms
      first_name
      last_name
      cognito_id
      status
      createdAt
      updatedAt
      dashboards {
        items {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
        nextToken
      }
      companies {
        items {
          id
          level
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUserCompanies = /* GraphQL */ `
  mutation CreateUserCompanies(
    $input: CreateUserCompaniesInput!
    $condition: ModelUserCompaniesConditionInput
  ) {
    createUserCompanies(input: $input, condition: $condition) {
      id
      level
      status
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const updateUserCompanies = /* GraphQL */ `
  mutation UpdateUserCompanies(
    $input: UpdateUserCompaniesInput!
    $condition: ModelUserCompaniesConditionInput
  ) {
    updateUserCompanies(input: $input, condition: $condition) {
      id
      level
      status
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserCompanies = /* GraphQL */ `
  mutation DeleteUserCompanies(
    $input: DeleteUserCompaniesInput!
    $condition: ModelUserCompaniesConditionInput
  ) {
    deleteUserCompanies(input: $input, condition: $condition) {
      id
      level
      status
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      name
      sn
      customId
      type
      year
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      name
      sn
      customId
      type
      year
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      name
      sn
      customId
      type
      year
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          sid
          sn
          customId
          class
          type
          sku
          name
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      items {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      items {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      items {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createItemTags = /* GraphQL */ `
  mutation CreateItemTags(
    $input: CreateItemTagsInput!
    $condition: ModelItemTagsConditionInput
  ) {
    createItemTags(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const updateItemTags = /* GraphQL */ `
  mutation UpdateItemTags(
    $input: UpdateItemTagsInput!
    $condition: ModelItemTagsConditionInput
  ) {
    updateItemTags(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const deleteItemTags = /* GraphQL */ `
  mutation DeleteItemTags(
    $input: DeleteItemTagsInput!
    $condition: ModelItemTagsConditionInput
  ) {
    deleteItemTags(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const createSensorTags = /* GraphQL */ `
  mutation CreateSensorTags(
    $input: CreateSensorTagsInput!
    $condition: ModelSensorTagsConditionInput
  ) {
    createSensorTags(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const updateSensorTags = /* GraphQL */ `
  mutation UpdateSensorTags(
    $input: UpdateSensorTagsInput!
    $condition: ModelSensorTagsConditionInput
  ) {
    updateSensorTags(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const deleteSensorTags = /* GraphQL */ `
  mutation DeleteSensorTags(
    $input: DeleteSensorTagsInput!
    $condition: ModelSensorTagsConditionInput
  ) {
    deleteSensorTags(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      sensor {
        id
        sid
        sn
        customId
        class
        type
        sku
        name
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
      }
    }
  }
`;
export const createSensor = /* GraphQL */ `
  mutation CreateSensor(
    $input: CreateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    createSensor(input: $input, condition: $condition) {
      id
      sid
      sn
      customId
      class
      type
      sku
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSensor = /* GraphQL */ `
  mutation UpdateSensor(
    $input: UpdateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    updateSensor(input: $input, condition: $condition) {
      id
      sid
      sn
      customId
      class
      type
      sku
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSensor = /* GraphQL */ `
  mutation DeleteSensor(
    $input: DeleteSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    deleteSensor(input: $input, condition: $condition) {
      id
      sid
      sn
      customId
      class
      type
      sku
      name
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createRule = /* GraphQL */ `
  mutation CreateRule(
    $input: CreateRuleInput!
    $condition: ModelRuleConditionInput
  ) {
    createRule(input: $input, condition: $condition) {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      conditions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateRule = /* GraphQL */ `
  mutation UpdateRule(
    $input: UpdateRuleInput!
    $condition: ModelRuleConditionInput
  ) {
    updateRule(input: $input, condition: $condition) {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      conditions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteRule = /* GraphQL */ `
  mutation DeleteRule(
    $input: DeleteRuleInput!
    $condition: ModelRuleConditionInput
  ) {
    deleteRule(input: $input, condition: $condition) {
      id
      name
      type
      itemType
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      item {
        id
        name
        sn
        customId
        type
        year
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      conditions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createRuleTag = /* GraphQL */ `
  mutation CreateRuleTag(
    $input: CreateRuleTagInput!
    $condition: ModelRuleTagConditionInput
  ) {
    createRuleTag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const updateRuleTag = /* GraphQL */ `
  mutation UpdateRuleTag(
    $input: UpdateRuleTagInput!
    $condition: ModelRuleTagConditionInput
  ) {
    updateRuleTag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const deleteRuleTag = /* GraphQL */ `
  mutation DeleteRuleTag(
    $input: DeleteRuleTagInput!
    $condition: ModelRuleTagConditionInput
  ) {
    deleteRuleTag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
    }
  }
`;
export const createRuleCondition = /* GraphQL */ `
  mutation CreateRuleCondition(
    $input: CreateRuleConditionInput!
    $condition: ModelRuleConditionConditionInput
  ) {
    createRuleCondition(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      condition {
        id
        operator
        metric
        value
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        geofence {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const updateRuleCondition = /* GraphQL */ `
  mutation UpdateRuleCondition(
    $input: UpdateRuleConditionInput!
    $condition: ModelRuleConditionConditionInput
  ) {
    updateRuleCondition(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      condition {
        id
        operator
        metric
        value
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        geofence {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const deleteRuleCondition = /* GraphQL */ `
  mutation DeleteRuleCondition(
    $input: DeleteRuleConditionInput!
    $condition: ModelRuleConditionConditionInput
  ) {
    deleteRuleCondition(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      condition {
        id
        operator
        metric
        value
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        geofence {
          id
          name
          paths
          center
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
      }
    }
  }
`;
export const createCondition = /* GraphQL */ `
  mutation CreateCondition(
    $input: CreateConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    createCondition(input: $input, condition: $condition) {
      id
      operator
      metric
      value
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      geofence {
        id
        name
        paths
        center
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        dashboard {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCondition = /* GraphQL */ `
  mutation UpdateCondition(
    $input: UpdateConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    updateCondition(input: $input, condition: $condition) {
      id
      operator
      metric
      value
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      geofence {
        id
        name
        paths
        center
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        dashboard {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCondition = /* GraphQL */ `
  mutation DeleteCondition(
    $input: DeleteConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    deleteCondition(input: $input, condition: $condition) {
      id
      operator
      metric
      value
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      geofence {
        id
        name
        paths
        center
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        dashboard {
          id
          name
          type
          itemType
          createdAt
          updatedAt
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createRuleAction = /* GraphQL */ `
  mutation CreateRuleAction(
    $input: CreateRuleActionInput!
    $condition: ModelRuleActionConditionInput
  ) {
    createRuleAction(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const updateRuleAction = /* GraphQL */ `
  mutation UpdateRuleAction(
    $input: UpdateRuleActionInput!
    $condition: ModelRuleActionConditionInput
  ) {
    updateRuleAction(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const deleteRuleAction = /* GraphQL */ `
  mutation DeleteRuleAction(
    $input: DeleteRuleActionInput!
    $condition: ModelRuleActionConditionInput
  ) {
    deleteRuleAction(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      rule {
        id
        name
        type
        itemType
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        item {
          id
          name
          sn
          customId
          type
          year
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        conditions {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction(
    $input: CreateActionInput!
    $condition: ModelActionConditionInput
  ) {
    createAction(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction(
    $input: UpdateActionInput!
    $condition: ModelActionConditionInput
  ) {
    updateAction(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction(
    $input: DeleteActionInput!
    $condition: ModelActionConditionInput
  ) {
    deleteAction(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      updatedAt
      company {
        id
        name
        appId
        appSecret
        token
        type
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        savedResults {
          nextToken
        }
        users {
          nextToken
        }
        items {
          nextToken
        }
        tags {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      tag {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        sensors {
          nextToken
        }
        rules {
          nextToken
        }
      }
      rules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createActionUser = /* GraphQL */ `
  mutation CreateActionUser(
    $input: CreateActionUserInput!
    $condition: ModelActionUserConditionInput
  ) {
    createActionUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const updateActionUser = /* GraphQL */ `
  mutation UpdateActionUser(
    $input: UpdateActionUserInput!
    $condition: ModelActionUserConditionInput
  ) {
    updateActionUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const deleteActionUser = /* GraphQL */ `
  mutation DeleteActionUser(
    $input: DeleteActionUserInput!
    $condition: ModelActionUserConditionInput
  ) {
    deleteActionUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      user {
        id
        email
        phone_number
        notify_email
        notify_sms
        first_name
        last_name
        cognito_id
        status
        createdAt
        updatedAt
        dashboards {
          nextToken
        }
        companies {
          nextToken
        }
        actions {
          nextToken
        }
      }
      action {
        id
        name
        type
        createdAt
        updatedAt
        company {
          id
          name
          appId
          appSecret
          token
          type
          createdAt
          updatedAt
        }
        tag {
          id
          name
          type
          createdAt
          updatedAt
        }
        rules {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
