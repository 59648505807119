import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const status = (lastSeenDate) => {
    let hubDate = new Date(Date.parse(lastSeenDate))
    let dateInPast = new Date()
    dateInPast.setMinutes(dateInPast.getMinutes() - 120)

    return dateInPast < hubDate && lastSeenDate;
}

export const statusIcon = (lastSeenDate, type = 'on-off') => {
    let hubDate = new Date(Date.parse(lastSeenDate))
    let dateInPast = new Date()
    dateInPast.setMinutes(dateInPast.getMinutes() - 120)

    if (type === 'on-off') {
        if (dateInPast < hubDate && lastSeenDate) {
            // Online
            return <FontAwesomeIcon icon="fa-duotone fa-signal-bars" />
        } else {
            // Offline
            return <FontAwesomeIcon icon="fa-duotone fa-signal-bars-slash" />
        }
    } else if (type === 'comms') {
        if (dateInPast < hubDate && lastSeenDate) {
            // Online
            return <FontAwesomeIcon icon="fa-duotone fa-signal-stream" />
        } else {
            // Offline
            return <FontAwesomeIcon icon="fa-duotone fa-signal-stream-slash" />
        }
    }

}