import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Chip } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { Sensor } from '../../Controllers'

import TagForm from '../Tag/TagForm'

import Loading from '../../Helpers/Components/Loading'

import ItemTypes from '../../Config/ItemTypes'

const ItemForm = ({ currentCompany, handler, itemData }) => {
    const { t } = useTranslation()
    let initItem = {
        name: '',
        tags: [],
        sensors: [],
        customId: '',
        sn: '',
        year: '',
        type: ''
    }

    if (itemData) {
        initItem = itemData
    }

    const [item, setItem] = useState(initItem)
    const [sensors, setSensors] = useState([])
    const [loadingSensors, setLoadingSensors] = useState(true)


    const tagsHandler = (tags) => {
        setItem({ ...item, tags: tags })
    }

    const newItemHandler = (event) => {
        event.preventDefault()
        handler(item)
        setItem(initItem)
    }

    const fetchSensors = async () => {
        let sensors = (await Sensor.All(currentCompany)).filter(s => !s.item)
        setSensors(sensors)
        setLoadingSensors(false)
    }

    useEffect(() => {
        fetchSensors()
    }, [])

    const addSensor = (sensor) => {
        // Set Item
        setItem({ ...item, sensors: [ ...item.sensors, sensor ] })
        // Removed and Set Sensors
        let newSensorList = sensors.filter(s => s.id !== sensor.id)
        setSensors(newSensorList)
    }

    const removeSensor = (sensor) => {
        setSensors([ ...sensors, sensor ])
        let newSensorList = item.sensors.filter(s => s.id !== sensor.id)
        setItem({ ...item, sensors: newSensorList })
    }

    return (
        <div className="row">
            <div className="col-8">
                <section>
                    <form onSubmit={newItemHandler}>
                        <div className="row">
                            <div className="col-12">
                                <h4>{t('item.title')} Details</h4>
                            </div>
                            <div className="col-12">
                                <div className="row">

                                    <div className="col-12 mb-4">
                                        <div className="form-group">
                                            <TextField className="form-control" value={item.name} onChange={(e) => setItem({ ...item, name: e.target.value })} name="name" required id="itemName" label="Item Name" variant="outlined" />
                                        </div>
                                    </div>

                                    <div className="col-12 mb-4">
                                        <div className="form-group">
                                            <FormControl required className="form-control" variant="outlined">
                                                <InputLabel id="ItemType">Type</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="ItemType"
                                                    value={item.type || ''}
                                                    onChange={(e) => setItem( { ...item, type: e.target.value } )}>
                                                        {ItemTypes[currentCompany.type].map((type, key) => (
                                                            <MenuItem key={key} value={type}>{type}</MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    {currentCompany.type === 'construction' ?
                                    <>
                                        <div className="col-12 mb-4">
                                            <div className="form-group">
                                                <p><small>Leave blank, and the system will create a Custom ID for you. You have the option to update this later.</small></p>
                                                <TextField className="form-control" value={item.customId || ''} onChange={(e) => setItem({ ...item, customId: e.target.value })} name="customId" id="itemcustomId" label="Custom ID" variant="outlined" />
                                            </div>
                                        </div>

                                        <div className="col-12 mb-4">
                                            <div className="form-group">
                                                <TextField className="form-control" value={item.sn || ''} onChange={(e) => setItem({ ...item, sn: e.target.value })} name="sn" id="itemSn" label="Serial Number" variant="outlined" />
                                            </div>
                                        </div>

                                        <div className="col-12 mb-4">
                                            <div className="form-group">
                                                <TextField className="form-control" value={item.year || ''} onChange={(e) => setItem({ ...item, year: e.target.value })} name="year" id="itemYear" label="Year" variant="outlined" />
                                            </div>
                                        </div>

                                    </>
                                    : null }

                                    <div className="col-12 mb-4">
                                        <div className="form-group">
                                            <TagForm handler={tagsHandler} defaultTags={item.tags} />
                                        </div>
                                    </div>
                                    
                                    {item.sensors ? 
                                    <div className="col-12 mb-4">
                                        <h5>Attached Sensors</h5>
                                        <div>
                                            {item.sensors.map(sensor => (
                                                <Chip onClick={() => removeSensor(sensor)} className="ItemSensorsRemove ChipSensor" key={sensor.id} variant="outlined" icon={<FontAwesomeIcon icon={['fad', 'times-circle']} />} label={`${sensor.name} [${sensor.sid}]`} />
                                            ))}
                                        </div>
                                    </div> : null }

                                    <div className="col align-self-end mt-4 text-right">
                                        <button className="btn btn-primary"><FontAwesomeIcon icon={['fad', 'save']} /> Save {t('item.title')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>

            <div className="col-4">
                <h4>Sensors</h4>
                <p>Click on a sensor or sensors below to attach it to the {t('item.title')}. You can attach as many sensors as needed.</p>
                <ul id="sensorSelectList" className="list-group">
                    <Loading isOpen={loadingSensors} />
                    {sensors.map((sensor => (
                        <li key={sensor.sid} onClick={() => addSensor(sensor)} className="list-group-item list-group-item-action FakeLink">
                            <FontAwesomeIcon icon={['fad', 'plus']} /> <span className="float-right">{sensor.name} [{sensor.sid}]</span></li>
                    )))}

                    {sensors.length === 0 ? <p>You currently are using all of your sensors or have not added any into your system. <Link to="/sensor/new">Go here to activate a new sensor</Link>.</p> : null}
                </ul>
            </div>
        </div>
    )
}

export default ItemForm