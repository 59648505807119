import React, { useEffect, useState } from "react"
import ProfileTableRow from './ProfileTableRow'

import { SplitServiceTypes } from '../../Services/Echolo/Profile'
const ProfileTable = ({ profiles, setPageHandler }) => {
    return (
        <table className="table table-hover">
            <thead>
            <tr>
                <th scope="col">Service ID</th>
                <th scope="col">Integration</th>
                <th scope="col">Delivery Methods</th>
                <th scope="col">Configured RSSI</th>
            </tr>
            </thead>
            <tbody>
            {profiles.map((row) => (
                <ProfileTableRow setPageHandler={setPageHandler} key={row.serviceId} row={row} />
            ))}
            </tbody>
        </table>
    )
}

export default ProfileTable
