import React from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ReportsMenu = ({ location }) => {
    return (
        <div className={'row'}>
            <div className={'col-12'}>
                <nav>
                    <ul className="nav flex-column nav-pills">
                        <li className="sidebarSubMenu_header">Reports</li>
                        <li><Link className={location === 'index' ? 'active nav-link' : 'nav-link'} to="/reports"><FontAwesomeIcon icon="fa-duotone fa-cloud-arrow-up" /> Incoming Reports</Link></li>
                        <li><Link className={location === 'savedReports' ? 'active nav-link' : 'nav-link'} to="/reports/view/saved"><FontAwesomeIcon icon="fa-duotone fa-folder-bookmark" /> Saved Reports</Link></li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default ReportsMenu
