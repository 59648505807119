import axios from 'axios'
import * as Error from './ErrorHandler'

import { Company } from '../../Controllers'
import { GetToken } from './Application'

const Echolo_Api_Key = process.env.ECHOLO_API_KEY || '6CQbdug5Kj1CndYmz2DlS9MUFg1NnrVm3JdvCPvR'
export const EcholoApi = axios.create({
    baseURL: 'https://api.echolo.io/v3',
    headers: { 
        'Content-Type': 'application/json', 
        'x-api-key': Echolo_Api_Key
    }
})

EcholoApi.interceptors.response.use(response => {
    if (response.data.error) {
        let origRequest = response
        // let errorObj = Error.Handler(response.data.status)
        if (response.data.status === 401) {
            // @TODO Need to get application/Company here.
            // Have app-id in the request header. response.config.headers['appId']
            Company.FindByAppId(response.config.headers['appId']).then(company => {
                GetToken(company).then(token => {
                    origRequest.config.headers['token'] = token
                    return EcholoApi(origRequest)
                })
            }).catch(e => {
                console.warn("Failed to FindByAppId in EcholoApi.interceptors", e)
            })
        }
    } else {
        // console.log("VALID REQUEST",response)
        return response
    }

}, err => {
    console.log("EcholoApi.interceptors", err)
})

export const Headers = (application) => {
    return {
        headers: {
            'appId': application.appId,
            'token': application.token || ''
        }
    }
}
