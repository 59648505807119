import { API, graphqlOperation } from 'aws-amplify'

import { updateSavedResults } from './graphql/mutations'
import { listSavedResultss, getSavedResults } from "./graphql/querys"
import { listResultsTags } from '../graphql/queries'
import { createSavedResults, createResultsTag, deleteResultsTag, deleteSavedResults } from "../graphql/mutations"

import { Tag } from '.'
import _ from "lodash"

export const SavedResultsAll = async (company) => {
    try {
        return (await API.graphql(graphqlOperation(listSavedResultss))).data.listSavedResultss.items.filter(i => i.company.id === company.id)
    } catch (error) {
        console.warn("Failed to find all Results", error)
        return false
    }
}

export const GetSavedResultsById = async (company, id) => {
    const results = (await API.graphql(graphqlOperation(getSavedResults, { id: id } ))).data.getSavedResults
    if (results.company.id !== company.id) throw new Error("You do not have access to the Results you are getting.")
    return results
}

export const Create = async (company, resultsData) => {
    try {
        const tags = resultsData.tags
        delete resultsData.tags

        resultsData.savedResultsCompanyId = company.id
        const input = resultsData
        const results = (await API.graphql(graphqlOperation(createSavedResults, { input }))).data.createSavedResults

        tags.forEach(tag => {
            saveTagForSavedResults(company, tag).then(newTag => {
                saveSavedResultsTag(results, newTag)
            })
        })

        return results
    } catch (error) {
        console.warn("Failed to save the Results", error)
        return false
    }
}

const addTagsBackToSavedReport = async (company, tags, resultsData) => {
    for (const tag of tags) {
        findOrCreate(company, tag).then(async (tag) => {
            await saveSavedResultsTag(resultsData, tag)
        })
    }
}

export const UpdateSavedReport = async (company, resultsData) => {
    try {
        const tags = resultsData.tags
        // delete all Tags
        let tagsToAdd = []
        if (tags.length > 0) {
            tagsToAdd = await RemoveOrUpdateTags(resultsData.id, tags)
        } else {
            await RemoveAllTags(resultsData.id)
        }

        resultsData.savedResultsCompanyId = company.id
        const input = resultsData
        delete input.company
        delete input.tags
        delete input.rule
        delete input.createdAt
        delete input.updatedAt

        if (tagsToAdd.length > 0) await addTagsBackToSavedReport(company, tagsToAdd, resultsData)
        const res = (await API.graphql(graphqlOperation(updateSavedResults, { input: input }))).data.updateSavedResults
        console.log('UpdateSavedReport', res)
        return res
    } catch (error) {
        console.warn("Failed to update the Results", error)
        return false
    }
}

export const Delete = async (id) => {
    try {
        return await API.graphql(graphqlOperation(deleteSavedResults, { input: { id: id } }))
    } catch (error) {
        console.log(error)
        return false
    }
}

const RemoveAllTags = async (savedResultId) => {
    try {
        const allTags = (await API.graphql(graphqlOperation(listResultsTags))).data.listResultsTags.items.filter(t => t.savedResults.id === savedResultId)
        for (const tagModel of allTags) {
            await deleteTag(tagModel)
        }
    } catch (error) {
        console.log('RemoveAllTags ERROR', error)
    }
}

const RemoveOrUpdateTags = async (savedResultId, updatesTags) => {
    try {
        const allTags = (await API.graphql(graphqlOperation(listResultsTags))).data.listResultsTags.items.filter(t => t.savedResults.id === savedResultId)
        let tagDiff = updatesTags
        let TagsToRemove = []
        let currentTags = []

        for (const tagModel of allTags) {
            currentTags.push(tagModel.tag.name)
        }

        TagsToRemove = _.remove(tagDiff, (t) => {
            return currentTags.includes(t)
        })

        TagsToRemove = _.remove(allTags, (at) => {
            return TagsToRemove.includes(at.tag.name)
        })

        //console.log('TagsToRemove', allTags)
        for (const removeTag of allTags) {
            await deleteTag(removeTag)
        }
        //console.log('TagsToADD', tagDiff)
        return tagDiff

    } catch (error) {
        console.log('RemoveOrUpdateTags ERROR', error)
    }
}

const deleteTag = async (tagModel) => {
    return await API.graphql(graphqlOperation(deleteResultsTag, { input: { id: tagModel.id } }))
}

const saveTagForSavedResults = async (company, tag) => {
    let tagData = { name: tag, type: 'report' }
    return await Tag.Create(company, tagData)
}

const saveSavedResultsTag = async (newSavedResults, newTag) => {
    try {
        const input = {
            resultsTagSavedResultsId: newSavedResults.id,
            resultsTagTagId: newTag.id
        }
        return (await API.graphql(graphqlOperation(createResultsTag, { input }))).data.createResultsTag
    } catch (error) {
        console.log('newTag', newSavedResults)
        console.log('saveSavedResultsTag', error)
    }
}

const findOrCreate = async (company, tagName) => {
    let tag = Tag.Find(company, tagName)
    if (_.isEmpty(tag)) return await saveTagForSavedResults(company, tagName)
    return tag
}
