import React from 'react'
import { Route } from 'react-router-dom'

const AppRoute = ({ needToBeLoggedin, loggedIn, logoutHandler, component: Component, layout: Layout, menu: Menu, ...rest }) => {
    
    let route = (
        <Route
            {...rest}
            render = {props => (
                <Layout>
                    <Component logoutHandler={logoutHandler} {...props}>{props.children}</Component>
                </Layout>
            )}    
        /> 
    )
    if (Menu) {
        route = (
            <Route
                {...rest}
                render = {props => (
                    <Layout menuLayout={<Menu />}>
                        <Component logoutHandler={logoutHandler} {...props}>{props.children}</Component>
                    </Layout>
                )}    
            /> 
        )
    }

    return (
        <>{route}</> 
    )
}

export default AppRoute
