import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'

import { Device } from '../../../Services/Echolo'

import DeleteWidgetModel from './DeleteWidgetModel'
import Loading from '../../../Helpers/Components/Loading'
import { StDateToTimeStamp } from '../../../Helpers/DateHelper'

const Text = ({ currentCompany, widgetData }) => {
    const [widget, setWidget] = useState(widgetData)
    const [device, setDevice] = useState(null)
    const [showModel, setShowModel] = useState(false)

    // request each device to get inital values
    const fetchDevice = async () => {
        let device = await Device.Find(currentCompany, widget.sensor.sid)
        setDevice(device)
    }
    // Subscribe to something for updates ??

    const openDeleteModel = () => {
        setShowModel(true)
    }

    useEffect(() => {
        if (_.isEmpty(widget.sensor)) {
            setWidget(null)
        }
        fetchDevice()
    }, [currentCompany])

    return (
        <>
            { widget ?
            <div className="row m-1 WidgetText">
                <div className="col-12">
                    <div className="row WidgetBox rounded">
                        <div className="col-12 WidgetTextHeader">
                            <h4>{widget.name}</h4>
                            <p className="WidgetTextSubHeader">{widget.sensorKey} from {widget.sensor.sid}</p>
                        </div>

                        <div className="col-12 WidgetTextData">
                            <p>{widget.sensorKey}</p>
                            { device ?
                                <p className="WidgetTextDatatext">{device.profilePacket[widget.sensorKey]}</p> :
                                <Loading isOpen={true} />
                            }
                        </div>
                        <p><FontAwesomeIcon className="FakeLink text-danger WidgetDeleteIcon" onClick={() => openDeleteModel()} icon={['fad', 'trash']} /></p>
                        <p className="WidgetTextInfo">Updated At: {device ? StDateToTimeStamp(device.lastSeenDate) : null}</p>
                        <DeleteWidgetModel widgetData={widget} showModel={showModel} setShowModel={setShowModel} />
                    </div>
                </div>
            </div>
            : null }
        </>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(Text)