import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from "react-i18next"

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: 'base',
        fallbackLng: 'base',
        whitelist: ['base', 'construction'],
        debug: true
    });

export default i18n;