import { API, graphqlOperation } from 'aws-amplify'

import { deleteDashboard, updateDashboard, createDashboardTag } from '../graphql/mutations'
import { createDashboard } from './graphql/mutations'
import { listDashboards, getDashboard } from './graphql/querys'
import { onCreateDashboard, onDeleteDashboard, onUpdateDashboard } from '../graphql/subscriptions'

import { Find as TagFind } from './Tags'
import { Widget, GeoFence } from '.'
export const Create = async (company, user, dashboard) => {
    const originalDashboard = dashboard
    let input = {
        name: dashboard.name,
        dashboardCompanyId: company.id,
        dashboardUserId: user.id,
        type: dashboard.type,
        itemType: dashboard.itemType
    }

    if (dashboard.type === 'item') {
        input.dashboardItemId = dashboard.item.id
    }

    try {
        const dashboard = (await API.graphql(graphqlOperation(createDashboard, { input }))).data.createDashboard
        
        if (dashboard.type === 'map' && dashboard.itemType === '') {
            // Add tags to the dashboard
            // [tags] Loop each and find each tag ID
            originalDashboard.tags.forEach(tagName => {
                CreateDashboardTag(dashboard, company, tagName)
            })
        }

        return dashboard
    } catch (e) {
        console.warn("Failed to create a new Dashboard.", e)
        return false
    }
}

export const Update = async (updatedDashboard) => {
    let input = {
        id: updatedDashboard.id,
        name: updatedDashboard.name
    }

    try {
        return (await API.graphql(graphqlOperation(updateDashboard, { input }))).data.updateDashboard
    } catch (e) {
        console.warn("Failed to update Dashboard.", e)
        return false
    }
}

export const Find = async (company, user, dashboardId) => {
    try {
        const dash = (await API.graphql(graphqlOperation(getDashboard, { id: dashboardId }))).data.getDashboard
        if (dash.company.id === company.id && dash.user.id === user.id) {
            return dash
        }
    } catch (e) {
        console.warn("Failed to find the dashboard.", e)
        return false
    }
}

export const FindByItemId = async (itemId) => {
    return (await API.graphql(graphqlOperation(listDashboards))).data.listDashboards.items.filter(dash => dash.item.id === itemId)
}

export const ItemFindByItemId = async (itemId) => {
    return ((await API.graphql(graphqlOperation(listDashboards))).data.listDashboards.items.filter(dash => dash.type === 'item')).filter(dash => dash.item.id === itemId)
}

export const All = async (company, user) => {
    return (await API.graphql(graphqlOperation(listDashboards))).data.listDashboards.items.filter(dash => dash.company.id === company.id && dash.user.id === user.id)
}

export const CreateSubscription = () => {
    return API.graphql(graphqlOperation(onCreateDashboard))
}

export const DeleteSubscription = () => {
    return API.graphql(graphqlOperation(onDeleteDashboard))
}

export const UpdateSubscription = () => {
    return API.graphql(graphqlOperation(onUpdateDashboard))
}

export const Delete = async (dashboard) => {
    try {
        // Delete All Widgets/GeoFences attached to this dashboard
        await RemoveAllWidgets(dashboard)
        await RemoveAllGeoFences(dashboard)
        return (await API.graphql(graphqlOperation(deleteDashboard, { input: { id: dashboard.id } }))).data.deleteDashboard
    } catch (e) {
        console.warn("There was an issue deleting this Dashboard.", e)
        return false
    }
}

// Private
const CreateDashboardTag = async (dashboard, company, tagName) => {
    let tag = await TagFind(company, tagName)
    const input = {
        dashboardTagDashboardId: dashboard.id,
        dashboardTagTagId: tag.id
    }
    return await API.graphql(graphqlOperation(createDashboardTag, { input }))
}

const RemoveAllWidgets = async (dashboard) => {
    if (dashboard.widgets && dashboard.widgets.items.length > 0) {
        dashboard.widgets.items.forEach(widget => {
            Widget.Delete(widget)
        })
    }
}

const RemoveAllGeoFences = async (dashboard) => {
    try {
        let geofences = await GeoFence.AllByDashboard(dashboard.id)
        if (geofences.length > 0) {
            await asyncForEach(geofences, async (geofence) => {
                await GeoFence.Delete(geofence)
            })
        }
    } catch (e) {
        console.warn("Failed to find and remove a GEO FENCE", e)
    }
}

const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}