import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CreateAction } from '../../../Services/Echolo/Rules/Action'
import ActionForm from "../ActionForm"
import { Link } from "react-router-dom"
const ActionAddModal = ({ showModel, setShowModel, ruleData, currentCompany, isUpdatingHandler }) => {
    const [action, setAction] = useState(null)

    const actionHandler = (type, data) => {
        setAction({ ...action, ...data })
    }

    const addActionToRule = async () => {
        const ruleAction = await CreateAction(currentCompany, ruleData.ruleId, action)
        if (ruleAction) {
            setShowModel(false)
            isUpdatingHandler()
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Added.'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue adding this Action'
                }
            })
        }
    }

    return (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add an Action</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 mb-4">
                        <ActionForm inWizard={true} wizardHandler={actionHandler} currentCompany={currentCompany} rule={ruleData} />
                    </div>
                    <div className="col-12">
                        <h6 className={'text-muted'}>Add Additional Rule Profiles</h6>
                        <p className={'text-muted'}><small>You need a profile in order to send data to your app
                            or even to build a report in Echolo. <Link to={'/settings/profiles'}><FontAwesomeIcon icon="fa-duotone fa-binary" /> Service Profiles</Link></small></p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn" onClick={() => setShowModel(false)}>
                    Close
                </button>
                <button onClick={() => addActionToRule()} className="btn btn-primary">
                    <FontAwesomeIcon icon={['fad', 'save']} /> Add Action
                </button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(ActionAddModal)