import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Hub } from '@aws-amplify/core'
import { Link } from 'react-router-dom'
import $ from 'jquery'

import { Rule } from '../../Controllers'

import { RuleTypeConverter } from '../../Helpers/TextHelpers'
import LoadingTable from '../../Helpers/Components/LoadingTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LeftNav from "../Settings/LeftNav"
const RuleShow = ({ currentCompany, currentUser }) => {
    const { t } = useTranslation()
    const [rules, setRules] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchRules = async () => {
        let results = await Rule.All(currentCompany)
        if (results) {
            setRules(results)
            setLoading(false)
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue loading your Rules'
                }
            })
        }
    }

    const deleteRule = async (rule) => {
        let deleated = await Rule.Delete(rule)
        if (deleated) {
            $('#rule_' + rule.id).hide('fast')
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Deleated.'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue with Deleting this Selection.'
                }
            })
        }
    }

    useEffect(() => {
        fetchRules()
    }, [])

    return (
        <div className="row">
            <LeftNav location={'rules'} currentUser={currentUser} currentCompany={currentCompany} />

            <div className="col-10 ml-auto pl-5">
                <div className="row">
                    <div className="col-12 pl-4">
                        <div className="row InnerContainer">
                            <div className="col-6">
                                <h2><FontAwesomeIcon icon={['fad', 'pencil-ruler']} /> {t('item.title')} Selections</h2>
                            </div>

                            <div className="col-6 text-right">
                                <Link to="/rule/new" className="btn btn-secondary"><FontAwesomeIcon icon={['fad', 'pencil-ruler']} /> New Selection</Link>
                            </div>

                            <div className="col-12">
                                <section>
                                    {loading ? <LoadingTable /> :
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Selection Type</th>
                                                <th>Delete</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rules.map(rule => (
                                                <tr id={`rule_${rule.id}`} key={rule.id}>
                                                    <td><Link to={`/rule/${rule.id}/view`}>{rule.name}</Link></td>
                                                    <td>{RuleTypeConverter(rule.type, t('item.title'))}</td>
                                                    <td>
                                                        {rule.actions.items.length > 0 && rule.conditions.items.length ? <span className="badge badge-warning">Has Attributes</span> : <button onClick={() => deleteRule(rule)} className="btn btn-danger btn-sm"><FontAwesomeIcon icon={['fad', 'trash']} /> DELETE</button>}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>}

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-warning" role="alert">
                                                <span className="badge badge-warning">Has Attributes</span>: Selections can not be deleted when it has functional attributes such as a Condition or Action attached to it.
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany,
        currentUser: state.user.currentUser
    }
}
export default connect(mapStateToProps)(RuleShow)