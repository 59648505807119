import React from "react"
import Modal from "react-bootstrap/Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Hub } from "@aws-amplify/core"
import Tooltip from '@material-ui/core/Tooltip'

const IftttModel = ({ showModel, setShowModel, currentCompany, rule }) => {
    const popCopiedToClipboard = (value) => {
        if (navigator.clipboard.writeText(value)) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success',
                    message: 'Copied to your Clipboard!'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error',
                    message: 'Sorry, there was an issue accessing your clipboard.'
                }
            })
        }
    }
    return (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon="fa-duotone fa-toolbox" /> IFTTT Setup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <p className={'text-center'}><img src={'/images/ifttt_wordmark-black-200.png'} alt={'IFTTT'} /></p>
                                <p>Copy and paste the following two items into your IFTTT Echolo IoT Applet.</p>
                            </div>
                        </div>
                        <div className={'row mb-4'}>
                            <div className={'col-3'}>
                                <strong>Rule ID</strong>
                            </div>
                            <div className={'col-9 input-group'}>
                                <div onClick={() => popCopiedToClipboard(rule.ruleId)} className="input-group-prepend FakeLink">
                                    <Tooltip title="Click to Copy" arrow><div className="input-group-text"><FontAwesomeIcon icon="fa-duotone fa-copy" /></div></Tooltip>
                                </div>
                                <Tooltip title="Click to Copy" arrow><input onClick={() => popCopiedToClipboard(rule.ruleId)} type="text" defaultValue={rule.ruleId} className="form-control FakeLink" id="inlineFormInputGroup"
                                       placeholder="RuleId"/></Tooltip>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-3'}>
                                <strong>Token</strong>
                            </div>
                            <div className={'col-9 input-group'}>
                                <div onClick={() => popCopiedToClipboard(currentCompany.token)} className="input-group-prepend FakeLink">
                                    <Tooltip title="Click to Copy" arrow><div className="input-group-text"><FontAwesomeIcon icon="fa-duotone fa-copy" /></div></Tooltip>
                                </div>
                                <Tooltip title="Click to Copy" arrow><input onClick={() => popCopiedToClipboard(currentCompany.token)} type="text" defaultValue={currentCompany.token} className="form-control FakeLink" id="inlineFormInputGroup"
                                       placeholder="Token"/></Tooltip>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn" onClick={() => setShowModel(false)}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default IftttModel
