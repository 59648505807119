import React from 'react'
import { Auth } from 'aws-amplify'

const Logout = ({ children }) => {

    const LogoutHandler = async () => {
        await Auth.signOut()
    }

    return (
        <>
            <span onClick={LogoutHandler}>{children}</span>
        </>
    )
}



export default Logout