import React, { useEffect, useState } from "react"
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"

import EcholoProfileForm from './ProfileForms/echolo'
import WebhookProfileForm from './ProfileForms/webhook'
import RedlistProfileForm from './ProfileForms/redlist'
import MysqlProfileForm from './ProfileForms/mysql'
import IftttProfileForm from './ProfileForms/ifttt'

const ProfileForm = ({ saveHandler, profileData }) => {
    let profileInit = {
        integration: 'echolo',
        serviceType: 'rule_delivery',
        methods: {
            sendMethod: 'echolo',
            auth: {
                username: '',
                password: '',
                host: '',
                database: '',
                tableName: '',
                key: '',
                secret: '',
                alt: '',
                url: '',
                method: ''
            }
        }
    }

    if (profileData) {
        profileInit = profileData
    }

    const [profile, setProfile] = useState(profileInit)
    const [methodCount, setMethodCount] = useState(0)
    const [methodListArray, setMethodListArray] = useState(null)



    const profileStateHandler = (profileUpdates) => {
        setProfile(profileUpdates)
    }

    useEffect(() => {
        console.log('running')
        saveHandler(profile)
    }, [profile])

    const loadProfileTypeForm = (sendMethod) => {
        switch (sendMethod) {
            case 'echolo':
                return <EcholoProfileForm />
            case 'webhook':
                return <WebhookProfileForm profileData={profile} saveProfileState={profileStateHandler} />
            case 'redlist':
                return <RedlistProfileForm profileData={profile} saveProfileState={profileStateHandler} />
            case 'mysql':
                return <MysqlProfileForm profileData={profile} saveProfileState={profileStateHandler} />
            case 'ifttt':
                return <IftttProfileForm />
            default:
                return 'n/a'
        }
    }

    return (
        <div className={'row'}>
            <div className={'col-12'}>
                <div className={'row'}>
                    <div className="col-12 mb-4">
                        <p>How would you like to deliver your data?</p>
                        <FormControl className="form-control" variant="outlined">
                            <InputLabel id="profileIntegrationLabel">Delivery Method</InputLabel>
                            <Select
                                fullWidth
                                labelId="profileIntegrationLabel"
                                id="profile-integration"
                                value={profile.methods.sendMethod}
                                onChange={(e) =>
                                    setProfile( {...profile, methods: { ...profile.methods, sendMethod: e.target.value } } )}>
                                <MenuItem value={'echolo'}>Echolo</MenuItem>
                                <MenuItem value={'mysql'}>MySQL</MenuItem>
                                <MenuItem value={'redlist'}>Redlist</MenuItem>
                                <MenuItem value={'webhook'}>Webhook</MenuItem>
                                <MenuItem value={'ifttt'}>IFTTT</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="col-12 mt-4">
                        { profile.methods.sendMethod ? loadProfileTypeForm(profile.methods.sendMethod) : null }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ProfileForm
