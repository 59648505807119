import { API, graphqlOperation } from 'aws-amplify'

import { createCondition, deleteRuleCondition, deleteCondition } from '../graphql/mutations'
import { listConditions } from './graphql/querys'

export const Create = async (company, condition) => {
    const input = { ...condition, conditionCompanyId: company.id }

    try {
        return (await API.graphql(graphqlOperation(createCondition, { input }))).data.createCondition
    } catch (e) {
        console.warn("Failed to Create Condtion", e)
        return false
    }
}

export const All = async (company) => {
    try {
        return (await API.graphql(graphqlOperation(listConditions))).data.listConditions.items.filter(c => c.company.id === company.id)
    } catch (e) {
        console.warn("Failed to load All Condtions", e)
        return false
    }
}

export const RemoveConditionFromRule = async (ruleConditionID) => {
    try {
        return (await API.graphql(graphqlOperation(deleteRuleCondition, { input: { id: ruleConditionID } }))).data.deleteRuleCondition
    } catch (e) {
        console.warn("Failed to remove the RuleCondition", e)
        return false
    }
}


export const Delete = async (condition) => {
    try {
        return (await API.graphql(graphqlOperation(deleteCondition, { input: { id: condition.id } }))).data.deleteCondition
    } catch (e) {
        console.warn("Failed to delete the Condition", e)
        return false
    }
}

export const FindByGeoFence = async (GeoFenceId) => {
    try {
        let geofences = (await API.graphql(graphqlOperation(listConditions))).data.listConditions.items.filter(c => c.metric === 'geofence')
        return geofences.filter(c => c.geofence.id === GeoFenceId)
    } catch (e) {
        console.warn("Failed to find Conditons with this GeoFence ID", e)
        return false
    }
}
