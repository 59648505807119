import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CreateRule } from '../../Services/Echolo/Rules/Rule'

import RuleForm from '../../Components/Rule/RuleForm'

class RuleNew extends Component {

    newRuleHandler = async (rule) => {
        const { currentCompany } = this.props

        let newRule = await CreateRule(currentCompany, rule)
        console.log("Rule in DB", newRule)
        if (newRule) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Saved!'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue saving your new Rule, please try again.'
                }
            })
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="row InnerContainer">
                <div className="col-12">
                    <h2><FontAwesomeIcon icon={['fad', 'pencil-ruler']} /> New {t('item.title')} Selection</h2>
                </div>

                <div className="col-12">
                    <section>
                       <RuleForm handler={this.newRuleHandler} /> 
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(withTranslation()(RuleNew))