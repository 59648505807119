import React from 'react'
import { connect } from 'react-redux'

import AppTopMenu from './Menu/AppTopMenu'
import AppLeftMenu from './Menu/AppLeftMenu'
import AppFooter from '../../Components/Layouts/AppFooter'


const MinimalLayout = ({ children, currentUser, currentCompany }) => {

    let locationPath = children.props.location.pathname

    return (
        <>
            <AppTopMenu locationPath={locationPath} />
            <div className="container-fluid">

                <div className="row">
                    <nav className="col sidebar">
                        <AppLeftMenu locationPath={locationPath} />
                    </nav>
                    <div className="ml-auto AppLayoutContainer">
                        <div className="row">
                            <div className="col-12 MinimalLayoutContainerBox">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter />
        </>
    )
}


const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(MinimalLayout)