import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SET_CURRENT_COMPANY } from '../../Store/Actions'


const SelectCompany = ({ currentUser, currentCompany, setCurrentCompany, handler }) => {
    const { i18n } = useTranslation()
    const [companies, setCompanies] = useState([])

    useEffect(() => {
        let isMounted = true
        const fetchCompanies = async () => {
            setCompanies(currentUser.companies.items)
        }
        if (isMounted) fetchCompanies()
        return () => { isMounted = false }
    }, [currentUser])
    
    const companySelectHandler = (company) => {
        setCurrentCompany(company)
        i18n.changeLanguage(company.type)
        if (handler) {
            handler()
        }
    }

    let companiesList = null

    if (companies) {
        companiesList = companies.map((item, key) => {
            return (
                <div className="col" onClick={() => companySelectHandler(item.company)} key={key}>
                    <section className="CompanySelectItem">
                        <div className="row">
                            <div className="col-12">
                                <h4><FontAwesomeIcon icon={['fad', 'server']} /> {item.company.name}</h4>
                                <p>{item.company.appId}</p>
                            </div>
                        </div>
                    </section>
                </div>
            )
        })
    }

    return (
        <div className="row row-cols-3">
            {companiesList}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentCompany: (company) => dispatch({ type: SET_CURRENT_COMPANY, payload: company })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(SelectCompany)