import React, { Component } from 'react'
import { connect } from 'react-redux'

import { UserAuth } from '../../Functions/Auth'

import UserPasswordForm from '../../Components/User/UserPasswordForm'

import Alert from '../../Helpers/Components/Alert'
import LeftNav from "../../Components/Settings/LeftNav"

class UserPassword extends Component {
    state = {
        notify: {
            isOpen: false,
            message: '',
            type: ''
        }
    }
    updatePasswordHandler = async (userPasswords) => {
        if (userPasswords.newPW !== userPasswords.newPWRetype) {
            this.setState({
                notify: {
                    isOpen: true,
                    message: 'Your new passwords must match. Please try again.',
                    type: 'error'
                }
            })
        } else {
            let updated = await UserAuth.UpdatePassword(userPasswords)
            if (updated === "SUCCESS") {
                this.setState({
                    notify: {
                        isOpen: true,
                        message: 'Your password has been updated!',
                        type: 'success'
                    }
                })
            } else {
                this.setState({
                    notify: {
                        isOpen: true,
                        message: 'Sorry, there was an issue updating your password, please try again.',
                        type: 'warning'
                    }
                })
            }
        }
    }

    alertCloseHandler = () => {
        this.setState({
            notify: { ...this.state.notify, isOpen: false }
        })
    }

    render() {
        const { notify, passwordData } = this.state
        return (
            <div className="row">
                <LeftNav location={'password'} currentUser={this.props.currentUser} currentCompany={this.props.currentCompany} />

                <div className="col-10 ml-auto pl-5">
                    <div className="row">
                        <div className="col-12 pl-4">
                            <div className="row InnerContainer">
                                <Alert notify={notify} setNotify={this.alertCloseHandler} />
                                <div className="col-12">
                                    <h2>Change your password</h2>
                                </div>
                                <div className="col-12">
                                    <section>
                                        <UserPasswordForm passwordData={passwordData} handler={this.updatePasswordHandler} />
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(UserPassword)