import { Auth, Hub } from 'aws-amplify'

import { User } from '../../Controllers'

export const SignIn = async () => {
    const user = await Auth.currentAuthenticatedUser()
    const { attributes } = user

    let dbUser = await User.Find({ email: attributes.email })
    return dbUser
}

export const RefreshCurrentUser = async () => {
    const user = await SignIn()
    // send message to hub for an update
    Hub.dispatch('User', {
        event: 'update',
        data: user
    })
}

export const SignOut = async () => {
    return await Auth.signOut()
}

export const CurrentUser = async () => {
    const user = await Auth.currentAuthenticatedUser()
    const { attributes } = user
    return attributes
}

export const CurrentUserObj = async () => {
    return await Auth.currentAuthenticatedUser()
}

export const Update = async (updatedUser) => {
    console.log("USERAUTH Update")
    try {
        let user = await CurrentUserObj()
        return await Auth.updateUserAttributes(user, {
            name: updatedUser.first_name,
            family_name: updatedUser.last_name,
            email: updatedUser.email
        })
    } catch (e) {
        console.warn("Failed to Update the user in Cognito", e)
    }
}

export const UpdatePassword = async (updatedPasswords) => {
    try {
        let user = await CurrentUserObj()
        return await Auth.changePassword(user, updatedPasswords.currentPW, updatedPasswords.newPW)
    } catch (e) {
        console.warn("Failed to Update the users Password in Cognito", e)
        return false
    }
}