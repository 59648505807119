import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { SET_CURRENT_USER } from '../../Store/Actions'
import { Hub } from '@aws-amplify/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { User } from '../../Controllers'
const UserAlertSettingsForm = ({ currentUser, setCurrentUser }) => {
    const [alertSettings, setAlertSettings] = useState({ notify_sms: false, notify_email: false })

    useEffect(() => {
        if (currentUser.notify_sms || currentUser.notify_email) {
            setAlertSettings({ notify_sms: currentUser.notify_sms, notify_email: currentUser.notify_email })
        }
    }, [currentUser])

    const submitHandler = async (event) => {
        let updatedUser = { id: currentUser.id, ...alertSettings }
        event.preventDefault()

        let results = await User.UpdateWOAuth(updatedUser)
        if (results) {
            setCurrentUser(results)
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success',
                    message: 'Saved!'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: "Sorry, there was an issue saving; please try again."
                }
            })
        }
    }

    const selectSms = () => {
        setAlertSettings({ ...alertSettings, notify_sms: !alertSettings.notify_sms })
    }

    const selectEmail = () => {
        setAlertSettings({ ...alertSettings, notify_email: !alertSettings.notify_email })
    }

    return (
        <div className="row">
            <div className="col-12">
                <section>
                    <h4>Alert Notification Settings</h4>
                    <p>Alert Notifications are used when account Alerts are set up with a 'Notify' action. When using SMS, standard text messaging rates apply for your mobile provider.</p>
                    <form onSubmit={submitHandler}>
                        <div className="row">
                            <div className="col-12">
                                <div className="custom-control custom-switch">
                                    <input onChange={() => selectSms()} checked={alertSettings.notify_sms} type="checkbox" className="custom-control-input" id="smsSwitch" />
                                    <label className="custom-control-label" htmlFor="smsSwitch">SMS Alerts</label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="custom-control custom-switch">
                                    <input onChange={() => selectEmail()} checked={alertSettings.notify_email} type="checkbox" className="custom-control-input" id="emailSwitch" />
                                    <label className="custom-control-label" htmlFor="emailSwitch">Standard Email Alerts</label>
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <button className="btn btn-primary"><FontAwesomeIcon icon={['fad', 'save']} /> Update</button>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentUser: (user) => dispatch({ type: SET_CURRENT_USER, payload: user })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(UserAlertSettingsForm)