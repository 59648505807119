import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'

import { Sensor } from '../../Controllers'

import SensorForm from '../../Components/Sensor/SensorForm'

class SensorEdit extends Component {
    state = {
        sensor: null
    }

    fetchSensor = async () => {
        let sensorData = await Sensor.Find(this.props.currentCompany, this.props.match.params.id)
        let tags = []

        sensorData.tags.items.forEach(tagSensor => {
            tags.push(tagSensor.tag.name)
        })

        this.setState({
            sensor: { ...sensorData, tags: tags }
        })
    }

    componentDidMount = () => {
        this.fetchSensor()
    }

    updateHandler = async (sensor) => {
        let results = await Sensor.Update(this.props.currentCompany, this.props.match.params.id, sensor)
        
        if (results) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Saved!'
                }
            })
            this.props.history.push('/sensor')
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue saving your Sensor, please try again.'
                }
            })
        }
    }

    render() {
        const { sensor } = this.state
        return (
            <div className="row InnerContainer">
                <div className="col-12">
                    <h2>Edit Sensor {this.props.match.params.id}</h2>
                </div>

                <div className="col-12">
                    <section>
                        { sensor ? <SensorForm saveHandler={this.updateHandler} sensorData={sensor} /> : null }
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(SensorEdit)