import React, { useState, useEffect } from 'react'

import { Widget } from '../../Controllers'

import Text from './Types/Text'
import Graph from './Types/Graph'
import List from './Types/List'

const WidgetList = ({ dashboard }) => {
    const [widgets, setWidgets] = useState([])
    const [widgetWidth, setWidgetWidth] = useState('col')

    const subscribeToWidgets = () => {
        // On create
        Widget.CreateSubscription().subscribe({
            next: onCreateWidget => {
                let newWidget = onCreateWidget.value.data.onCreateWidget
                if (newWidget.dashboard.id === dashboard.id) {
                    getWidgets()
                }
            }
        })
        // On update
        Widget.UpdateSubscription().subscribe({
            next: onUpdateWidget => {
                let updatedWidget = onUpdateWidget.value.data.onUpdateWidget
                if (updatedWidget.dashboard.id === dashboard.id) {
                    let widgetArray = widgets.filter(w => w.id !== updatedWidget.id)
                    setWidgets([ ...widgetArray, updatedWidget ])
                }
            }
        })
        // On delete
        Widget.DeleteSubscription().subscribe({
            next: onDeleteWidget => {
                let deleatedWidget = onDeleteWidget.value.data.onDeleteWidget
                if (deleatedWidget.dashboard.id === dashboard.id) {
                    //let widgetArray = widgets.filter(w => w.id !== deleatedWidget.id)
                    getWidgets()
                    //setWidgets([ ...widgetArray ])
                }
            }
        })
    }

    const getWidgets = async () => {
        let widgets = await Widget.All(dashboard)
        setWidgets(widgets)
    }

    const fetchWidgets = async () => {
        //setWidgets(dashboard.widgets.items)
        getWidgets()
    }

    const getWidgetWidth = (widget) => {
        switch (widget.range) {
            case 'now-6h':
                return 'col-6'
            case 'now-7h':
                return 'col-6'
            case 'now-8h':
                return 'col-6'
            case 'now-9h':
                return 'col-6'
            case 'now-10h':
                return 'col-6'
            case 'now-11h':
                return 'col-6'
            case 'now-12h':
                return 'col-6'
            case 'now-1d':
                return 'col-6'
            case 'now-2d':
                return 'col-6'
            case 'now-3d':
                return 'col-6'
            default:
                return 'col'
        }
    }

    useEffect(() => {
        fetchWidgets()
        subscribeToWidgets()
    }, [])

    return (
        <>
            { widgets.map((widget) => (
                <div id={`widget_${widget.id}`} key={widget.id} className={getWidgetWidth(widget)}>
                    <section>
                        { widget.type === 'text' ? <Text widgetData={widget} /> : null }
                        { widget.type === 'graph' ? <Graph widgetData={widget} /> : null }
                        { widget.type === 'list' ? <List widgetData={widget} /> : null }
                    </section>
                </div>
            )) }
        </>
    )
}

export default WidgetList