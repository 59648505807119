import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TagChip from '../Tag/TagChip'


const TagTable = ({ tags, deleteTag }) => {
    const { t } = useTranslation()
    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Usage</th>
                    <th scope="col">Type</th>
                    <th scope="col">Options</th>
                </tr>
            </thead>

            <tbody>
                {tags.map((row, key) => (
                    <tr key={key}>
                        
                        <td><TagChip tag={row} /></td>
                        { row.items.items.length > 0 ? <td>{row.items.items.length}</td> : null }
                        { row.sensors.items.length > 0 ? <td>{row.sensors.items.length}</td> : null }
                        { row.sensors.items.length === 0 && row.items.items.length === 0 ? <td>0</td> : null }
                        <td>{row.type ? t(row.type + '.title') : 'n/a'}</td>
                        
                        <td>
                            { row.sensors.items.length === 0 && row.items.items.length === 0 ? <button onClick={() => deleteTag(row.id)} className="btn btn-sm btn-danger"><FontAwesomeIcon icon={['fad', 'trash']} /> Delete</button> : null }
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default TagTable
