import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { SET_SESSION_USERNAME, SET_REGISTERED_USER } from '../../Store/Actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Register = ({ setSessionUsername, setRegisterUser }) => {
    const logValues = {
        email: '',
        password: '',
        first_name: '',
        last_name: ''
    }

    const { register, handleSubmit, errors } = useForm()

    const [userRegister, setUserRegister] = useState(logValues)
    const [confirmPage, setConfirmPage] = useState(false)
    const [showalert, setShowalert] = useState(null)

    const resgisterHandler = async (userData) => {
        try {
            Auth.signUp({
                email: userData.email,
                username: userData.email,
                password: userData.password,
                attributes: {
                    name: userData.first_name,
                    family_name: userData.last_name
                }
            }).then(cognito => {
                userData.cognito_id = cognito.userSub

                // Remove the password
                delete userData.password
                
                setRegisterUser(userData)
                setSessionUsername(cognito.user.username)
                setConfirmPage(true)
            })
            
            console.log(userData)
            
        } catch (error) {
            // Cognito Error
            console.log('error signing up:', error)
            setShowalert(<div className="alert alert-danger" role="alert">
                            I am sorry! There was an issue. {error.message}
                        </div>)
        }
    }

    let redirect = null
    if (confirmPage) redirect = <Redirect to="/register/confirm" />

    return (
        <div className="row justify-content-md-center Register">
            <div className="col-12">
                <section className="iotbg-light">
                    <div className="RegisterForm">
                        {showalert}
                        <h3 className="text-center text-dark">Register</h3>
                        <p className="text-center text-subtext">Create a new account with Explorer.</p>

                        {redirect}
                        <form onSubmit={handleSubmit(resgisterHandler)}>
                            {errors.email && <p className="text-danger">Email is required.</p>}
                            {errors.password && <p className="text-danger">Password is required.</p>}
                            {errors.first_name && <p className="text-danger">Your First Name is required.</p>}
                            {errors.last_name && <p className="text-danger">Your Last Name is required.</p>}
                            <div className="form-group">
                                <label htmlFor="email">Email Address:</label>
                                <input 
                                value={userRegister.email}
                                name="email"
                                onChange={e => setUserRegister({ ...userRegister, email: e.target.value })}
                                placeholder="Email Address"
                                ref={register({ required: true })}
                                type="email" className="form-control form-control-lg" id="email" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="first_name">First Name:</label>
                                <input 
                                value={userRegister.first_name}
                                name="first_name"
                                onChange={e => setUserRegister({ ...userRegister, first_name: e.target.value })}
                                placeholder="First Name"
                                ref={register({ required: true })}
                                type="text" className="form-control form-control-lg" id="first_name" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="first_name">Last Name:</label>
                                <input 
                                value={userRegister.last_name}
                                name="last_name"
                                onChange={e => setUserRegister({ ...userRegister, last_name: e.target.value })}
                                placeholder="Last Name"
                                ref={register({ required: true })}
                                type="text" className="form-control form-control-lg" id="last_name" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="first_name">Password:</label>
                                <input 
                                value={userRegister.password}
                                name="password"
                                onChange={e => setUserRegister({ ...userRegister, password: e.target.value })}
                                placeholder="Password"
                                ref={register({ required: true })}
                                type="password" className="form-control form-control-lg" id="password" />
                                <small id="emailHelp" className="form-text text-muted">Your password must be at least 8 characters long with a minimum of one uppercase letter and one special character.</small>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-outline-primary btn-lg btn-block">Register <FontAwesomeIcon icon={['fad', 'chevron-right']} /></button>
                                </div>
                                <div className="col-12">
                                    <p className="text-size-medium">Already have an account? <Link to="/">Signin here</Link>.</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    )
    
}

const mapDispactToProps = dispatch => {
    return {
        setSessionUsername: (username) => dispatch({ type: SET_SESSION_USERNAME, payload: username }),
        setRegisterUser: (user) => dispatch({ type: SET_REGISTERED_USER, payload: user })
    }
}
export default connect(null, mapDispactToProps)(Register)
