import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-bootstrap/Modal'
import { TextField, FormControl, InputLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Dashboards, Item } from '../../Controllers'

import Loading from '../../Helpers/Components/Loading'
import TagForm from '../../Components/Tag/TagForm'

import ItemTypes from '../../Config/ItemTypes'

const DashboardCreateModal = ({ showModel, setShowModel, currentUser, currentCompany }) => {
    const { t } = useTranslation()
    const initDashboard = { name: '', type: 'standard', item: '', itemType: '' }
    const [dashboard, setDashboard] = useState(initDashboard)
    const [items, setItems] = useState(null)
    const [tags, setTags] = useState([])
    const [mapFilterType, setMapFilterType] = useState(null)

    const createDashboardHandler = async (event) => {
        event.preventDefault()

        // Check for Map and Map Type
        if (dashboard.type === 'map') {
            if (mapFilterType === 'tags') {
                setDashboard({ ...dashboard, itemType: '' })
            } else {
                setDashboard({...dashboard, tags: null})
            }
        }

        let newdash = await Dashboards.Create(currentCompany, currentUser, dashboard)
        // !! Dashboards.Create NEEDS the same result as the DashboardGet
        
        if (newdash) {
            setShowModel(false)
            setDashboard(initDashboard)
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Your new Dasboard has been created and is ready to use!'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue creating your Dashboard, please try again.'
                }
            })
        }
    }

    const selectDashboardType = async (e) => {
        if (e.target.value === 'item') {
            let itemsForSelect = await Item.All(currentCompany)
            setItems(itemsForSelect)
        } else {
            setItems(null)
            setDashboard( {...dashboard, item: ''} )
        }
        setDashboard( {...dashboard, type: e.target.value} )
    }

    const selectTags = async (tags) => {
        setTags(tags)
        setDashboard( {...dashboard, tags: tags} )
    }

    return (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            <form onSubmit={createDashboardHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a new Dashboard</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-12">
                            <h4>Dashboard Types</h4>
                            <p>Dashboard types allow you to create a custom view across your sensors or for specific {t('item.titles')}.</p>
                            <ul>
                                <li><strong>Standard:</strong> This Dashboard type will allow you to add any sensor on your account as a widget.</li>
                                <li><strong>{t('item.title')}:</strong> The {t('item.title')} Dashboard type restricts the sensors to just the ones attached to the {t('item.title')}.</li>
                                <li><strong>Map:</strong> A Map dashboard will allow you to view {t('item.title')} locations on a map via {t('item.title')} tags.</li>
                            </ul>
                        </div>

                        <div className="col-12 mt-4">
                            <div className="form-group">
                                <FormControl className="form-control" variant="outlined">
                                    <InputLabel id="dashboardTypeSelect">Dashboard Type</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="dashboardTypeSelect"
                                        id="dashboardTypeSelect"
                                        value={dashboard.type}
                                        onChange={(e) => selectDashboardType(e)}>
                                            <MenuItem value={'standard'}>Standard</MenuItem>
                                            <MenuItem value={'item'}>{t('item.title')}</MenuItem>
                                            <MenuItem value={'map'}>Map</MenuItem>
                                    </Select>
                                </FormControl>
                                { dashboard.type === 'item' && !items ? <Loading isOpen={true} /> : null }
                            </div>
                        </div>

                        { dashboard.type === 'map' ? 
                            <RadioGroup className="col-12 mt-4" row aria-label="position" name="position" defaultValue="top">
                                <p>{t('item.title')} Tags or {t('item.title')} Types can filter map Dashboards. For example, you may want to see all {t('item.title')} that have the tag "Green" or all {t('item.title')} that have the type "Vehicle."</p>
                                <div className="col-6 mt-1">
                                    <FormControlLabel value="tags" control={<Radio checked={mapFilterType === 'tags'} onClick={() => setMapFilterType('tags')} color="primary" />} label={`${t('item.title')} Tags`} />
                                </div>

                                <div className="col-6 mt-1">
                                    <FormControlLabel value="type" control={<Radio checked={mapFilterType === 'type'} onClick={() => setMapFilterType('type')} color="primary" />} label={`${t('item.title')} Type`} />
                                </div>
                            </RadioGroup>
                        : null }

                        { dashboard.type === 'map' && mapFilterType === 'type' ?
                        <div className="col-12 mt-4">
                            <div className="form-group">
                                <FormControl className="form-control" variant="outlined">
                                    <InputLabel id="dashboardItemSelect">{t('item.title')}</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="dashboardItemSelect"
                                        id="dashboardItemSelect"
                                        value={dashboard.itemType}
                                        onChange={(e) => setDashboard( {...dashboard, itemType: e.target.value} )}>
                                            {ItemTypes[currentCompany.type].map((type, key) => (
                                                <MenuItem key={key} value={type}>{type}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div> 
                        : null }

                        { dashboard.type === 'map' && mapFilterType === 'tags' ?
                        <div className="col-12 mt-4">
                            <TagForm handler={selectTags} defaultTags={tags} />
                        </div> : null }

                        { items ?
                        <div className="col-12 mt-4">
                            <div className="form-group">
                                <FormControl className="form-control" variant="outlined">
                                    <InputLabel id="dashboardItemSelect">{t('item.title')}</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="dashboardItemSelect"
                                        id="dashboardItemSelect"
                                        value={dashboard.item}
                                        onChange={(e) => setDashboard( {...dashboard, item: e.target.value, name: e.target.value.name} )}>
                                            {items.map(item => (
                                                <MenuItem key={item.id} value={item}>{item.name}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div> : null }


                        <div className="col-12 mt-4">
                            <div className="form-group">
                                <TextField className="form-control" variant="outlined" value={dashboard.name} label="Dashboard Name" onChange={(e) => setDashboard({ ...dashboard, name: e.target.value })} name="name" required />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn" onClick={() => setShowModel(false)}>
                        Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                        <FontAwesomeIcon icon={['fad', 'save']} /> Save Changes
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(DashboardCreateModal)
