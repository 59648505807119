import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { GeoFence } from '../../../Controllers'

import GeoFenceBoxView from '../../../Components/Map/GeoFence/GeoFenceBoxView'

class GeoFenceIndex extends Component {
    state = {
        geofences: [],
        loading: true
    }

    fetchGeoFences = async () => {
        const { t } = this.props
        let results = await GeoFence.All(this.props.currentCompany)
        if (results) {
            this.setState({
                geofences: results,
                loading: false
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue finding your '+t('geofence.title_plural')+', please try reloading this page'
                }
            })
        }
    }

    componentDidMount = () => {
        this.fetchGeoFences()
    }

    render() {
        const { t } = this.props
        return (
            <div className="row InnerContainer">
                <div className="col-12">
                    <h2><FontAwesomeIcon icon={['fad', 'location-arrow']} /> {t('geofence.title_plural')}</h2>
                </div>
                <div className="col-12">
                    <GeoFenceBoxView geoFenceData={this.state.geofences} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(withTranslation()(GeoFenceIndex))