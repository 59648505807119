import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Skeleton from '@material-ui/lab/Skeleton'
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import LoadingTable from "../../Helpers/Components/LoadingTable"
import LeftNav from "../../Components/Settings/LeftNav"

import ProfileMethods from '../../Components/Profile/ProfileMethods'

import { GetProfileById } from '../../Services/Echolo/Profile'
import { capitalize } from '../../Helpers/TextHelpers'

class ProfileShow extends Component {
    state = {
        serviceId: '',
        loading: true,
        profile: null
    }

    fetchProfile = async () => {
        const { currentCompany } = this.props
        const profile = await GetProfileById(currentCompany, this.props.match.params.id)
        console.log(profile)
        this.setState({
            profile: profile,
            loading: false
        })
    }

    componentDidMount() {
        this.setState({
            serviceId: this.props.match.params.id
        })
        this.fetchProfile()
    }

    render() {
        const { serviceId, loading, profile } = this.state
        return (
            <div className="row">
                <LeftNav location={'profiles-index'} currentUser={this.props.currentUser} currentCompany={this.props.currentCompany} />

                <div className="col-10 ml-auto pl-5">
                    <div className="row">
                        <div className="col-12 pl-4">
                            <div className="row InnerContainer">
                                <div className="col-12">
                                    <h2><FontAwesomeIcon icon={['fad', 'binary']} /> Profile: {loading ? <Skeleton variant="text" /> : capitalize(profile.integration)}</h2>
                                </div>

                                {loading ? <Skeleton variant="rect" height={50} /> :
                                    <>
                                        <hr />
                                        <div className="col-6">
                                            <h5>Service ID: <span>{profile.serviceId}</span></h5>
                                        </div>
                                        <div className="col-6">
                                            <h5>Configured RSSI: <span>{profile.configuredRssi}</span></h5>
                                        </div>
                                        <hr />
                                    </>
                                }

                                <div className="col-12">
                                    { loading ? <section><LoadingTable /></section> :
                                        <div className="row row-cols-3">
                                            <ProfileMethods methods={JSON.parse(profile.methods)} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany,
        currentUser: state.user.currentUser
    }
}
export default connect(mapStateToProps)(withTranslation()(ProfileShow))