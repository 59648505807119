import { EcholoApi, Headers } from '../config'

export const GetAllActions = async (application, ruleId) => {
    try {
        const actions = await EcholoApi.get('/rules/' + ruleId + '/actions', Headers(application))
        return actions.data
    } catch (error) {
        console.log(error)
        return false
    }
}

export const CreateAction = async (application, ruleId, actionData) => {
    try {
        const action = await EcholoApi.post('/rules/' + ruleId + '/actions', actionData, Headers(application))
        return action.data
    } catch (error) {
        console.log(error)
        return false
    }
}

export const RemoveAction = async (application, ruleId, actionId) => {
    try {
        await EcholoApi.delete('/rules/' + ruleId + '/actions/' + actionId, Headers(application))
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}