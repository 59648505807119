import React, { useState } from 'react'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Company } from '../../Controllers'

const CompanyInviteForm = ({ currentUser, currentCompany }) => {
    const initInvite = {
        email: ''
    }
    const [invite, setInvite] = useState(initInvite)

    const handleInvite = async (event) => {
        event.preventDefault()
        console.log(invite)
        const result = await Company.InviteUser(currentUser, currentCompany, invite)
        console.log(result)
    }

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={handleInvite}>
                    <div className="col-auto mb-4">
                        <div className="form-group">
                            <TextField required value={invite.email} type="email" onChange={(e) => setInvite({ ...invite, email: e.target.value })} className="form-control" label="Email Address" variant="outlined" />
                        </div>
                    </div>

                    <div className="col-auto mb-4 text-right">
                        <button className="btn btn-primary" type="submit"><FontAwesomeIcon icon={['fad', 'mailbox']} /> Invite</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(CompanyInviteForm)