import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Company } from '../../Controllers'

import UserTable from '../../Components/User/UserTable'
import LeftNav from "../../Components/Settings/LeftNav"

class CompanyUsers extends Component {
    state = {
        users: []
    }

    fetchCompanyUsers = async () => {
        const { currentCompany } = this.props
        const users = await Company.Users(currentCompany)
        this.setState({
            users: users
        })
    }

    subscribeToCompanyUsers = async () => {
        const { currentCompany } = this.props

        Company.UsersSubscription().subscribe({
            next: userAndCompany => {
                let uc = userAndCompany.value.data.onUpdateUserCompanies

                if (uc.company.id === currentCompany.id) {
                    uc.user.level = uc.level
                    uc.user.company_user_status = uc.status
                    
                    // Remove the updated user from the users state
                    let updatedUserList = this.state.users.filter(u => u.id !== uc.user.id)

                    // Put the list back together with the now updated user data
                    let updatedUsers = [ ...updatedUserList, uc.user]

                    this.setState({
                        users: updatedUsers
                    })
                }
            }
        })
    }

    componentDidMount = () => {
        this.fetchCompanyUsers()

        // Subscribe to updates.
        this.subscribeToCompanyUsers()
    }

    render() {
        const { users } = this.state
        const { currentCompany, currentUser } = this.props
        return (
            <div className="row">
                <LeftNav location={'company-users'} currentUser={this.props.currentUser} currentCompany={this.props.currentCompany} />

                <div className="col-10 ml-auto pl-5">
                    <div className="row">
                        <div className="col-12 pl-4">
                            <div className="row InnerContainer">
                                <div className="col-12">
                                    <h2>People</h2>
                                </div>

                                <div className="col-12">
                                    <section>
                                        <UserTable currentUser={currentUser} currentCompany={currentCompany} users={users} />
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

export default connect(mapStateToProps)(CompanyUsers)