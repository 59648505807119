import React, { useState, useEffect } from 'react'
import { Chip } from '@material-ui/core'
import { Hub } from '@aws-amplify/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'

import { RemoveAction } from '../../../Services/Echolo/Rules/Action'
import { GetProfileById } from "../../../Services/Echolo/Profile"
import { BuildDeliveryTags } from "../../../Helpers/ProfileHelpers"

const ActionTable = ({ actionData = null, ruleData, currentCompany, isUpdatingHandler }) => {
    const [actions, setActions] = useState(actionData)

    const getProfileMethodTags = (profile) => {
        return BuildDeliveryTags(profile.methods)
    }

    useEffect(() => {
        setActions(actionData)
    }, [actionData])


    const removeActionFromRuleHandler = async (ruleAction) => {
        let removed = await RemoveAction(currentCompany, ruleAction.ruleId, ruleAction.actionId)
        if (!removed) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, we could not remove this Action.'
                }
            })
        } else {
            $('#ruleaction_'+ ruleAction.actionId).hide('fast')
            isUpdatingHandler()
        }
    }

    return (
        <>
            <table className="table table-sm table-hover">
                <thead>
                    <tr>
                        <th scope="col">Action ID</th>
                        <th scope="col">Service ID</th>
                        <th scope="col">Delivery To</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {actions.map((action) => (
                        <tr id={`ruleaction_${action.actionId}`} key={action.actionId}>
                            <td>{action.actionId}</td>
                            <td>{action.serviceId}</td>
                            <td>{getProfileMethodTags(action.profile)}</td>
                            <td><button onClick={() => removeActionFromRuleHandler(action)} className="btn btn-sm btn-outline-danger"><FontAwesomeIcon icon="fa-duotone fa-trash-can" /> Remove</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default ActionTable