import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ConditionForm from '../ConditionForm'

import { CreateCondition } from "../../../Services/Echolo/Rules/Condition"
const ConditionAddModal = ({ showModel, setShowModel, currentCompany, ruleData, isUpdatingHandler }) => {
    const { t } = useTranslation()
    const [condition, setCondition] = useState(null)

    const conditionHandler = (type, data) => {
        setCondition({ ...condition, ...data })
    }

    useEffect(() => {
        //fetchConditions()
    }, [])


    const addConditionToRule = async () => {
        const newCondition = await CreateCondition(currentCompany, condition)

        if (newCondition) {
            setShowModel(false)
            isUpdatingHandler()
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Added.'
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'Sorry, there was an issue adding this Condition.'
                }
            })
        }
    }

    return (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add a Condition</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="form-group">
                            <ConditionForm wizardHandler={conditionHandler} inWizard={true} currentCompany={currentCompany} rule={ruleData} />
                        </div>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn" onClick={() => setShowModel(false)}>
                    Close
                </button>
                <button onClick={() => addConditionToRule()} className="btn btn-primary">
                    <FontAwesomeIcon icon={['fad', 'save']} /> Add Condition
                </button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(ConditionAddModal)