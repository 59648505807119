import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Hub } from '@aws-amplify/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Rule, Condition, Action } from '../../Controllers'
import { CreateRule } from '../../Services/Echolo/Rules/Rule'

import RuleForm from '../../Components/Rule/RuleForm'
import ConditionForm from '../../Components/Rule/ConditionForm'
import ActionForm from '../../Components/Rule/ActionForm'

const RuleWizard = ({ currentCompany }) => {
    const [step, setStep] = useState(1)
    const [alert, setAlert] = useState({})
    const [alertTies, setAlertTies] = useState({})

    const stepHandler = (direction) => {
        switch(step) {
            case 1:
                saveRule()
                break
            case 2:
                saveCondition()
                break
            default:
                return
        }
        if (direction === 'prev' && step !== 1) {
            setStep(step - 1)
        }
        if (direction === 'next' && step !== 3) {
            setStep(step + 1)
        }
    }

    const wizardHandler = (type, data) => {
        switch(type) {
            case 'rule':
                setAlert({ ...alert, rule: data })
                break
            case 'condition':
                setAlert({ ...alert, condition: data })
                break
            case 'action':
                setAlert({ ...alert, action: data })
                break
            default:
                return
        }
    }

    const saveRule = async () => {
        const rule = await CreateRule(currentCompany, alert.rule)
        if (rule) {
            setAlertTies({ ...alertTies, rule: rule })
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Saved!'
                }
            })
        } else {
            setStep(1)
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'There was an issue creating your Rule, please try again.'
                }
            })
        }
    }

    const saveCondition = async () => {
        let condition = await Condition.Create(currentCompany, alert.condition)
        if (condition) {
            setAlertTies({ ...alertTies, condition: condition })
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Saved!'
                }
            })
        } else {
            setStep(2)
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'There was an issue creating your Condition, please try again.'
                }
            })
        }
    }

    const saveAction = async () => {
        let action = await Action.Create(currentCompany, alert.action)
        if (action) {
            setAlertTies({ ...alertTies, action: action })
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: 'Saved!'
                }
            })
            return action
        } else {
            setStep(3)
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'There was an issue creating your Action, please try again.'
                }
            })
        }
    }

    const finishHandler = async () => {
        let action = await saveAction()

        if (alertTies.rule && alertTies.condition && action) {
            let conditionTie = await Rule.CreateRuleCondition(alertTies.rule, alertTies.condition)
            let actionTie = await Rule.CreateRuleAction(alertTies.rule, action)

            if (conditionTie && actionTie) {
                Hub.dispatch('Alert', {
                    event: 'show',
                    data: {
                        type: 'success', 
                        message: 'Your new Alert is active and ready for use.'
                    }
                })
            } else {
                Hub.dispatch('Alert', {
                    event: 'show',
                    data: {
                        type: 'error', 
                        message: 'There was an issue creating your Alert, please try again.'
                    }
                })
            }
        } else {
            setStep(1)
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: 'The Alert Wizard has failed, you may need to start over again.'
                }
            })
        }
    }

    return (
        <div className="row InnerContainer">
            <div className={'col-12'}>
                <h2>Rule Wizard</h2>
            </div>
            <div className="col-12">
                <section>
                    <div className="row">
                        <div className="col-12">
                            {step === 1 ? <RuleForm wizardHandler={wizardHandler} inWizard={true} /> : null }
                            {step === 2 ? <ConditionForm wizardHandler={wizardHandler} inWizard={true} /> : null }
                            {step === 3 ? <ActionForm wizardHandler={wizardHandler} inWizard={true} /> : null }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col RuleWizardStep">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <p className={step > 1 ? 'MutedText' : ''}><span className={step > 1 ? `badge badge-pill badge-dark` : `badge badge-pill badge-light`}>1</span> Create a new Rule</p>
                                </div>
                            </div>
                        </div>

                        <div className="col RuleWizardStep">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <p className={step > 2 ? 'MutedText' : ''}><span className={step > 2 ? `badge badge-pill badge-dark` : `badge badge-pill badge-light`}>2</span> Add conditions to your new Rule.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col RuleWizardStep">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <p className={step > 3 ? 'MutedText' : ''}><span className={step > 3 ? `badge badge-pill badge-dark` : `badge badge-pill badge-light`}>3</span> Finish up by adding an Action to take.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6"><button onClick={() => stepHandler('prev')} className="btn btn-muted"><FontAwesomeIcon icon={['fad', 'chevron-double-left']} /> Prev</button></div>
                        <div className="col-6 text-right">
                            {step === 3 ?
                                <button onClick={() => finishHandler()} className="btn btn-success">Finish <FontAwesomeIcon icon={['fad', 'save']} /></button>
                            : <button onClick={() => stepHandler('next')} className="btn btn-primary">Next <FontAwesomeIcon icon={['fad', 'chevron-double-right']} /></button> }
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentCompany: state.user.currentCompany
    }
}
export default connect(mapStateToProps)(RuleWizard)