import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import { Link, Redirect } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as UrlParamHelper from '../../Helpers/UrlParams'

const ForgotPassword = (props) => {
    const params = UrlParamHelper.getParams(props.location.search)
    let user = ''
    try {
        user = params[0].user
    } catch (e) {
        user = ''
    }
    const userState = {
        email: user
    }

    const { register, handleSubmit, errors } = useForm()
    const [userForm, setUserForm] = useState(userState)
    const [passwordRedirect, setPasswordRedirect] = useState(null)
    const [showalert, setShowalert] = useState(null)

    const resetFormHandler = (formData) => {
        Auth.forgotPassword(formData.email).then(data => {
            setPasswordRedirect(<Redirect to={"/register/resetpassword?user=" + formData.email} />)
        }).catch(err => {
            setShowalert(<div className="alert alert-danger" role="alert">
                            I am sorry! There was an issue. {err.message}
                        </div>)
        })
    }

    return (
        <div className="row justify-content-md-center">
            <div className="col-12">
                <section>
                    <div className="LoginForm">
                        {passwordRedirect}
                        {showalert}
                        {userForm.email === '' ?
                            <>
                                <h3 className="text-center text-dark">Forget your Password?</h3>
                                <p className="text-center text-subtext">No worries, we've got you! We'll send and email with a verification code allowing you to reset your password.</p>
                            </>
                        : 
                            <>
                                <h3 className="text-center text-dark">Please Reset Your Password</h3>
                                <p className="text-center text-subtext">No worries, we've got you! We'll send and email with a verification code allowing you to reset your password.</p>
                            </>
                        }
                        <form onSubmit={handleSubmit(resetFormHandler)}>
                            <div className="form-group">
                                {errors.email && <p className="text-danger">Email Address is required.</p>}
                                <label htmlFor="code">Email Address:</label>
                                <input 
                                value={userForm.email}
                                name="email"
                                onChange={e => setUserForm({ ...userForm, email: e.target.value })}
                                placeholder="Email Address"
                                ref={register({ required: true })}
                                type="text" className="form-control form-control-lg" id="email" />
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-outline-primary btn-lg btn-block">Forgot Password Reset <FontAwesomeIcon icon={['fad', 'chevron-right']} /></button>
                                </div>
                                <div className="col-12">
                                    <p className="text-size-medium"><Link to="/">Back to Login</Link></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ForgotPassword