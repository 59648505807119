import React, { Component } from 'react'

import CompanyActivate from '../../Components/Company/CompanyActivate'
import CompanyNew from './CompanyNew'
import { Redirect } from 'react-router-dom'

class CompanyWelcome extends Component {
    state = {
        activateJoin: false,
        createNew: false,
        redirect: null
    }


    activateJoinHandler = () => {
        this.setState({
            activateJoin: true
        })
    }

    createNewHandler = () => {
        this.setState({
            createNew: true
        })
    }

    cancelHandler = () => {
        this.setState({
            activateJoin: false,
            createNew: false
        })
    }

    activationHandler = (isDone, error) => {
        if (isDone) {
            this.setState({
                redirect: <Redirect to="/dashboard" />
            })
        }
    }

    render() {
        const { activateJoin, createNew, redirect } = this.state

        if (redirect) return ( redirect )
        
        return (
            <div className="row">

                <div className="col-12">
                    <section>
                        <div className="row">
                            <div className="col-12 text-center">
                                <h4>Welcome to Explorer</h4>
                            </div>
                        </div>
                    { !activateJoin && !createNew ?
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <button onClick={() => this.activateJoinHandler()} className="btn btn-lg btn-block btn-primary">Join / Activate a current Application</button>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12">
                                    <button onClick={() => this.createNewHandler()} className="btn btn-lg btn-block btn-light">Create a new Application</button>
                                </div>
                            </div>
                        </>
                    : null }
                    </section>
                </div>
                
                { activateJoin ? 
                <div className="col-12 mt-2">
                    
                    <CompanyActivate cancel={this.cancelHandler} activated={this.activationHandler} />
                    
                </div> : null }

                { createNew ?
                    <div className="col-12 mt-2">
                        <CompanyNew />
                    </div>
                : null}
            </div>
        )
    }
}

export default CompanyWelcome
