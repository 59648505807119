import { EcholoApi, Headers } from './config'

export const GetAllHubs = async (application) => {
    try {
        const hubs = await EcholoApi.get('/hubs', Headers(application))
        return hubs.data
    } catch (error) {
        console.log(error)
        return false
    }
}
