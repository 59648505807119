import React, { useState } from 'react'
import { connect } from 'react-redux'
import { SET_CURRENT_COMPANY, SET_CURRENT_USER } from '../../Store/Actions'
import { TextField } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Company, User } from '../../Controllers'

import Alert from '../../Helpers/Components/Alert'
import LeftNav from "../Settings/LeftNav"

const CompanyActivate = ({ cancel = false, currentCompany, setCurrentCompany, currentUser, setCurrentUser, activated, setAsCurrentCompany = true }) => {
    const initState = {
        appId: ''
    }
    const [application, setApplication] = useState(initState)
    const [notify, setNotify] = useState({ isOpen: false, message: "", type: "info" })

    const updateCurrentUser = async () => {
        let updatedCurrentUser = await User.Find({ id: currentUser.id })
        setCurrentUser(updatedCurrentUser)
    }

    const submitHandler = async (event) => {
        event.preventDefault()
        let company = null
        
        company = await Company.Activate(application.appId)
        
        if (company) {
            setApplication(initState)
            User.AssignCompany(currentUser, company).then(assignment => {

                // This check is for using the component on another page where we just want to join but not switch.
                if (setAsCurrentCompany) {
                    setCurrentCompany(assignment.company)
                }
                updateCurrentUser()
                
                setNotify({ isOpen: true, message: "Application was saved!", type: "success" })
            })
        } else {
            setNotify({ isOpen: true, message: "Sorry there was an error Joining or Activating your Application. Please try again.", type: "error" })
        }
        
    }

    return (
        <div className="row">
            <div className="col-12 pl-4">
                <div className="row InnerContainer">
                    <div className="col-12">
                        <h2>Join / Activate an Application</h2>
                    </div>

                    <div className="col-12">
                        <section>
                            <div className="row">
                                <div className="col-12">
                                    <p>Enter an Application ID below to Activate or Join it. If you are Activating an existing application, you will be set as the "Admin," after you may invite others and change permissions later. When Joining an application, you will need Permission from an existing Application Admin.</p>
                                    <p>If you are having issues Joining or Activating your Application, please contact <a href="https://support.echolo.io">Echolo Support here</a>.</p>
                                </div>
                            </div>
                            <form onSubmit={submitHandler}>

                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <TextField className="form-control" required value={application.appId} onChange={(e) => setApplication({ ...application, appId: e.target.value })} label="Application ID" variant="outlined" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-end mb-4">
                                    {cancel ?
                                        <div className="col-auto">
                                            <button onClick={() => cancel()} className="btn btn-outline-primary">Cancel</button>
                                        </div> : null }

                                    <div className="col-auto">
                                        <button type="submit" className="btn btn-primary"><FontAwesomeIcon icon={['fad', 'save']} /> Join / Activate</button>
                                    </div>
                                </div>
                                <Alert notify={notify} setNotify={setNotify} />
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentUser: (user) => dispatch({ type: SET_CURRENT_USER, payload: user }),
        setCurrentCompany: (company) => dispatch({ type: SET_CURRENT_COMPANY, payload: company })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(CompanyActivate)
