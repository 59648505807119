import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { GetAllReportsByRule } from '../../Services/Echolo/Reports'
import Loading from "../../Helpers/Components/Loading"

import { StDateToTimeStamp } from '../../Helpers/DateHelper'

const ReportsTable = ({ reportData, limit = 50, ruleId, currentCompany, saveReport }) => {
    const [ reports, setReports ] = useState(reportData)

    const fetchRuleReports = async () => {
        const ruleReports = await GetAllReportsByRule(currentCompany, ruleId, limit)
        if (ruleReports) {
            setReports(ruleReports)
        } else {
            setReports([])
        }
    }

    useEffect(() => {
        if (!reportData && ruleId) {

        }
    })

    return (
        <table className="table table-hover">
            <thead>
            <tr>
                <th scope="col">Rule ID</th>
                <th scope="col">Reported on</th>
                <th scope="col"># of Results</th>
                {saveReport ?
                    <th scope="col">Options</th>
                    : null }
            </tr>
            </thead>
            <tbody>
            {!reports ? <Loading isOpen={true} /> :
                <>
                {
                    reports.map((row) => (
                        <tr id={'result_row_' + row._id} key={row._id}>
                            <td><Link to={'/reports/' + row._id}><FontAwesomeIcon
                                icon="fa-duotone fa-clipboard-list" /> {row.fields.ruleResults.ruleId}</Link></td>
                            <td>{StDateToTimeStamp(row['@timestamp'])}</td>
                            <td>{row.fields.ruleResults.results.length}</td>
                            {saveReport ?
                                <td><button onClick={() => saveReport(row)} className={'btn btn-sm btn-outline-primary'}><FontAwesomeIcon icon="fa-duotone fa-bookmark" /> Save</button></td>
                                : null }
                        </tr>
                    ))
                }
                </>
            }
            </tbody>
        </table>
    )
}

export default ReportsTable
