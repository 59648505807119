import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const UserForm = ({ currentUser, handler }) => {
    const initUser = currentUser
    const [user, setUser] = useState(initUser)

    const userFormHandler = (event) => {
        event.preventDefault()
        handler(user)
    }

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={userFormHandler}>
                    <div className="row mb-4">
                        <div className="col">
                            <div className="form-group">
                                <TextField required value={user.first_name} onChange={(e) => setUser({ ...user, first_name: e.target.value })} className="form-control" label="First Name" variant="outlined" />
                            </div>
                        </div>

                        <div className="col">
                            <div className="form-group">
                                <TextField required value={user.last_name} onChange={(e) => setUser({ ...user, last_name: e.target.value })} className="form-control" label="Last Name" variant="outlined" />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="form-group">
                                <TextField required value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} className="form-control" label="Email Address" variant="outlined" />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-auto">
                            <div className="form-group">
                                <p className="userPhoneContryCode"><strong>+1</strong></p>
                            </div>
                        </div>

                        <div className="col-auto">
                            <div className="form-group">
                                <TextField required value={user.phone_number} onChange={(e) => setUser({ ...user, phone_number: e.target.value })} className="form-control" label="Phone Number" variant="outlined" />
                            </div>
                        </div>
                        <div className="col-12">
                            <p><small>Currently, only US numbers are supported for SMS notifications.</small></p>
                        </div>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-3 text-right">
                            <button type="submit" className="btn btn-primary"><FontAwesomeIcon icon={['fad', 'save']} /> Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UserForm