import React from "react"
import { Link } from "react-router-dom"
import TagChip from "../Tag/TagChip"

import { capitalize } from '../../Helpers/TextHelpers'

const ProfileTableRow = ({ row }) => {
    let methodsArr = JSON.parse(row.methods)
    let methodTags = []
    for (const method of methodsArr) {
        methodTags.push(method.sendMethod)
    }

    return (
        <tr key={row.serviceId}>
            <td><Link to={'/settings/profiles/' + row.serviceId + '/view'}>{row.serviceId}</Link></td>
            <td>{capitalize(row.integration)}</td>
            <td>{methodTags.map(m => (<TagChip key={m} tag={{ name: m }} />))}</td>
            <td>{row.configuredRssi}</td>
        </tr>
    )
}

export default ProfileTableRow
