import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AppLeftMenu = ({ locationPath, selectedDashboard }) => {
    const { t } = useTranslation()

    return (
        <div className="MainLeftMenu">
            <ul className="nav flex-column">
                { selectedDashboard ?
                    <li className={locationPath === '/dashboard?dash=' + selectedDashboard.id ? "navLink active" : "navLink"}>
                        <Link to={'/dashboard?dash=' + selectedDashboard.id}><FontAwesomeIcon icon={['fad', 'chart-bar']} /> <span className="MainLeftMenuMenuItem">{selectedDashboard.name}</span></Link>
                    </li> :
                    <li className={locationPath === '/dashboard' ? "navLink active" : "navLink"}>
                        <Link to="/dashboard"><FontAwesomeIcon icon={['fad', 'chart-bar']} /> <span className="MainLeftMenuMenuItem">Dashboard</span></Link>
                    </li>
                }
                <li className={locationPath === '/map/geofence' ? "navLink active" : "navLink"}>
                    <Link to="/map/geofence"><FontAwesomeIcon icon={['fad', 'location-arrow']} /> <span className="MainLeftMenuMenuItem">{t('geofence.title_plural')}</span></Link>
                </li>
                <li className={locationPath === '/item' ? "navLink active" : "navLink"}>
                    <Link to="/item"><FontAwesomeIcon icon={['fad', 'barcode-read']} /> <span className="MainLeftMenuMenuItem">{t('item.titles')}</span></Link>
                </li>
                <li className={locationPath === '/sensor' ? "navLink active" : "navLink"}>
                    <Link to="/sensor"><FontAwesomeIcon icon={['fad', 'microchip']} /> <span className="MainLeftMenuMenuItem">{t('sensor.titles')}</span></Link>
                </li>
                <li className={locationPath === '/rule' ? "navLink active" : "navLink"}>
                    <Link to="/rule"><FontAwesomeIcon icon="fa-duotone fa-code-branch" /> <span className="MainLeftMenuMenuItem">Rules</span></Link>
                </li>
                <li className={locationPath === '/reports' ? "navLink active" : "navLink"}>
                    <Link to="/reports"><FontAwesomeIcon icon="fa-duotone fa-clipboard-list" /> <span className="MainLeftMenuMenuItem">Reports</span></Link>
                </li>
            </ul>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentCompany: state.user.currentCompany,
        selectedDashboard: state.user.selectedDashboard
    }
}

export default connect(mapStateToProps)(AppLeftMenu)