import React from "react"
import { flatten } from 'flat'

const DeviceExtraDataTable = ({ rowData }) => {
    let tableRow = []
    if (rowData) {
        // We Flatten data here to make it easy to read in the table
        let flattenData = flatten(rowData)
        let d = Object.keys(flattenData)

        tableRow = d.map((key, i) => {
            return (
                <tr key={Math.random() + i}>
                    {flattenData[key] ?
                        <>
                            <th scope="row">{key}</th>
                            <td>{flattenData[key]}</td>
                        </>
                        : null}
                </tr>
            )
        })
    }

    return (
        <>
            {tableRow}
        </>
    )
}

export default DeviceExtraDataTable
