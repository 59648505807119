import React, { useEffect, useState } from "react"
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"

const RedlistProfileForm = ({ saveProfileState, profileData }) => {
    const [profile, setProfile] = useState(profileData)
    useEffect(() => {
        saveProfileState(profile)
    }, [profile])
    return (
        <div className={'row'}>
            <div className={'col-12'}>
                <h5>Redlist Rule Profile</h5>
                <p>Redlist combines your field service, maintenance and safety operations into a single unified solution that improves accountability among your teams.</p>
            </div>
            <div className={'col-12 mb-4'}>
                <FormControl className="form-control" variant="outlined">
                    <TextField required className="form-control" value={profile.methods.auth.key} onChange={(e) =>
                        setProfile({ ...profile, methods: { ...profile.methods, auth: { ...profile.methods.auth, key: e.target.value } } }) } label="Redlist API Key" variant="outlined" />
                </FormControl>
            </div>
            <div className={'col-12 mb-4'}>
                <FormControl className="form-control" variant="outlined">
                    <TextField required className="form-control" value={profile.methods.auth.secret} onChange={(e) =>
                        setProfile({ ...profile, methods: { ...profile.methods, auth: { ...profile.methods.auth, secret: e.target.value } } }) } label="Redlist API Secret" variant="outlined" />
                </FormControl>
            </div>
            <div className={'col-12 mb-4'}>
                <FormControl className="form-control" variant="outlined">
                    <TextField required className="form-control" value={profile.methods.auth.alt} onChange={(e) =>
                        setProfile({ ...profile, methods: { ...profile.methods, auth: { ...profile.methods.auth, alt: e.target.value } } }) } label="Redlist Shard ID" variant="outlined" />
                </FormControl>
            </div>
        </div>
    )
}

export default RedlistProfileForm
