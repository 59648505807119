import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import { Hub } from '@aws-amplify/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SET_SELECTED_DASHBOARD } from '../../Store/Actions'

import { Dashboards } from '../../Controllers'

import DashboardForm from './DashboardForm'
import WidgetList from '../Widgets/WidgetList'
import WidgetCreateModel from '../Widgets/WidgetCreateModel'

import MapIndex from '../../Pages/Map/MapIndex'

const DashboardShow = ({ clearSelectedDashboard, dashboardData, setSelectedDashboard, onMap = false, onMapClose }) => {
    const [showModel, setShowModel] = useState(false)
    const [dashboard, setDashboard] = useState(dashboardData)

    const deleteDashboard = async () => {
        clearSelectedDashboard()
        let deleated = await Dashboards.Delete(dashboard)
        if (deleated) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: `${deleated.name} has been deleated.`
                }
            })
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'error', 
                    message: `${deleated.name} was not delated, please try again.`
                }
            })
        }
    }

    const editDashboard = async (dashboardData) => {
        let updatedDash = await Dashboards.Update(dashboardData)
        if (updatedDash) {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'success', 
                    message: `${updatedDash.name} has been updated.`
                }
            })
            setDashboard(updatedDash)
            setSelectedDashboard(updatedDash)
            setShowModel(false)
        } else {
            Hub.dispatch('Alert', {
                event: 'show',
                data: {
                    type: 'warning', 
                    message: 'There was an issue updating your Dashboard'
                }
            })
        }
    }

    let dashboardModel = (
        <Modal show={showModel} onHide={() => setShowModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Dashboard Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <h4 className="mb-4">Update this Dashboard</h4>
                        <DashboardForm handler={editDashboard} dashboardData={dashboard} />
                        <hr />
                    </div>
                    <div className="col-12 mt-4">
                        <h4>Delete this Dashboard</h4>
                        <p className="text-danger">Once you delete this dashboard, this action can not be undone; in other words, it's permanent.</p>
                        <p className="text-right"><button onClick={() => deleteDashboard()} className="btn btn-outline-danger"><FontAwesomeIcon icon={['fad', 'trash']} /> DELETE</button></p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )

    return (
        <div className="row">
            <div className="col-12">
                {!onMap ?
                <div className="row DashboardShowHeader">
                    <div className="DashboardShowBackIcon">
                        <FontAwesomeIcon className="FakeLink" icon={['fad', 'chevron-left']} onClick={() => clearSelectedDashboard()} />
                    </div>

                    <div className="DashboardShowName">
                        <h3>{dashboard.name} Dashboard</h3>
                    </div>

                    <div className="col-6 ml-auto text-right DashboardShowMenu">
                        {['item', 'standard'].includes(dashboard.type) ? <span className="DashboardShowMenuItem FakeLink"><WidgetCreateModel dashboard={dashboard} /></span> : null }
                        <span onClick={() => setShowModel(true)} className="DashboardShowMenuItem FakeLink"><FontAwesomeIcon icon={['fad', 'sliders-v-square']} /> Dashboard Settings</span>
                    </div>
                </div> : 
                    <div className="row justify-content-end">
                        <div className="col-2 text-right">
                            <FontAwesomeIcon onClick={() => onMapClose(null)} className="FakeLink OnMapDashboard_CloseIcon" icon={['fad', 'times']} />
                        </div>
                    </div>
                }

                { ['item', 'standard'].includes(dashboard.type) ?
                    <div className="row row-cols-3">
                        <WidgetList dashboard={dashboard} />
                    </div>
                : null }

                { ['map'].includes(dashboard.type) ? <MapIndex dashboard={dashboard} /> : null }

            </div>
            {dashboardModel}
        </div>
    )
}

const mapDispactToProps = dispatch => {
    return {
        setSelectedDashboard: (dashboard) => dispatch({ type: SET_SELECTED_DASHBOARD, payload: dashboard })
    }
}

export default connect(null, mapDispactToProps)(withTranslation()(DashboardShow))