import { EcholoApi, Headers } from './config'

export const GetAllProfiles = async (application) => {
    try {
        const profiles = await EcholoApi.get('/applications/profiles', Headers(application))
        return profiles.data
    } catch (error) {
        console.log(error)
        return false
    }
}

export const GetProfileById = async (application, serviceId) => {
    try {
        const profile = await EcholoApi.get('/applications/profiles/' + serviceId, Headers(application))
        return profile.data
    } catch (error) {
        console.log(error)
        return false
    }
}

export const SplitServiceTypes = (profiles) => {
    const splitProfiles = {
        auto_delivery: [],
        rule_delivery: []
    }
    profiles.forEach(profile => {
        if (profile.serviceType) {
            splitProfiles[profile.serviceType].push(profile)
        } else {
            splitProfiles['auto_delivery'].push(profile)
        }
    })
    return splitProfiles
}

export const CreateProfile = async (application, unstructuredProfile) => {
    try {
        const profileData = {
            ...unstructuredProfile,
            methods: [
                { ...unstructuredProfile.methods }
            ]
        }
        console.log(profileData)
        const profile = await EcholoApi.post('/applications/profiles', profileData, Headers(application))
        console.log(profile)
        return profile
    } catch (error) {
        console.log(error)
        return false
    }
}